import React from 'react'
import { Grid, Box, Container, Typography, Link, makeStyles } from '@material-ui/core'

import { useContext } from 'react'
import FaqDataRight from './FaqDataRight'
const FaqDataList1 = [
  {
    title: 'What is Tarality and how does it work?',
    desc:
      'Tarality is a decentralized application (DApp) built on the Taral Chain. It allows users to swap tokens, provide liquidity, create new tokens, explore blockchain data, claim free NFTs, and access a TARAL faucet. The platform works by connecting your Web3 wallet, enabling you to interact with various DeFi features.'
  },
  {
    title: 'How do I start using Tarality?',
    desc:
      "To start using Tarality, you need to connect a compatible Web3 wallet like MetaMask or Trust Wallet to the platform. Once connected, you can access all features including token swapping, liquidity provision, and more. If you're new and need some TARAL to get started, you can use the faucet feature."
  },
  {
    title: 'Is it safe to use Tarality?',
    desc:
      'Tarality prioritizes security, with smart contracts audited by top blockchain security firms. However, as with all DeFi platforms, users should exercise caution, do their own research (DYOR), and only invest what they can afford to lose. Remember, in decentralized finance, you are responsible for your own funds.'
  },
  {
    title: ' What makes Tarality different from other DEXes?',
    desc:
      "Tarality stands out by offering a comprehensive suite of DeFi tools beyond just token swapping. This includes features like token creation, a blockchain explorer, free NFT claims, and a TARAL faucet. It's designed to be an all-in-one platform for both newcomers and experienced DeFi users on the Taral Chain."
  }
  // {
  //   title: 'In publishing and graphic design, Lorem ipsum is a placeholder text.',
  //   desc:
  //     'In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before the final copy is available. '
  // }
]

const useStyles = makeStyles(theme => ({
  '@keyframes rotating': {
    from: {
      transform: 'rotate(0deg)'
    },
    to: {
      transform: 'rotate(360deg)'
    }
  },
  root: {
    width: '100%',
    padding: '20px 0px 80px',
    position: 'relative',

    background: '#0d151f',
    overflow: 'hidden',
    '& h1': {
      textAlign: 'center',
      lineHeight: '66px',
      marginBottom: '60px',
      color: theme.palette.primary.main
    },
    '& h6': {
      // color: "#4AE69C",
      '&:hover': {
        // color: "#FF632C",
      }
    },
    '& .bgx1': {
      animation: '$rotating 26s linear infinite'
    }
  },
  heading: {
    color: '#fff',
    fontSize: '36px',
    fontWeight: '500',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px'
    }
  },
  roadmapcenter: {
    position: 'absolute',
    // top: '12%',
    // left: '-41%',
    // right: '0%',
    top: '35%',
    left: '-4%',
    zIndex: '1',
    borderRadius: '1000px',
    opacity: '1.55',
    width: '180px',
    height: '180px',
    background: '#FED056',
    opacity: '40%',
    filter: 'blur(94px)',
    transform: 'rotate(-90deg)',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  roadmapcenter1: {
    position: 'absolute',
    // top: '12%',
    // left: '-41%',
    // right: '0%',
    top: '35%',
    left: '62%',
    zIndex: '1',
    borderRadius: '1000px',
    opacity: '1.55',
    width: '500px',
    height: '500px',
    background: '#1a4d55',
    opacity: '40%',
    filter: 'blur(94px)',
    transform: 'rotate(-90deg)',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  }
}))

export default function FAQpage() {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.roadmapcenter}></div>
      <img className={classes.roadmapcenter1} src="/images/greenishbg.png" alt="" />
      <Container>
        <Box mt={4}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Grid container spacing={2}>
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column'
                  }}
                >
                  <Typography
                    variant="h5"
                    style={{
                      color: '#fff'
                      // fontSize: "30px",
                      // paddingBottom: "5px",
                    }}
                    className={classes.heading}
                  >
                    Frequently Asked <span style={{ color: '#4AFCFE' }}>Questions?</span>{' '}
                  </Typography>
                  <img
                    className="bgx1"
                    src="new-module/sunny.svg"
                    alt=""
                    style={{ rotate: '-30deg', height: '300px', width: '300px' }}
                  />
                  {/* <Typography variant="h6" style={{ color: '#fff', fontWeight: '300' }}>
                    In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the
                    visual form of a document.
                  </Typography> */}
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Grid container spacing={2}>
                <Box>
                  {FaqDataList1.map((data, i) => {
                    return (
                      // <Grid item xs={12} sm={12} md={12} key={i}>
                      <FaqDataRight data={data} index={i} />
                      // </Grid>
                    )
                  })}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  )
}
