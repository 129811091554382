import { MenuItem, Select } from '@material-ui/core'
import { socketURL } from 'ApiConfig/ApiConfig'
import React, { useEffect, useState } from 'react'
import NumberAnimation from './number-animation/NumberAnimation'

export default function SelectComponent({
  callSetData,
  addresses,
  selectedAddress,
  setSelectedAddress,
  classes,
  activeTab
}) {
  const [priceData, setPriceData] = useState({})
  useEffect(() => {
    if (activeTab === 'Game') {
      const socket = new WebSocket(socketURL)
      socket.onopen = () => {
        //* Request First*//
        socket.send(
          JSON.stringify({
            token: 'coin_list'
          })
        )
      }

      socket.onmessage = async event => {
        try {
          const data = await event.data // Ensure the event data is a text string
          const newData = JSON.parse(data)
          const priceData = newData[0]?.data
          const symbol = Object.keys(addresses).find(key => addresses[key] === selectedAddress)
          if (symbol) {
            setPriceData(priceData[symbol]?.quote?.USDT ?? '')
            callSetData(priceData[symbol]?.quote?.USDT ?? '')
          }
        } catch (error) {
          console.error('Error parsing WebSocket message', error)
        }
      }

      // setWebSocketCoinList(data?.data);

      // Clean up socket connection when the component unmounts
      socket.onclose = () => {
        // console.log("WebSocket disconnected");
        // You may handle reconnection logic here if needed
      }

      return () => {
        socket.close() // Close the WebSocket connection on component unmount
      }
    }
  }, [selectedAddress, activeTab])
  return (
    <>
      <Select
        value={selectedAddress}
        fullWidth
        variant="outlined"
        onChange={e => setSelectedAddress(e.target.value)}
        className={classes.select}
      >
        {Object.entries(addresses).map(([key, value]) => (
          <MenuItem key={value} value={value}>
            {key} -${' '}
            {selectedAddress === value && priceData && (
              <>{priceData?.price ? <NumberAnimation targetNumber={priceData?.price} /> : ''}</>
            )}
          </MenuItem>
        ))}
      </Select>
    </>
  )
}
