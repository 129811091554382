import React from 'react'
import { Box, Typography, makeStyles, Container } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  '@keyframes rotating': {
    from: {
      transform: 'rotate(0deg)'
    },
    to: {
      transform: 'rotate(360deg)'
    }
  },
  mainBox: {
    zIndex: 1,
    padding: '20px 0px 280px',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    alignItems: 'center',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      padding: '20px 0px 20px'
    },
    '& h4': {
      color: theme.palette.text.graypink,
      textAlign: 'center',
      marginBottom: '10px'
    },
    '& h1': {
      textAlign: 'center',
      lineHeight: '66px',
      marginBottom: '240px',
      '@media(max-width:991px)': {
        marginBottom: '296px'
      }
    },
    '& h6': {
      fontSize: '14px',
      fontWeight: '500'
    },
    '& p': {
      fontSize: '12px',
      fontWeight: '300',
      color: theme.palette.text.dull
    },
    '& h2': {
      color: '#fff',
      fontSize: '36px',
      fontWeight: '500',
      [theme.breakpoints.down('sm')]: {
        fontSize: '16px'
      }
    },
    '& h5': {
      fontSize: '16px',
      fontWeight: '300',
      color: theme.palette.text.dull
    }
  },
  mainNaviagation: {
    marginTop: '230px',
    width: '100%',
    maxWidth: '380px',
    height: '380px',
    position: 'relative',
    borderRadius: '100%',
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& .bgImagecir': {
      position: 'absolute',
      width: 'calc(100% + 370px)',
      [theme.breakpoints.down('sm')]: {
        width: '100%'
      }
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '50px'
    },
    '& .bgcircular': {
      width: '100%'
    },
    '& .bgx1': {
      animation: '$rotating 26s linear infinite'
    },
    '& .bgx2': {
      animation: '$rotating 19s linear infinite reverse'
    },
    '& .bgx3': {
      animation: '$rotating 14s linear infinite'
    }
  },
  circleCenterText: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px !important'
    }
  },
  roadmapcenter1: {
    position: 'absolute',
    // top: '35%',
    // left: '62%',
    // zIndex: '2',
    borderRadius: '1000px',
    opacity: '1.55',
    width: '500px',
    height: '500px',
    background: '#4AFCFE',
    opacity: '40%',
    filter: 'blur(94px)',
    transform: 'rotate(-90deg)',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  }
}))

const CircleRoadMap = () => {
  const classes = useStyles()

  return (
    <Box className={classes.mainBox}>
      <Container>
        <Container maxWidth="sm">
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              textAlign: 'center'
            }}
          >
            <Typography style={{ textTransform: 'capitalize', marginBottom: '16px' }} variant="h2" color="primary">
              An <span style={{ color: '#4AFCFE' }}>Ecosystem</span> for getting all{' '}
            </Typography>
            <Typography variant="h6" color="primary" style={{ fontWeight: '300' }}>
              Experience the full spectrum of decentralized finance (DeFi) with Tarality. Our ecosystem integrates
              various components to provide a holistic financial solution.
            </Typography>
          </Box>
        </Container>

        <Box className={classes.mainNaviagation}>
          <div className={classes.roadmapcenter1}></div>

          <Box className="bgImagecir">
            <img src="new-module/circle1.svg" alt="" width="100%" className="bgcircular bgx1" />
          </Box>
          <Box style={{ display: 'flex', justifyContent: 'center' }} className="bgImagecir">
            <img src="new-module/circle2.svg" alt="" style={{ width: '75%' }} className="bgcircular bgx2" />
          </Box>
          <Box style={{ display: 'flex', justifyContent: 'center' }} className="bgImagecir">
            <img src="new-module/circle3.svg" alt="" style={{ width: '60%' }} className="bgcircular bgx3" />
          </Box>
          <Box style={{ display: 'flex', justifyContent: 'center' }} className="bgImagecir">
            <Box
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <img
                src="circle/main.png"
                alt=""
                className="bgcircular"
                style={{ width: '30%', zIndex: '-1', position: 'relative' }}
              />
              <Typography
                className={classes.circleCenterText}
                style={{
                  maxWidth: '120px',
                  wordBreak: 'break-word',
                  position: 'absolute',
                  color: 'rgb(0, 0, 0)',
                  top: '35%',
                  textAlign: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '15px',
                  fontWeight: 'bold'
                }}
              >
                A Decentralized Transaction Layer For The Internet
              </Typography>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default CircleRoadMap
