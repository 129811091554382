import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Drawer,
  Grid,
  MenuItem,
  Box,
  Container,
  Typography,
  Menu
} from '@material-ui/core'

import MenuIcon from '@material-ui/icons/Menu'
import React, { useState, useEffect, useRef, useContext } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { NavLink } from 'react-router-dom'

import { useWeb3React } from '@web3-react/core'
import Logo from '../../../components/Header/Logo'

const headersData = [
  {
    label: 'Dashboard',
    href: '#1'
  },
  {
    label: 'Trade',
    href: '#2'
  },
  {
    label: 'Token',
    href: '#3'
  },
  {
    label: 'DeFi',
    href: '#4'
  },
  {
    label: 'Mint NFT',
    href: '#5'
  },
  {
    label: 'Games',
    href: '#6'
  }
  // {
  //   label: "Marketplace",
  //   href: "/explore",
  // },
  // {
  //   label: "Collections",
  //   href: "/collections",
  // },
]

const useStyles = makeStyles(theme => ({
  menuButton: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '300',
    borderRadius: 0,
    minWidth: 'auto',
    color: '#fff !important',
    height: '31px',
    padding: '0px 7px',
    letterSpacing: '1px',
    // marginLeft: "15px",
    textDecoration: ' none',
    '@media (max-width: 900px)': {
      fontStyle: 'normal',
      letterSpacing: '-0.6px',
      lineHeight: '24px',
      color: '#FFF !important',
      padding: '15px !important',
      height: '51px',
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-start'
    },
    '&.active': {
      // color: "#51ACED",
      color: '#FFF !important'
    },
    '&:hover': {
      // color: "#51ACED",
      color: '#FFF !important'
    }
  },
  toolbar: {
    paddingLeft: '20px',
    paddingRight: '14px',
    background: 'rgba(166, 162, 162, 0.2)',
    border: '1.5px solid rgba(255, 255, 255, 0.1)',
    boxShadow: '0px 8px 15px rgb(0 0 0 / 15%)',
    borderRadius: '10px',
    marginTop: '15px'
  },

  maindrawer: {
    height: '100%',
    background: '#0c0731',
    width: '260px'
  },
  logoDrawer: {
    paddingLeft: '10px',
    width: '140px',
    marginBottom: '30px'
  },
  drawerContainer: {
    padding: '20px 0px 20px 20px',
    height: '100%',
    background: '#000',
    boxShadow: '-17px 19px 20px rgb(0 0 0 / 12%)',
    backdropFilter: 'blur(25px)',
    width: '260px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    '& .MuiButton-root:hover': {
      // background: "linear-gradient(104.47deg, #00FBFB -6.14%, #2E1EDB 134.27%)",
      color: 'red',
      // width: "100%",
      // display: "flex",
      // paddingLeft: "10px",
      // justifyContent: "flex-start",
      backgroundColor: 'transparent'
    }
  },
  drawericon: {
    color: '#000',
    position: 'absolute',
    top: '0px',
    right: '-10px',
    fontSize: '25px'
  },
  logoImg: {
    width: '75px',
    // height: '44.5px',
    margin: ' 14px 15px 11px 0px',
    objectFit: 'contain',
    '@media (max-width: 500px)': {
      margin: ' 11px 1px 3px 0px',
      width: '52px'
    }
  },
  menuMobile: {
    fontSize: '16px',
    fontWeight: '400',
    fontStyle: 'normal',
    letterSpacing: '-0.6px',
    lineHeight: '1.75',
    color: '#fff !important',
    paddingLeft: 0,
    // borderBottom: "1px solid #3e3e3e",
    padding: '16px',
    '@media (max-width: 500px)': {
      padding: '7px 0',
      width: '100%'
    }
  },
  paper1: {
    background: 'black',
    color: 'white'
  },
  containerHeight: {
    height: '100%'
  },
  mainHeader: {
    justifyContent: 'space-between',
    padding: '0px'
  },
  search: {
    color: '#fff',
    width: '100%',
    height: '40px',
    position: 'relative',
    marginLeft: '20px',
    borderRadius: '8px',
    background: 'rgba(255, 255, 255, 0.3)',
    backdropFilter: 'blur(15px)',
    overflow: 'hidden'

    // [theme.breakpoints.up("sm")]: {
    //   marginLeft: theme.spacing(1),
    //   width: "180px",
    // },
  },
  metamaskhead: {
    display: 'flex',
    alignItems: 'center',
    background: '#fff',
    borderRadius: '10px',
    padding: '20px',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: '24px',
    '&:hover': {
      backgroundColor: '#daf4ff'
    },
    '& img': {
      width: '70px',
      marginRight: '20px',
      [theme.breakpoints.down('xs')]: {
        width: '50px'
      }
    },
    '& h5': {
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '16px',
      lineHeight: '20px',
      textTransform: 'capitalize',
      color: '#db7d2a'
    }
  },
  searchIcon: {
    color: '#656565',
    height: '100%',
    display: 'flex',
    padding: '0px 8px',
    position: 'absolute',
    fontSize: '16px',
    alignItems: 'center',
    pointerEvents: 'none',
    justifyContent: 'center',
    background: 'rgba(255, 255, 255, 0.4)',
    backdropFilter: 'blur(20px)'
  },
  inputRoot: {
    color: 'inherit',
    fontSize: '16px',
    width: '100%'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    fontSize: '13px',
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      '&:focus': {
        width: '100%'
      }
    }
  },
  menuButton1: {
    paddingLeft: '0',
    color: '#fff !important'
  },
  searcBox: {
    backgroundColor: '#DAF4FF',
    // border: "1px solid #daf4ff",
    borderRadius: ' 50px'
  },
  menuMobile1: {
    // marginLeft: "10px",
    // backgroundColor: " #FCF2FA",
    // borderRadius: "40px",
    '& h4': {
      fontSize: '14px',
      lineHeight: ' 17px',
      color: '#fff',
      margin: '0 5px',
      '@media (max-width:767px)': {
        display: 'none'
      }
    },
    '& svg': {
      '@media (max-width:767px)': {
        display: 'none'
      }
    },
    // "&:hover": {
    //   backgroundColor: " #FCF2FA",
    //   borderRadius: "40px",
    // },
    '& figure': {
      margin: 0,
      width: 30,
      height: 30,
      borderRadius: '50px',
      overflow: 'hidden',
      display: 'flex',
      justifyContent: ' center',
      alignItems: 'center',
      '& img': {
        width: 'auto',
        height: 'auto',
        maxWidth: '100%'
        // maxHeight: "100%",
      }
    }
  },
  flexbox: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  }
}))

export default function NewHeader() {
  const { account, chainId } = useWeb3React()
  const [anchorEl1, setAnchorEl1] = React.useState(null)
  const [anchorEl2, setAnchorEl2] = React.useState(null)

  const location = useLocation()
  const [networkDetails, setNetworkDetails] = React.useState([])
  const [open2, setOpen2] = React.useState(false)
  const [updatepage, setUpdatepage] = useState()
  const [open, setOpen] = React.useState(false)

  const [isLoaderwallet, setIsloader] = useState(false)

  useEffect(() => {
    if (account) {
      setIsloader(false)

      handleClose()
    }
  }, [account])

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const {
    menuMobile,
    menuButton,
    menuButton1,
    divstake,
    toolbar,
    drawerContainer,
    menuMobile1,
    drawericon,
    logoDrawer,
    containerHeight,
    mainHeader
  } = useStyles()
  const history = useHistory()

  const [updateMinSatkeOpen, setUpdateMinSatkeOpen] = useState(false)
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false
  })

  const { mobileView, drawerOpen } = state

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1220
        ? setState(prevState => ({ ...prevState, mobileView: true }))
        : setState(prevState => ({ ...prevState, mobileView: false }))
    }

    setResponsiveness()

    window.addEventListener('resize', () => setResponsiveness())
  }, [])

  useEffect(() => {
    if (location.search.substring(1, location.search.length)) {
      const id = location.search.substring(1, location.search.length)

      setUpdatepage(id)
    }
  }, [location.search])

  const displayDesktop = () => {
    return (
      <Box style={{ display: 'flex', alignItems: 'center', background: '#141c26' }}>
        <Grid container alignItems="center">
          <Grid item xs={2}>
            {femmecubatorLogo}
          </Grid>
          <Grid item xs={9}>
            <Box
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                padding: '4px',
                gap: '10px'
              }}
            >
              <Box
                style={{
                  background: '#152232',
                  border: '1px solid #ffffff52',
                  borderRadius: '6px',
                  padding: '2px'
                }}
              >
                <Button variant="text" style={{ padding: '8px 10px', backgroundColor: '#152232', color: '#000' }}>
                  Exchange
                </Button>
                <Button variant="text" style={{ padding: '8px 10px', backgroundColor: '#ffffff' }}>
                  Wallet
                </Button>
              </Box>
              {getMenuButtons()}
            </Box>
          </Grid>
        </Grid>
      </Box>
    )
  }

  const displayMobile = () => {
    const handleDrawerOpen = () => setState(prevState => ({ ...prevState, drawerOpen: true }))
    const handleDrawerClose = () => setState(prevState => ({ ...prevState, drawerOpen: false }))

    return (
      <Toolbar className={mainHeader}>
        <Drawer
          {...{
            anchor: 'right',
            open: drawerOpen,
            onClose: handleDrawerClose
          }}
        >
          <div className={drawerContainer}>
            <img className={logoDrawer} src="images/logo.png" alt="" />

            {getDrawerChoices()}

            <div>
              <IconButton
                aria-label="delete"
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={() => setUpdateMinSatkeOpen(true)}
                className={menuMobile1}
                size="small"
              >
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  // to="/conect-wallet"
                  // component={Link}
                  onClick={handleClickOpen}
                  style={{ whiteSpace: 'pre' }}
                >
                  Connect Wallet
                </Button>
              </IconButton>
            </div>
          </div>
        </Drawer>
        {/* Qi wallet */}

        <Grid container alignItems="center">
          <Grid item xs={5}>
            <Box>{femmecubatorLogo}</Box>
          </Grid>
          <Grid item xs={5}></Grid>
          <Grid item xs={2}>
            <IconButton
              className={drawericon}
              {...{
                edge: 'start',
                color: 'inherit',
                'aria-label': 'menu',
                'aria-haspopup': 'true',
                onClick: handleDrawerOpen
              }}
            >
              <MenuIcon width="60px" height="60px" style={{ color: '#197ab3', fontSize: '30px' }} />
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
    )
  }

  const getDrawerChoices = () => {
    return headersData.map(({ label, href }) => {
      return (
        <>
          <Button
            {...{
              key: label,
              color: 'inherit',
              to: href,
              component: Link,
              className: menuButton1
            }}
          >
            <MenuItem className={menuMobile}>{label}</MenuItem>
          </Button>
        </>
      )
    })
  }

  const femmecubatorLogo = (
    <Box mt={1}>
      <Link to="/">
        <Logo className="logoImg" />
      </Link>
    </Box>
  )

  const getMenuButtons = activeClassName => {
    return headersData.map(({ label, href }) => {
      return (
        <>
          <NavLink
            exact
            {...{
              key: label,
              color: 'inherit',
              to: href,
              // component: Link,
              className: menuButton,
              activeClassName: 'active'
            }}
          >
            {' '}
            {label}
          </NavLink>
        </>
      )
    })
  }

  return (
    <>
      <AppBar
        position={history.location.pathname !== '/' ? 'relative' : 'absolute'}
        elevation={0}
        style={{ backgroundColor: '#ccc0', border: 'none' }}
      >
        <Box
          maxWidth="xxl"
          style={{
            background: '#141c26',
            width: '100%'
          }}
        >
          <Container maxWidth={history.location.pathname !== '/' ? 'lg' : 'lg'} className={containerHeight}>
            {mobileView ? displayMobile() : displayDesktop()}
          </Container>
        </Box>
      </AppBar>
    </>
  )
}
