import { calculateTimeLeft } from 'mint-utils'
import React, { useEffect, useState } from 'react'
import moment from 'moment'

export default function Lefttimer({ item, setTimeRemain }) {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())

  useEffect(() => {
    const unixTimestamp = moment(item?.endDate).unix()
    const timer = setTimeout(() => {
      if (Number(unixTimestamp) > Number(moment().unix())) {
        setTimeLeft(calculateTimeLeft(new Date(parseInt(unixTimestamp) * 1000)))
        setTimeRemain(calculateTimeLeft(new Date(parseInt(unixTimestamp) * 1000)))
      }
    }, 1000)
    return () => clearTimeout(timer)
  })

  return (
    <div>
      {timeLeft !== false ? (
        <>{`${timeLeft?.minutes} m : ${timeLeft?.seconds} s`}</>
      ) : (
        <> {moment(item?.endDate).format('lll')}</>
      )}
    </div>
  )
}
