import { Box, Container, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import BlockchainCard from './BlockchainCard'

const useStyles = makeStyles(theme => ({
  heading: {
    color: '#fff',
    fontSize: '36px',
    fontWeight: '500',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px'
    }
  }
}))

export default function BlockchainPageSolution() {
  const classes = useStyles()

  return (
    <Container>
      <Container maxWidth="lg">
        <Box
          mt={5}
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            textAlign: 'center'
          }}
        >
          <Typography variant="h2" style={{ marginBottom: '16px' }} className={classes.heading}>
            Smart Solutions For The <span style={{ color: '#4AFCFE' }}>Automated</span> World Solutions
          </Typography>
          <Typography
            variant="h6"
            color="primary"
            style={{
              marginBottom: '18px',
              fontWeight: '300',
              fontSize: '14px'
            }}
          >
            Explore the future of secure and transparent transactions with our advanced blockchain solutions.
          </Typography>
        </Box>
      </Container>
      <BlockchainCard />
    </Container>
  )
}
