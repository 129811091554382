import React, { useCallback, useState } from 'react'
import { isMobile } from 'react-device-detect'
import styled from 'styled-components'
import Settings from '../Settings'
import { useIsDarkMode } from '../../state/user/hooks'
import Logo from './Logo'
import menuIcon from '../../assets/images/msic-menu.svg'
import MobileMenu from '../MobileMenu'
import AccountButton from './AccountButton'
import Web3Status from '../Web3Status'
import TranslatedText from 'components/TranslatedText'
import { useLocation } from 'react-router-dom'
import { Box, Button, Hidden } from '@material-ui/core'
import Devnet from "./Devnet"
const HeaderFrame = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  top: 0;
  position: absolute;
  background: ${({ theme }) => theme.colors.bg1};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: calc(100%);
    position: relative;
  `};
`

const Menu = styled.div`
  display: block;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: block;
    width: 22px;
    margin-right: 8px;
    img {
      width: 100%;
      display: block;
    }
  `};
`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 320px) {
    width: 20%;
  }
`

const HeaderControlsMobile = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;
  text-decoration: none;
  :hover {
    cursor: pointer;
  }
`

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  `};
`

const StyledTopBarInner = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  padding: 10px 24px;
  position: fixed;
  color: #fff;
  backdrop-filter: blur(25px);
  @media (max-width: 850px) {
    min-width: auto;
  }
  border-bottom: 1px solid #f68d24;
  z-index: 1111;
`

const StyledAccountButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    .lng-switch, .price {
      display: none;
    }
  `};
`

interface StyledAbsoluteLinkProps {
  path: string
  active?: boolean
}

const StyledAbsoluteLink = styled.a<StyledAbsoluteLinkProps>`
  position: relative;
  color: ${({ active }) => (active ? '#f68d24' : '#fff')} !important; // Change active color here
  margin: 24px;
  text-decoration: none;

  @media (max-width: 400px) {
    margin-right: 24px;
  }
`

export default function Header() {
  const isDark = useIsDarkMode()
  const [mobileMenu, setMobileMenu] = useState(false)
  const handlePresentMobileMenu = useCallback(() => {
    setMobileMenu(true)
  }, [setMobileMenu])
  const handleDismissMobileMenu = useCallback(() => {
    setMobileMenu(false)
  }, [setMobileMenu])
  const location = useLocation()
  const currentPath = location.pathname
 

  return (
    <HeaderFrame>
      <StyledTopBarInner style={{ justifyContent: isMobile ? 'space-between' : 'start' }}>
        <HeaderElement>
          <Hidden lgUp>
            <Menu onClick={handlePresentMobileMenu}>
              <img src={menuIcon} alt="menu" />
            </Menu>
          </Hidden>
          <Title href="/" id="logostyle">
            <Logo isDark={isDark} />
          </Title>
        </HeaderElement>
        <Hidden lgUp>
          <HeaderControlsMobile>
            <AccountButton />
            <Settings />
            <Hidden smDown>

            <Devnet />
            </Hidden>
          </HeaderControlsMobile>
        </Hidden>
        <Hidden mdDown>
          <HeaderControls>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Box
                style={{
                  background: '#152232',
                  border: '1px solid #ffffff52',
                  borderRadius: '26px',
                  padding: '2px',
                  width: 'fit-content',
                  display: 'flex'
                }}
              >
                <Button
                  onClick={() => window.open('https://stgweb-bnplv2.tarality.io/')}
                  variant="text"
                  style={{ color: '#fff', padding: '8px 10px', backgroundColor: '#152232', borderRadius: '20px' }}
                >
                  Exchange
                </Button>
                <Button
                  variant="text"
                  style={{
                    padding: '8px 10px',
                    backgroundColor: '#ffffff',

                    borderRadius: '20px'
                  }}
                >
                  Web3
                </Button>
              </Box>
              <StyledAbsoluteLink path={currentPath} href="#swap" active={currentPath === '/swap'}>
                <TranslatedText translationId={198}>Swap</TranslatedText>
              </StyledAbsoluteLink>
              {/* <StyledAbsoluteLink path={currentPath} href="#/add/ETH" active={currentPath === '/add/ETH'}>
                <TranslatedText translationId={200}>Liquidity</TranslatedText>
              </StyledAbsoluteLink> */}
              <StyledAbsoluteLink path={currentPath} href="https://web-dapp.tarality.io/" target="_blank" active={currentPath === '/create-token'}>
                <TranslatedText translationId={200}>Create Token</TranslatedText>
              </StyledAbsoluteLink>
              <StyledAbsoluteLink path={currentPath} href="#mint" active={currentPath === '/mint'}>
                <TranslatedText translationId={200}>Free NFT</TranslatedText>
              </StyledAbsoluteLink>
              {/* <StyledAbsoluteLink path={currentPath} href="#game" active={currentPath === '/game'}>
                <TranslatedText translationId={200}>Game</TranslatedText>
              </StyledAbsoluteLink> */}
              <StyledAbsoluteLink path={currentPath} href="https://devnet.taralscan.com/" target="_blank">
                <TranslatedText translationId={200}>Explorer</TranslatedText>
              </StyledAbsoluteLink>
              <StyledAbsoluteLink path={currentPath} href="https://faucet-devnet.tarality.com/" target="_blank">
                <TranslatedText translationId={200}>Faucet</TranslatedText>
              </StyledAbsoluteLink>
            </div>
            <HeaderElement>
              <StyledAccountButtonWrapper>
                <Web3Status />
               
                <AccountButton />
                <Settings />
                <Devnet />
               
              </StyledAccountButtonWrapper>
            </HeaderElement>
          </HeaderControls>
        </Hidden>
        <MobileMenu onDismiss={handleDismissMobileMenu} visible={mobileMenu} />
      </StyledTopBarInner>
    </HeaderFrame>
  )
}

