import React, { useState } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Hidden,
  Tooltip
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import HomeIcon from '@material-ui/icons/Home'
import DashboardIcon from '@material-ui/icons/Dashboard'
import SettingsIcon from '@material-ui/icons/Settings'
import { Switch, Route, Link, useLocation } from 'react-router-dom'
import { MdBook, MdForum, MdLiveHelp, MdPeople, MdSecurity, MdSportsHandball } from 'react-icons/md'

const drawerWidth = 240

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100%'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    backgroundColor: 'rgb(21, 34, 50)'
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1
    },
    backgroundColor: 'rgb(21, 34, 50)'
  },
  drawerPaper: {
    backgroundColor: 'rgb(21, 34, 50)'
  },
  toolbar: theme.mixins.toolbar,
  content: {
    width: '100%',
    // flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
    // marginLeft: drawerWidth
  },
  contentShift: {
    width: '100%',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.leavingScreen
    })
    // marginLeft: theme.spacing(7) + 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  link: {
    textDecoration: 'none',
    color: 'inherit'
  },
  listItemText: {
    opacity: 1,
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },

  listItemTextHidden: {
    opacity: 0,
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  listItem: {
    '&:hover': {
      backgroundColor: '#e9b581',
      borderRadius: '5px'
    }
  },
  listItemActive: {
    backgroundColor: '#f68d24',
    borderRadius: '5px'
  }
}))

const menuItems = [
  { text: 'Promotions', icon: <HomeIcon />, path: '/' },
  { text: 'Affliate', icon: <DashboardIcon />, path: '/dashboard' },
  { text: 'VIP Club', icon: <MdPeople style={{ height: '25px', width: '25px' }} />, path: '/settings' },
  { text: 'Blog', icon: <MdBook style={{ height: '25px', width: '25px' }} />, path: '/settings' },
  { text: 'Forum', icon: <MdForum style={{ height: '25px', width: '25px' }} />, path: '/settings' },
  { text: 'Sponsorships', icon: <MdSportsHandball style={{ height: '25px', width: '25px' }} />, path: '/settings' },
  { text: 'Responsible Gambling', icon: <MdSecurity style={{ height: '25px', width: '25px' }} />, path: '/settings' },
  { text: 'Live Support', icon: <MdLiveHelp style={{ height: '25px', width: '25px' }} />, path: '/settings' }
]

const DashboardLayout = ({ children }) => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [open, setOpen] = useState(false)
  const location = useLocation()

  const handleDrawerToggle = () => {
    setOpen(!open)
  }

  const drawerContent = (
    <List>
      {menuItems.map((item, index) => {
        const isActive = location.pathname === item.path
        return (
          <Link to={item.path} className={classes.link} key={index}>
            <Tooltip title={item.text} placement="right" disableHoverListener={open}>
              <ListItem button className={`${classes.listItem} ${isActive ? classes.listItemActive : ''}`}>
                <ListItemIcon
                  style={{
                    color: '#fff',
                    minWidth: '45px',
                    paddingLeft: open ? '10px' : '15px'
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                {open && (
                  <ListItemText
                    primary={item.text}
                    className={open ? classes.listItemText : classes.listItemTextHidden}
                  />
                )}
              </ListItem>
            </Tooltip>
          </Link>
        )
      })}
    </List>
  )

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            My Dashboard
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        style={{ background: 'rgb(21, 34, 50)' }}
        variant="permanent"
        className={open ? classes.drawerOpen : classes.drawerClose}
        classes={{
          paper: open ? classes.drawerOpen : classes.drawerClose
        }}
        open={open}
      >
        <div className={classes.toolbar} />
        {drawerContent}
      </Drawer>
      <main className={open ? classes.content : classes.contentShift}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  )
}

export default DashboardLayout
