import { Box, makeStyles, Typography, Button } from '@material-ui/core'
import { useWeb3React } from '@web3-react/core'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { useWalletModalToggle } from 'state/application/hooks'

const useStyles = makeStyles(theme => ({
  connectMainPage: {
    zIndex: '1',
    height: '300px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    alignItems: 'center',
    overflow: 'hidden',
    background: 'url(new-module/walletconnectbg.png)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    textAlign: 'center'
  },
  textHeading: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '37px !important'
    }
  }
}))

export default function ConnectWalletPage() {
  const { account } = useWeb3React()
  const classes = useStyles()
  const toggleWalletModal = useWalletModalToggle()

  const history = useHistory()
  return (
    <Box className={classes.connectMainPage}>
      <Box>
        <Typography
          style={{
            textTransform: 'capitalize',
            fontSize: '80px',
            wordBreak: 'break-word',
            maxWidth: '600px',
            lineHeight: '80px',
            paddingBottom: '5px',
            fontWeight: '700',
            marginBottom: '20px'
          }}
          className={`${classes.textHeading} quantico`}
        >
          transform Your Future Now
        </Typography>
        {account ? (
          // <Button
          //   onClick={() => history.push('/swap')}
          //   style={{
          //     background: '#4AFCFE',
          //     color: '#000',
          //     padding: '8px 20px'
          //   }}
          // >
          //   Swap
          // </Button>
          <></>
        ) : (
          <Button
            style={{
              background: '#4AFCFE',
              color: '#000',
              padding: '8px 20px'
            }}
            onClick={toggleWalletModal}
          >
            Connect Wallet
          </Button>
        )}
      </Box>
    </Box>
  )
}
