import React, { useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  IconButton,
  Box
} from '@material-ui/core'
import Close from '@material-ui/icons/Close'
const dialogStyle = {
  backgroundColor: '#1b2c42',
  color: 'white',
  borderRadius: '24px'
}
const GameRuleModal = ({ open, handleClose }) => {
  return (
    <Dialog PaperProps={{ style: dialogStyle }} open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle style={{ ...dialogStyle }}>
        Tarality's Crypto Price Prediction Game
        <Box style={{ position: 'absolute', right: '10px', top: '10px' }}>
          <IconButton onClick={handleClose}>
            <Close style={{ color: '#fff' }} />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent style={{ ...dialogStyle }}>
        <Typography variant="body1" gutterBottom>
          Welcome to Tarality's exciting Crypto Price Prediction Game! Predict the final digit of a cryptocurrency's
          price sum for a chance to win free crypto.
        </Typography>
        <Typography variant="h6">How to Play</Typography>
        <Typography variant="body1" gutterBottom>
          <ol>
            <li>Choose your favorite cryptocurrency from our selection.</li>
            <li>Predict the final digit of the sum of its price digits in 15 minutes.</li>
            <li>Wait for the results and see if you've won!</li>
          </ol>
        </Typography>
        <Typography variant="h6">Game Rules</Typography>
        <Typography variant="body1" gutterBottom>
          <ul>
            <li>Predictions must be a single digit between 1 to 9.</li>
            <li>The game runs for 15 minutes from the moment you place your prediction.</li>
            <li>Final prices are taken from our trusted crypto data provider.</li>
          </ul>
        </Typography>
        <Typography variant="h6">How We Calculate</Typography>
        <Typography variant="body1" gutterBottom>
          Let's break it down with an example:
          <br />
          <span style={{ fontWeight: 'bold', color: 'rgb(49, 208, 170)', padding: '10px 0px' }}>
            {' '}
            If Bitcoin's price is $64,690.78 when the game ends:
          </span>
          <br />
          <span> 1. We add all the digits: 6 + 4 + 6 + 9 + 0 + 7 + 8 = 40</span>
          <br />
          <span> 2. Then we sum those digits again: 4 + 0 = 4</span>
          <br />
          <span> 3. The final result is 4</span>
          <br />
          If you predicted 4, congratulations! You're a winner!
        </Typography>
        <Typography style={{ paddingTop: '12px' }} variant="h6">
          Prizes
        </Typography>
        <Typography variant="body1" gutterBottom>
          All correct predictions share the prize pool equally.
          <br />
          Winnings are distributed in cryptocurrency directly to your Tarality's wallet.
        </Typography>
        <Typography style={{ paddingTop: '12px' }} variant="h6">
          Why Play?
        </Typography>
        <Typography variant="body1" gutterBottom>
          <ul>
            <li>Chance to earn free cryptocurrencies</li>
            <li>Test your prediction skills</li>
            <li>Learn about crypto price movements</li>
            <li>Reinvest your winnings in Tarality's asset marketplace</li>
          </ul>
        </Typography>
        <Typography variant="body1" gutterBottom>
          Ready to put your crypto knowledge to the test? Make your prediction now and potentially boost your crypto
          holdings!
        </Typography>
      </DialogContent>
    </Dialog>
  )
}

export default GameRuleModal
