import { AbiItem } from 'web3-utils'
import { Interface } from '@ethersproject/abi'
import { getWeb3NoAccount } from './web3'
import MultiCallAbi from '../constants/multicall/abi.json'
import { getMulticallAddress } from './addressHelpers'

interface Call {
  address: string // Address of the contract
  name: string // Function name on the contract (example: balanceOf)
  params?: any[] // Function params
}

const multicall = async (abi: any[], calls: Call[]) => {
  const web3 = getWeb3NoAccount()
  // console.log("i got web3::",web3);
  const multi = new web3.eth.Contract((MultiCallAbi as unknown) as AbiItem, getMulticallAddress())
  console.log(">>>>>>>>>>>>>multi",multi);
  
  const itf = new Interface(abi)

  const calldata = calls.map(call => [call.address.toLowerCase(), itf.encodeFunctionData(call.name, call.params)])
  console.log(">>>>>>>>>>>calldata",calldata);
  
  const { returnData } = await multi.methods.aggregate(calldata).call()
  console.log(">>>>>>>>>>>>>>>returnData",returnData);
  
  const res = returnData.map((call: Call, i: number) => itf.decodeFunctionResult(calls[i].name, call as any))
  console.log(">>>>>>>>>>>>>.res",res);
  

  return res
}

export default multicall
