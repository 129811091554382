import React, { useEffect, useMemo, useState } from 'react'
import { CardContent, Typography, Button, Grid, Chip, Box, makeStyles } from '@material-ui/core'
import { ethers } from 'ethers'
import PredicationABI from '../../mint-constants/ABI/PredicationABI.json'
import { predicationContract } from 'mint-constants'
import toast from 'react-hot-toast'
import {
  calculateTimeLeft,
  getContract,
  getGradientColor,
  getNumberButtonGradient,
  getStatusColors,
  sortAddress
} from 'mint-utils'
import { useWeb3React } from '@web3-react/core'
import { formatNumber } from 'pages/helper/helperFunctions'
import Lefttimer from './Lefttimer'
import ConfirmationModal from './confirmation-modal'
import { apiRouterCall } from 'ApiConfig/services'
import ButtonCircularProgress from 'component/ButtonCircularProgress'
import NumberAnimation from './number-animation/NumberAnimation'
import { bottom } from '@popperjs/core'
// Custom styles
const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    backgroundSize: '400% 400%',
    borderRadius: '24px',
    overflow: 'hidden',
    padding: '1px',
    margin: '0px 3px',
    animation: `$gradientAnimation 15s ease infinite`,
    transition: 'transform 0.3s ease'
    // '&:hover': {
    //   background: '#f3f1f5',
    //   transform: 'scale(1.01)'
    // }
  },
  card: {
    color: '#280d5f',
    height: '100%',
    display: 'flex',
    padding: '16px',
    background: '#0d1620',
    transition: 'transform 0.3s ease, background 0.3s ease',
    flexDirection: 'column',
    justifyContent: 'space-between',
    overflow: 'hidden',
    borderBottomLeftRadius: '24px',
    borderBottomRightRadius: '24px'
  },
  statusBox: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px',
    transition: 'background 0.3s ease',
    overflow: 'hidden',
    borderTopLeftRadius: '24px',
    borderTopRightRadius: '24px'
  },
  innerBox: {
    background: '#fff',
    borderRadius: '14px',
    padding: '16px',
    transition: 'background 0.3s ease',
    flexGrow: 1
  },
  title: {
    fontWeight: 600,
    fontSize: '18px'
  },
  titleHovr: {
    fontSize: '15px',
    '&:hover': {
      borderBottom: '1px solid black', // Adjust the border as needed
      cursor: 'pointer'
    }
  },
  label: {
    color: '#000',
    fontSize: '14px'
  },
  value: {
    textAlign: 'end',
    color: '#280d5f',
    lineHeight: '1.5',
    fontWeight: 'bold',
    fontSize: '16px'
  },
  button: {
    marginTop: '8px',
    transition: 'opacity 0.3s ease, transform 0.3s ease',
    color: '#fff',
    background: '#000',
    padding: '10px',
    border: 'none',
    '&:hover': {
      color: '#000 !important',
      background: '#fff !important'
    },
    '&.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.26) !important',
      background: 'gray !important'
    }
  },
  numberButton: {
    margin: theme.spacing(1),
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    textAlign: 'center',
    lineHeight: '40px',
    cursor: 'pointer',
    color: '#fff',
    backgroundSize: '200% 200%',
    animation: `$gradientAnimation 15s ease infinite`,
    transition: 'background 0.3s ease, color 0.3s ease',
    border: '1px solid gray',
    '&.selected': {
      backgroundImage: 'linear-gradient(135deg, #00f260 0%, #00f260 100%) !important',
      color: '#fff'
    }
  },
  flipped: {
    transform: 'rotateY(180deg)' // Rotate card 180 degrees
  },
  '@keyframes gradientAnimation': {
    '0%': { backgroundPosition: '0% 50%' },
    '50%': { backgroundPosition: '100% 50%' },
    '100%': { backgroundPosition: '0% 50%' }
  },
  cardBack: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backfaceVisibility: 'hidden',
    transform: 'rotateY(180deg)', // Rotate back face 180 degrees
    background: '#fff', // Example background for the back face
    color: '#000',
    padding: '16px'
  }
}))

const PredictionCard = ({
  currentPastItem,
  setCurrentPastItem,
  particularParticipantListGetter,
  activeTab,
  status,
  lastPrice,
  prizePool,
  item,
  callBack,
  selectedAddress,
  latestPrize,
  closePrize
}) => {
  const { account, library } = useWeb3React()
  const classes = useStyles()
  const gradientColor = getGradientColor(status)
  const { chipColor, boxColor, cardContentColor } = getStatusColors(status)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoading1, setIsLoading1] = useState(true)
  const [particularSelectedData, setParticularSelectedData] = useState({})
  const [timeRemain, setTimeRemain] = useState(calculateTimeLeft())
  const [isFlipped, setIsFlipped] = useState(false) // State for flipping
  const [selectedNumber, setSelectedNumber] = useState(null)

  const [selectedData, setSelectedData] = useState({
    open: false,
    id: ''
  })
  const [remainingTime, setRemainingTime] = useState(null)
  const [isCountdown, setIsCountdown] = useState(false)
  const handleNumberClick = number => {
    setSelectedNumber(number)
  }

  const participate = async () => {
    if (!window.ethereum) {
      toast('MetaMask is not installed')
      return
    }

    if (account !== undefined) {
      const isParticipated = item?.participate && item?.participate.find(check => check?.walletAddress === account)

      if (isParticipated) {
        return toast('You are already participated in this pool.')
      }
    } else {
      return toast.error('Please connect your wallet.')
    }

    try {
      setIsLoading(true)
      const provider = new ethers.providers.Web3Provider(window.ethereum)
      await provider.send('eth_requestAccounts', []) // Request account access if needed
      const signer = provider.getSigner()
      const participateContract = getContract(selectedAddress, PredicationABI, library, account)
      const contract = new ethers.Contract(selectedAddress, PredicationABI, signer)
      const tx = await contract.participate(selectedNumber.toString())
      toast('Transaction sent, waiting for confirmation...')
      await tx.wait()
      await participateinPoolHandler()
    } catch (error) {
      console.log('>>>>>>>>>>>..error', error)
      toast.error(`Error: ${error.message}`)
      setIsLoading(false)
    }
  }
  // API to participate in pool
  const participateinPoolHandler = async () => {
    try {
      const res = await apiRouterCall({
        method: 'POST',
        endPoint: 'participatesUser',
        data: {
          _id: selectedData?.id,
          walletAddress: account,
          participateNum: selectedNumber
        }
      })
      if (res.data.responseCode === 200) {
        toast.success('Transaction confirmed!')
        setIsLoading(false)
        setSelectedData({
          open: false,
          id: ''
        })
        callBack()
      }
    } catch (error) {
      setIsLoading(false)
      toast.error(`Error: ${error.response.data.responseMessage}`)
    }
  }
  // // API to get particular user data participateList
  // const participateinPoolUserData = async selectedItem => {
  //   try {
  //     const res = await apiRouterCall({
  //       method: 'POST',
  //       endPoint: 'participateList',
  //       data: {
  //         symbol: selectedItem?.symbol,
  //         walletAddress: account,
  //         poolId: selectedItem?.poolId
  //       }
  //     })
  //     if (res.data.responseCode === 200) {
  //       setParticularSelectedData(res.data?.docs?.result[0])
  //       setIsLoading1(false)
  //     }
  //   } catch (error) {
  //     setIsLoading1(false)
  //   }
  // }

  useEffect(() => {
    let intervalId

    const checkTime = () => {
      const currentTime = new Date().getTime()
      const expirationTime = new Date(item?.endDate).getTime()
      if (Math.abs(currentTime - expirationTime) < 1000) {
        // Call the callback function
        // toast.success('function call')
        callBack()
        setIsCountdown(true)
        setRemainingTime(60) // Set initial countdown time to 60 seconds
        clearInterval(intervalId) // Clear the interval once the expiration time is reached
      }
    }

    if (!isCountdown) {
      // Start the interval to check the time every second
      intervalId = setInterval(checkTime, 1000)
    }

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId)
  }, [item, callBack, isCountdown])

  return (
    <Box className={classes.root} style={{ backgroundImage: gradientColor }}>
      <Box className={classes.statusBox} style={{ background: boxColor }}>
        <Box>
          <Chip label={status} style={{ backgroundColor: chipColor, color: '#fff' }} />
          <Typography className={classes.title} variant="h6">
            Last Price : {status === 'Live' && <>${latestPrize && <NumberAnimation targetNumber={latestPrize} />}</>}
            {status === 'Expired' && item?.closePrice ? `$${Number(item?.closePrice).toFixed(2)}` : ''}
          </Typography>
        </Box>
        <Lefttimer item={item} setTimeRemain={item => setTimeRemain(item)} />
      </Box>
      <Box className={`${classes.card} ${isFlipped ? classes.flipped : ''}`}>
        <Box
          onClick={() => {
            if (account) {
              // setIsFlipped(!isFlipped)
              // participateinPoolUserData(item)
              // particularParticipantListGetter(item)
              setCurrentPastItem(item)
            }
          }}
          width="100%"
          style={{ display: 'flex', flexDirection: 'column', cursor: 'pointer' }}
        >
          <Box className={classes.innerBox}>
            <Grid container justify="space-between" alignItems="center">
              <Typography className={classes.title} variant="h6">
                Pool Id #{item?.poolId}
              </Typography>
              <Typography
                className={`${classes.title} ${classes.titleHovr}`}
                variant="h6"
                onClick={() => {
                  if (account) {
                    // particularParticipantListGetter(item)
                    setCurrentPastItem(item)
                  }
                }}
              >
                Click here
              </Typography>
            </Grid>
            <CommonContent classes={classes} item={item} prizePool={prizePool} status={status} />
          </Box>
        </Box>

        <Grid
          style={{ transform: isFlipped && 'rotateY(180deg)' }}
          container
          justifyContent="center"
          alignItems="center"
        >
          {[...Array(9).keys()].map(number => {
            const filterNumber = item?.participate && item?.participate.find(check => check?.walletAddress === account)
            return (
              <Box
                key={number}
                className={`${classes.numberButton} ${
                  (selectedNumber || (filterNumber?.selectedNum && Number(filterNumber?.selectedNum))) === number + 1
                    ? 'selected'
                    : ''
                }`}
                onClick={() => {
                  if (status === 'Live') {
                    handleNumberClick(number + 1)
                  }
                }}
                // style={{
                //   backgroundImage: getNumberButtonGradient(number + 1)
                // }}
              >
                {number + 1}
              </Box>
            )
          })}
        </Grid>
        <Button
          style={{ transform: isFlipped && 'rotateY(180deg)' }}
          className={classes.button}
          disabled={
            isLoading ||
            selectedNumber === null ||
            status === 'Expired' ||
            (Number(timeRemain?.seconds) <= 30 && Number(timeRemain?.minutes) <= 0) ||
            !timeRemain
          }
          onClick={() => {
            if (Number(item?.participants) === Number(30)) {
              toast('Pool is full. Max 30 users can participate per pool.')
            } else {
              setSelectedData({
                open: true,
                id: item?._id
              })
            }
          }}
          variant="contained"
          color="primary"
        >
          {isLoading ? 'Processing...' : 'Participate'}
        </Button>
      </Box>

      {selectedData.open && (
        <ConfirmationModal
          classes={classes}
          isLoading={isLoading}
          open={selectedData.open}
          handleConfirm={item => participate(item)}
          handleClose={() => {
            setSelectedData({
              open: false,
              id: ''
            })
          }}
        />
      )}
    </Box>
  )
}

export default PredictionCard

const CommonContent = ({ height, classes, prizePool, item, status }) => {
  return (
    <Grid container justifyContent="space-between" style={{ marginTop: height || '16px' }}>
      <Grid item xs={6}>
        <Typography className={classes.label} variant="body2" color="textSecondary">
          Pool Prize
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography
          style={{
            color: '#31d0aa',
            lineHeight: '1.5',
            fontWeight: 'bold',
            fontSize: '16px'
          }}
          className={classes.value}
          variant="body2"
          color="textSecondary"
        >
          {`${formatNumber(prizePool)} TARAL`}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography className={classes.label} variant="body2" color="textSecondary">
          Start Price
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography className={classes.value} variant="body2" color="textSecondary">
          ${Number(item?.cuurentPrice).toFixed(2) || 0}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography className={classes.label} variant="body2" color="textSecondary">
          Close Price
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography className={classes.value} variant="body2" color="textSecondary">
          ${item?.closePrice ? Number(item?.closePrice).toFixed(2) : 'N/A'}
        </Typography>
      </Grid>

      <Grid item xs={6}>
        <Typography className={classes.label} variant="body2" color="textSecondary">
          Winning No.
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography className={classes.value} variant="body2" color="textSecondary">
          {item?.winningNumber || 'N/A'}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography className={classes.label} variant="body2" color="textSecondary">
          {status === 'Live' ? 'Current Participant' : 'Total Participant'}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography className={classes.value} variant="body2" color="textSecondary">
          {item?.participants || 0}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography className={classes.label} variant="body2" color="textSecondary">
          Total Winner
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography className={classes.value} variant="body2" color="textSecondary">
          {item?.winnerAddress?.length || '0'}
        </Typography>
      </Grid>
    </Grid>
  )
}
