export const contractAddresses = {
  weth: {
    1206: '0x0aa0C9E19117483d0Aa3bd4Ef3E4Bf5AaC121BD8',
    4442: '0x2e1Ad83B4F2AE3EFD169E1d161dD818C0b73AB15'
  }
}

const multicall = {
  1206: '0x237Cd3f0241979552C52d9C61cE3c9d7a2B72eA7', //multicall address
  4442: '0xdCAD3FB7544EBcf5A8beC3C152E3A6066EA2650A'
 
}
const chainId = 4442

interface AddressProps {
  4442: string // Address of the contract
}
export const getAddress = (obj: AddressProps) => {
  return obj[chainId] ? obj[chainId] : obj
}

export const getMulticallAddress = () => {
  return multicall[chainId]
}

export const getPipiAddress = () => {
  return getAddress(contractAddresses.weth)
}
