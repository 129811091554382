import React, { useEffect } from 'react'
import { makeStyles, Paper, Typography, Box, Grid } from '@material-ui/core'
const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    margin: '0 5px',
    background: 'rgba(38, 36, 52, 0.3)',
    boxShadow: '0px 7px 12px rgb(0 0 0 / 15%)',
    backdropFilter: 'blur(31.3725px)',
    border: '1px solid #16032999',
    borderRadius: '10px',
    overflow: 'hidden',
    cursor: 'pointer',

    '& .basecontent': {
      '& .databox': {
        borderBottom: '1px solid #16032999',
        paddingBottom: '16px'
      },
      '& .buttonbox': {
        paddingTop: '16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }
    }
  },
  text: {
    whiteSpace: 'pre',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: 'calc(100% - 5px)',
    color: '#fff'
  },
  mainimg: {
    width: '100%',
    height: '190px ',
    overflow: 'hidden',
    backgroundPosition: 'center !important',
    backgroundSize: 'cover !important',
    backgroundRepeat: ' no-repeat !important',
    borderRadius: '5px 5px 0px 0px',
    backgroundColor: '#ccc !important',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    zIndex: '-1',
    '& .topcontent': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      margin: '15px',
      '& .topleft': {
        display: 'flex',
        alignItems: 'center',
        background: '#FFFFFF',
        borderRadius: '10px',
        padding: '5px 8px',
        width: 'fit-content',
        '& p': {
          marginLeft: '5px',
          color: '#4da7f0',
          [theme.breakpoints.down('xs')]: {
            fontSize: '10px'
          },
          '& span': {
            color: '#000000'
          }
        },
        '& .Userbox': {
          display: 'flex',
          alignItems: 'center',
          '& figure': {
            margin: '0',
            marginLeft: '-10px',
            height: '25px',
            width: '25px',
            borderRadius: '50%',
            overflow: 'hidden',
            backgroundColor: '#101010',
            position: 'relative',
            transition: '0.3s',
            cursor: 'pointer',
            '&:first-child': {
              marginLeft: '0px'
            },
            '&:hover': {
              zIndex: '2',
              transform: 'scale(1.2)'
            },
            '& img': {
              width: 'auto',
              maxWidth: '100%',
              maxHeight: '41px'
            }
          }
        }
      },
      '& .likes': {
        display: 'flex',
        alignItems: 'center',
        background: '#FFFFFF',
        borderRadius: '10px',
        width: 'fit-content',
        padding: '5px 8px',
        '& p': {
          marginLeft: '5px',
          color: '#000'
        }
      }
    },
    '& .bottomcontent': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: '10px',
      '& .timer': {
        display: 'flex',
        alignItems: 'center',
        width: 'fit-content',
        background: 'linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%)',
        border: '1px dashed #FFFFFF',
        filter: 'drop-shadow(0px 0px 53px rgba(0, 0, 0, 0.25))',
        backdropFilter: 'blur(42px)',
        borderRadius: '10px',
        padding: '5px 10px',
        '& h6': {
          color: '#FFFFFF'
        }
      }
    }
  },
  pricedata: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    '& h6': {
      fontSize: '14px',
      color: '#000',
      display: 'flex',
      alignItems: 'center'
    }
  },
  customizedButton: {
    position: 'absolute',
    top: '-42px',
    right: '-9px',
    color: '#fff'
  }
}))

function NormalNftCards(props) {
  const classes = useStyles()
  const { data, type } = props
  const updateDimensions = () => {
    var offsetWidth = document.getElementById('imagecard' + data?._id).offsetWidth
    var newoofsetWidth = offsetWidth - 80
    document.getElementById('imagecard' + data?._id).style.height = newoofsetWidth + 'px'
  }
  useEffect(() => {
    updateDimensions()
  }, [data, data?._id])
  useEffect(() => {
    window.addEventListener('resize', updateDimensions)
    return () => window.removeEventListener('resize', updateDimensions)
  }, [])

  return (
    <>
      <Paper className={classes.root}>
        <Box
          id={`imagecard${data?._id}`}
          className={classes.mainimg}
          style={data?.coverImage ? { background: 'url(' + data?.coverImage + ')' } : { background: '#000' }}
        ></Box>

        <Box className="basecontent">
          <Box p={2}>
            <Box className="databox">
              <Grid container spacing={1}>
                <Grid item xs={6} sm={12} align="left">
                  <Typography variant="h6" className={classes.text}>
                    {data.tokenName}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Paper>
    </>
  )
}

export default NormalNftCards
