import React, { useRef } from 'react'
import { Box, makeStyles } from '@material-ui/core'
import Mint from './Mint'
import Header from 'components/Header'
import Footer from 'pages/HomePage1/footer/Footer'

const useStyles = makeStyles(theme => ({
  mainBg: {
    background: 'url(images/shape/framebg.png)',
    backgroundRepeat: 'no-repeat'
  }
}))

function FreeMint() {
  const classes = useStyles()
  return (
    <Box
      style={{ width: '100%', position: 'relative' }}

      // className={classes.mainBg}
    >
      <Box style={{ position: 'absolute', right: '30px', top: '50px' }}>
        <img src="/images/greenishbg.png" style={{ width: '100%', maxHeight: '700px' }} />
      </Box>
      <Box
        style={{
          top: '40%',
          left: '-70px',
          right: '0%',
          width: '314px',
          filter: 'blur(94px)',
          height: '314px',
          opacity: '40%',
          zIndex: '2',
          position: 'absolute',
          transform: 'rotate(-90deg)',
          background: '#FED056',
          borderRadius: '1000px'
        }}
      >
        {/* <img src='/images/greenishbg.png' style={{width:"100%",maxHeight:"400px"}} /> */}
      </Box>
      <Header />
      <Mint />
      <Footer />
    </Box>
  )
}

export default FreeMint
