import { toPlainString } from './helperFunctions'
// import {
//     fetchBalance,
//     fetchToken,
//     writeContract,
//     readContracts,
//   } from "@wagmi/core";
// export async function getTokenDetails(tokenAddress, { walletAddress }) {
//     if (walletAddress) {
//       const getToken = await fetchToken({
//         address: tokenAddress,
//       });
//       const walletAmount = await fetchBalance({
//         address: walletAddress,
//         token: tokenAddress,
//       });
//       return {
//         name: getToken.name,
//         symbol: getToken.symbol,
//         totalsupply: getToken.totalSupply.formatted,
//         decimals: getToken.decimals,
//         balance: walletAmount.formatted,
//         token_address: tokenAddress,
//       };
//     }
//   }

import { ethers } from 'ethers'

export async function getTokenDetails(tokenAddress, walletAddress) {
  if (walletAddress) {
    // Connect to an Ethereum node
    const provider = new ethers.providers.JsonRpcProvider('https://devnet-rpc1.tanledger.com')

    // ERC-20 Token ABI
    const erc20Abi = [
      'function name() view returns (string)',
      'function symbol() view returns (string)',
      'function totalSupply() view returns (uint256)',
      'function decimals() view returns (uint8)',
      'function balanceOf(address) view returns (uint256)'
    ]

    // Create contract instance
    const tokenContract = new ethers.Contract(tokenAddress, erc20Abi, provider)

    // Fetch token details
    const [name, symbol, totalSupply, decimals, balance] = await Promise.all([
      tokenContract.name(),
      tokenContract.symbol(),
      tokenContract.totalSupply(),
      tokenContract.decimals(),
      tokenContract.balanceOf(walletAddress)
    ])

    return {
      name,
      symbol,
      totalsupply: ethers.utils.formatUnits(totalSupply, decimals),
      decimals,
      balance: ethers.utils.formatUnits(balance, decimals),
      token_address: tokenAddress
    }
  }
}
