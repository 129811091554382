// import { splitSignature } from '@ethersproject/bytes'
// import { Contract } from '@ethersproject/contracts'
// import { TransactionResponse } from '@ethersproject/providers'
import { Currency, currencyEquals, ETHER, Percent, WETH } from 'calculas-sdk'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { ArrowDown, Plus } from 'react-feather'
// import ReactGA from 'react-ga'
import { RouteComponentProps } from 'react-router'
import { Text } from 'rebass'
import styled, { ThemeContext } from 'styled-components'
import { ButtonPrimary, ButtonLight, ButtonError, ButtonConfirmed } from '../../components/Button'
import { LightCard } from '../../components/Card'
import { AutoColumn, ColumnCenter } from '../../components/Column'
import TransactionConfirmationModal, { ConfirmationModalContent } from '../../components/TransactionConfirmationModal'
import CurrencyInputPanel from '../../components/CurrencyInputPanel'
import DoubleCurrencyLogo from '../../components/DoubleLogo'
import { AddRemoveTabs } from '../../components/NavigationTabs'
import { MinimalPositionCard } from '../../components/PositionCard'
import Row, { RowBetween, RowFixed } from '../../components/Row'
import ApproveABI from '../../mint-constants/ABI/IERC20ABI.json'
import NewFactoryABI from "../../constants/abis/NewFactoryABI.json"
import Slider from '../../components/Slider'
import CurrencyLogo from '../../components/CurrencyLogo'
import { FACTORY_ADDRESS, ROUTER_ADDRESS } from '../../constants'

import { useActiveWeb3React } from '../../hooks'
import { useCurrency } from '../../hooks/Tokens'
// import { usePairContract } from '../../hooks/useContract'

// import { useTransactionAdder } from '../../state/transactions/hooks'
import { StyledInternalLink, TYPE } from '../../components/Shared'
import {
  // calculateGasMargin,
  // calculateSlippageAmount,
  getRouterContract
} from '../../utils'
import { currencyId } from '../../utils/currencyId'
import useDebouncedChangeHandler from '../../utils/useDebouncedChangeHandler'
import { wrappedCurrency } from '../../utils/wrappedCurrency'
import AppBody from '../AppBody'
import { ClickableText, MaxButton, Wrapper } from '../Pool/styleds'
import { useApproveCallback, ApprovalState } from '../../hooks/useApproveCallback'
import { Dots } from '../../components/swap/styleds'
import { useBurnActionHandlers } from '../../state/burn/hooks'
import { useDerivedBurnInfo, useBurnState } from '../../state/burn/hooks'
import { Field } from '../../state/burn/actions'
import { useWalletModalToggle } from '../../state/application/hooks'
import { 
  useUserDeadline, 
  useUserSlippageTolerance } from '../../state/user/hooks'
import { useI18n } from 'i18n/i18n-react'
// import { BigNumber } from '@ethersproject/bignumber'
// import SideNavbar from 'components/SideNavbar'
import Header from 'components/Header'
import { ethers } from 'ethers'
import { getProviderOrSigner } from 'mint-utils'
import toast from 'react-hot-toast'
// import { useWeb3React } from '@web3-react/core'

export default function RemoveLiquidity({
  history,
  match: {
    params: { currencyIdA, currencyIdB }
  }
}: RouteComponentProps<{ currencyIdA: string; currencyIdB: string }>) {
  const i18n = useI18n()
  const [currencyA, currencyB] = [useCurrency(currencyIdA) ?? undefined, useCurrency(currencyIdB) ?? undefined]
  const { account, chainId, library } = useActiveWeb3React()
 
  const [tokenA, tokenB] = useMemo(() => [wrappedCurrency(currencyA, chainId), wrappedCurrency(currencyB, chainId)], [
    currencyA,
    currencyB,
    chainId
  ])
  

  const theme = useContext(ThemeContext)

  // toggle wallet when disconnected
  const toggleWalletModal = useWalletModalToggle()

  // burn state
  const { independentField, typedValue } = useBurnState()
  const { pair, parsedAmounts, error } = useDerivedBurnInfo(currencyA ?? undefined, currencyB ?? undefined)
  const { onUserInput: _onUserInput } = useBurnActionHandlers()
  const isValid = !error

  // modal and loading
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [showDetailed, setShowDetailed] = useState<boolean>(false)
  const [tokenApproving, setTokenApproving] = useState<boolean>(false)
  const [tokingRemoving, setTokingRemoving] = useState<boolean>(false)
  const [tokenApproval, setTokenApproval] = useState<boolean>(false)
  // const [attemptingTxn, setAttemptingTxn] = useState(false) // clicked confirm

  // txn values
  const [txHash, setTxHash] = useState<string>('')
  const [deadline] = useUserDeadline()
  const [allowedSlippage] = useUserSlippageTolerance()

  const formattedAmounts = {
    [Field.LIQUIDITY_PERCENT]: parsedAmounts[Field.LIQUIDITY_PERCENT].equalTo('0')
      ? '0'
      : parsedAmounts[Field.LIQUIDITY_PERCENT].lessThan(new Percent('1', '100'))
      ? '<1'
      : parsedAmounts[Field.LIQUIDITY_PERCENT].toFixed(0),
    [Field.LIQUIDITY]:
      independentField === Field.LIQUIDITY ? typedValue : parsedAmounts[Field.LIQUIDITY]?.toSignificant(6) ?? '',
    [Field.CURRENCY_A]:
      independentField === Field.CURRENCY_A ? typedValue : parsedAmounts[Field.CURRENCY_A]?.toSignificant(6) ?? '',
    [Field.CURRENCY_B]:
      independentField === Field.CURRENCY_B ? typedValue : parsedAmounts[Field.CURRENCY_B]?.toSignificant(6) ?? ''
  }

  const atMaxAmount = parsedAmounts[Field.LIQUIDITY_PERCENT]?.equalTo(new Percent('1'))

  // pair contract
  // const pairContract: Contract | null = usePairContract(pair?.liquidityToken?.address)

  // allowance handling
  const [signatureData, setSignatureData] = useState<{ v: number; r: string; s: string; deadline: number } | null>(null)
  const [
    approval
    //  approveCallback
  ] = useApproveCallback(parsedAmounts[Field.LIQUIDITY], ROUTER_ADDRESS)
  console.log('>>>>>>>approval', approval)

  // async function onAttemptToApprove() {
  //   if (!pairContract || !pair || !library) throw new Error('missing dependencies')
  //   const liquidityAmount = parsedAmounts[Field.LIQUIDITY]
  // console.log(">>>>>>>>>.liquidityAmount",liquidityAmount);

  //   if (!liquidityAmount) throw new Error('missing liquidity amount')
  //   // try to gather a signature for permission
  //   const nonce = await pairContract.nonces(account)

  //   const deadlineForSignature: number = Math.ceil(Date.now() / 1000) + deadline
  //   console.log("deadline:", deadline, "deadlineFS:", deadlineForSignature, "nonce:", nonce);
  //   const EIP712Domain = [
  //     { name: 'name', type: 'string' },
  //     { name: 'version', type: 'string' },
  //     { name: 'chainId', type: 'uint256' },
  //     { name: 'verifyingContract', type: 'address' },
  //   ]
  //   const domain = {
  //     name: 'Differentitation-LPs',
  //     version: '1',
  //     chainId: chainId,
  //     verifyingContract: pair.liquidityToken.address,
  //   }
  //   const Permit = [
  //     { name: 'owner', type: 'address' },
  //     { name: 'spender', type: 'address' },
  //     { name: 'value', type: 'uint256' },
  //     { name: 'nonce', type: 'uint256' },
  //     { name: 'deadline', type: 'uint256' },
  //   ]
  //   console.log(">>>>>>>>>>>>liquidityAmount.raw.toString(),",liquidityAmount.raw.toString(),);

  //   const message = {
  //     owner: account,
  //     spender: ROUTER_ADDRESS,
  //     value: liquidityAmount.raw.toString(),
  //     nonce: nonce.toHexString(),
  //     deadline: deadlineForSignature,
  //   }

  //   const data = JSON.stringify({
  //     types: {
  //       EIP712Domain,
  //       Permit,
  //     },
  //     domain,
  //     primaryType: 'Permit',
  //     message,
  //   })
  //   //  console.log(pair, data);
  //   library
  //     .send('eth_signTypedData_v4', [account, data])
  //     .then(splitSignature)
  //     .then((signature) => {
  //       // console.log('checksignature::', signature)
  //       setSignatureData({
  //         v: signature.v,
  //         r: signature.r,
  //         s: signature.s,
  //         deadline: deadlineForSignature,
  //       })
  //     })
  //     .catch((error) => {
  //       // for all errors other than 4001 (EIP-1193 user rejected request), fall back to manual approve
  //       if (error?.code !== 4001) {
  //         approveCallback()
  //       }
  //     })
  // }

  // function to approve token
  // const { account, library, chainId } = useWeb3React()

  const approveLPToken = async () => {
    try {
      setTokenApproving(true)
      // const tokenAddress = '0x33ae9460F5fE71a64DA8520AfbE28c5d738F3316'
      
      const signer = await getProviderOrSigner(library, account)
      const factoryContract = new ethers.Contract(FACTORY_ADDRESS, NewFactoryABI, signer)
      console.log(">>>>>>factoryContract",factoryContract);
      
      const pairAddress = await factoryContract.getPair(tokenA?.address,  tokenB?.address)
      console.log(">>>>>>>>>>>>pairAddress",pairAddress);
      
      const tokenContract = new ethers.Contract(pairAddress, ApproveABI, signer)
      const liquidityAmount = parsedAmounts[Field.LIQUIDITY]
      // const amountInWei = ethers.utils.parseUnits( liquidityAmount.raw.toString(), "ether");
     

      console.log('>>>>>>amountInWei', liquidityAmount && liquidityAmount.raw.toString())
      const tx = await tokenContract.approve(ROUTER_ADDRESS,
        "3162277660168379330998"
        //  liquidityAmount && liquidityAmount.raw.toString()
        )
      const receipt = await tx.wait()
      console.log('Token approved:', receipt)
      toast.success('Token approved successfully');
      setTokenApproving(false)
      setTokenApproval(true)
      return true
    } catch (error) {
      setTokenApproving(false)
      if (error?.message.includes("user rejected")) {
        toast.error('You rejected the transaction');
      } else {
        toast.error(`Error: ${error?.message}`);
      }
      console.log('>>>>>>>>>>>error', error)
      return false
    }
  }

  // wrapped onUserInput to clear signatures
  const onUserInput = useCallback(
    (field: Field, typedValue: string) => {
      setSignatureData(null)
      return _onUserInput(field, typedValue)
    },
    [_onUserInput]
  )

  const onLiquidityInput = useCallback((typedValue: string): void => onUserInput(Field.LIQUIDITY, typedValue), [
    onUserInput
  ])
  const onCurrencyAInput = useCallback((typedValue: string): void => onUserInput(Field.CURRENCY_A, typedValue), [
    onUserInput
  ])
  const onCurrencyBInput = useCallback((typedValue: string): void => onUserInput(Field.CURRENCY_B, typedValue), [
    onUserInput
  ])

  // tx sending
  // const addTransaction = useTransactionAdder()

  // const onRemove = async () => {
  //   try {
  //     if (!chainId || !library || !account) throw new Error('Missing dependencies');

  //     const { [Field.CURRENCY_A]: currencyAmountA, [Field.CURRENCY_B]: currencyAmountB } = parsedAmounts;
  //     if (!currencyAmountA || !currencyAmountB) throw new Error('Missing currency amounts');

  //     const router = getRouterContract(chainId, library, account);
  //     console.log("Router:", router);

  //     const amountsMin = {
  //       [Field.CURRENCY_A]: calculateSlippageAmount(currencyAmountA, allowedSlippage)[0],
  //       [Field.CURRENCY_B]: calculateSlippageAmount(currencyAmountB, allowedSlippage)[0],
  //     };

  //     if (!currencyA || !currencyB) throw new Error('Missing tokens');
  //     const liquidityAmount = parsedAmounts[Field.LIQUIDITY];
  //     if (!liquidityAmount) throw new Error('Missing liquidity amount');

  //     const currencyBIsETH = currencyB === ETHER;
  //     const oneCurrencyIsETH = currencyA === ETHER || currencyBIsETH;
  //     const deadlineFromNow = Math.ceil(Date.now() / 1000) + deadline;
  //     console.log("Deadline:", deadlineFromNow);

  //     if (!tokenA || !tokenB) throw new Error('Could not wrap tokens');

  //     let methodNames:any = [];
  //     let args:any = [];

  //     console.log("Approval state:", approval === ApprovalState.APPROVED, approval, ApprovalState.APPROVED);

  //     if (approval === ApprovalState.APPROVED) {
  //       if (oneCurrencyIsETH) {
  //         methodNames = ['removeLiquidityETH', 'removeLiquidityETHSupportingFeeOnTransferTokens'];
  //         args = [
  //           currencyBIsETH ? tokenA.address : tokenB.address,
  //           liquidityAmount.raw.toString(),
  //           amountsMin[currencyBIsETH ? Field.CURRENCY_A : Field.CURRENCY_B].toString(),
  //           amountsMin[currencyBIsETH ? Field.CURRENCY_B : Field.CURRENCY_A].toString(),
  //           account,
  //           deadlineFromNow,
  //         ];
  //       } else {
  //         methodNames = ['removeLiquidity'];
  //         args = [
  //           tokenA.address,
  //           tokenB.address,
  //           liquidityAmount.raw.toString(),
  //           amountsMin[Field.CURRENCY_A].toString(),
  //           amountsMin[Field.CURRENCY_B].toString(),
  //           account,
  //           deadlineFromNow,
  //         ];
  //       }
  //     } else if (signatureData !== null) {
  //       console.log("Signature data:", signatureData);

  //       if (oneCurrencyIsETH) {
  //         methodNames = ['removeLiquidityETHWithPermit', 'removeLiquidityETHWithPermitSupportingFeeOnTransferTokens'];
  //         args = [
  //           currencyBIsETH ? tokenA.address : tokenB.address,
  //           liquidityAmount.raw.toString(),
  //           amountsMin[currencyBIsETH ? Field.CURRENCY_A : Field.CURRENCY_B].toString(),
  //           amountsMin[currencyBIsETH ? Field.CURRENCY_B : Field.CURRENCY_A].toString(),
  //           account,
  //           signatureData.deadline,
  //           false,
  //           signatureData.v,
  //           signatureData.r,
  //           signatureData.s,
  //         ];
  //       } else {
  //         methodNames = ['removeLiquidityWithPermit'];
  //         args = [
  //           tokenA.address,
  //           tokenB.address,
  //           liquidityAmount.raw.toString(),
  //           amountsMin[Field.CURRENCY_A].toString(),
  //           amountsMin[Field.CURRENCY_B].toString(),
  //           account,
  //           signatureData.deadline,
  //           false,
  //           signatureData.v,
  //           signatureData.r,
  //           signatureData.s,
  //         ];
  //       }
  //     } else {
  //       throw new Error('Attempting to confirm without approval or a signature. Please contact support.');
  //     }
  //     console.log("Method names and args:", methodNames, args);

  //     const safeGasEstimates = await Promise.all(
  //       methodNames.map(async (methodName:any, index:any) => {
  //         try {
  //           console.log(`Estimating gas for ${methodName} with args:`, args);
  //           const gasEstimate = await router.estimateGas[methodName](...args);
  //           console.log(`Gas estimate for ${methodName}:`, gasEstimate);
  //           return calculateGasMargin(gasEstimate);
  //         } catch (error) {
  //           console.log(">>>>>>>error",error);
  //           console.error(`Gas estimation failed for ${methodName} at index ${index}:`, error);
  //           return undefined;
  //         }
  //       })
  //     );

  //     console.log("Safe gas estimates:", safeGasEstimates);

  //     const indexOfSuccessfulEstimation = safeGasEstimates.findIndex((safeGasEstimate) =>
  //       ethers.BigNumber.isBigNumber(safeGasEstimate)
  //     );

  //     console.log("Index of successful gas estimation:", indexOfSuccessfulEstimation);

  //     if (indexOfSuccessfulEstimation === -1) {
  //       console.error('This transaction would fail. Please contact support.');
  //     } else {
  //       const methodName = methodNames[indexOfSuccessfulEstimation];
  //       const safeGasEstimate = safeGasEstimates[indexOfSuccessfulEstimation];

  //       setAttemptingTxn(true);

  //       try {
  //         const response = await router[methodName](...args, { gasLimit: safeGasEstimate });
  //         setAttemptingTxn(false);

  //         addTransaction(response, {
  //           summary: `Remove ${parsedAmounts[Field.CURRENCY_A]?.toSignificant(3)} ${currencyA?.symbol} and ${parsedAmounts[Field.CURRENCY_B]?.toSignificant(3)} ${currencyB?.symbol}`,
  //         });

  //         setTxHash(response.hash);

  //         ReactGA.event({
  //           category: 'Liquidity',
  //           action: 'Remove',
  //           label: `${currencyA?.symbol}/${currencyB?.symbol}`,
  //         });
  //       } catch (error) {
  //         setAttemptingTxn(false);
  //         console.error('Transaction failed:', error);
  //       }
  //     }
  //   } catch (error) {
  //     console.error('Error in onRemove:', error);
  //   }
  // };

  // function to remove liquitiy
  const removeLiquidityHandler = async () => {
    try {
      if (!chainId || !library || !account) throw new Error('Missing dependencies');
      const { [Field.CURRENCY_A]: currencyAmountA, [Field.CURRENCY_B]: currencyAmountB } = parsedAmounts;
      if (!currencyAmountA || !currencyAmountB) throw new Error('Missing currency amounts');
      const router = getRouterContract(chainId, library, account);
      if (!currencyA || !currencyB) throw new Error('Missing tokens');
      const liquidityAmount = parsedAmounts[Field.LIQUIDITY];
      if (!liquidityAmount) throw new Error('Missing liquidity amount');
      const currencyBIsETH = currencyB === ETHER;
      const oneCurrencyIsETH = currencyA === ETHER || currencyBIsETH;
      const deadlineFromNow = Math.ceil(Date.now() / 1000) + deadline;
      if (!tokenA || !tokenB) throw new Error('Could not wrap tokens');
      let methodNames = [];
      let args = [];

      console.log(">>>>>>>>>>signatureData", signatureData);
      console.log(">>>>>>>>>>>>>address", currencyBIsETH ? tokenA.address : tokenB.address, liquidityAmount.raw.toString());
setTokingRemoving(true)
      if (oneCurrencyIsETH) {
        methodNames = ['removeLiquidityETH'];
        args = [
          currencyBIsETH ? tokenA.address : tokenB.address,
          liquidityAmount.raw.toString(),
          0,
          0,
          account,
          deadlineFromNow,
        ];
      } else {
        methodNames = ['removeLiquidity'];
        args = [
          tokenA.address,
          tokenB.address,
          liquidityAmount.raw.toString(),
          0,
          0,
          account,
          deadlineFromNow,
        ];
      }
      console.log(">>>>>>>>>>>>args", args);

      const methodName = methodNames[0];
      console.log('>>>>>>>>>>>>>>methodName', methodName);

      const response = await router[methodName](...args, { gasLimit: 300000 });
      await response.wait();
      console.log('>>>>>>>>response', response);
      toast.success('Liquidity removed successfully!');
      history.push("/pool")
      setTokingRemoving(false)
      setShowConfirm(false)
    } catch (error) {
      console.log('>>>>>>>>>>>error', error);
      if (error?.message.includes("user rejected")) {
        toast.error('You rejected the transaction');
      } else {
        toast.error(`Error: ${error?.message}`);
      }
      setTokingRemoving(false)
    }
  }

  function modalHeader() {
    return (
      <AutoColumn gap={'md'} style={{ marginTop: '20px' }}>
        <RowBetween align="flex-end">
          <Text fontSize={24} fontWeight={500}>
            {parsedAmounts[Field.CURRENCY_A]?.toSignificant(6)}
          </Text>
          <RowFixed gap="4px">
            <CurrencyLogo currency={currencyA} size={'24px'} />
            <Text fontSize={24} fontWeight={500} style={{ marginLeft: '10px' }}>
              {currencyA?.symbol}
            </Text>
          </RowFixed>
        </RowBetween>
        <RowFixed>
          <Plus size="16" color="#fff" />
        </RowFixed>
        <RowBetween align="flex-end">
          <Text fontSize={24} fontWeight={500}>
            {parsedAmounts[Field.CURRENCY_B]?.toSignificant(6)}
          </Text>
          <RowFixed gap="4px">
            <CurrencyLogo currency={currencyB} size={'24px'} />
            <Text fontSize={24} fontWeight={500} style={{ marginLeft: '10px' }}>
              {currencyB?.symbol}
            </Text>
          </RowFixed>
        </RowBetween>

        <TYPE.italic fontSize={12} color="#fff" textAlign="left" padding={'12px 0 0 0'}>
          {`Output is estimated. If the price changes by more than ${allowedSlippage /
            100}% your transaction will revert.`}
        </TYPE.italic>
      </AutoColumn>
    )
  }

  function modalBottom() {
    console.log(
      '>>>>>>>>>>>>>>!(approval === ApprovalState.APPROVED || signatureData !== null)',
      !(approval === ApprovalState.APPROVED || signatureData !== null)
    )

    return (
      <>
        <RowBetween>
          <Text color="#fff" fontWeight={500} fontSize={16}>
            {'FLIP ' + currencyA?.symbol + '/' + currencyB?.symbol} Burned
          </Text>
          <RowFixed>
            <DoubleCurrencyLogo currency0={currencyA} currency1={currencyB} margin={true} />
            <Text fontWeight={500} fontSize={16}>
              {parsedAmounts[Field.LIQUIDITY]?.toSignificant(6)}
            </Text>
          </RowFixed>
        </RowBetween>
        {pair && (
          <>
            <RowBetween>
              <Text color="#fff" fontWeight={500} fontSize={16}>
                Price
              </Text>
              <Text fontWeight={500} fontSize={16} color={theme.colors.text1}>
                1 {currencyA?.symbol} = {tokenA ? pair.priceOf(tokenA).toSignificant(6) : '-'} {currencyB?.symbol}
              </Text>
            </RowBetween>
            <RowBetween>
              <div />
              <Text fontWeight={500} fontSize={16} color={theme.colors.text1}>
                1 {currencyB?.symbol} = {tokenB ? pair.priceOf(tokenB).toSignificant(6) : '-'} {currencyA?.symbol}
              </Text>
            </RowBetween>
          </>
        )}
        <ButtonPrimary
          // disabled={!(approval === ApprovalState.APPROVED || signatureData !== null)}
          //  onClick={onRemove}
          disabled={tokingRemoving}
          onClick={() => removeLiquidityHandler()}
        >
          <Text fontWeight={500} fontSize={20}>
          {tokingRemoving ? <Dots>Removing</Dots>:"Confirm"}  
          </Text>
        </ButtonPrimary>
      </>
    )
  }

  const pendingText = `Removing ${parsedAmounts[Field.CURRENCY_A]?.toSignificant(6)} ${
    currencyA?.symbol
  } and ${parsedAmounts[Field.CURRENCY_B]?.toSignificant(6)} ${currencyB?.symbol}`

  const liquidityPercentChangeCallback = useCallback(
    (value: number) => {
      onUserInput(Field.LIQUIDITY_PERCENT, value.toString())
    },
    [onUserInput]
  )

  const oneCurrencyIsETH = currencyA === ETHER || currencyB === ETHER
  const oneCurrencyIsWETH = Boolean(
    chainId &&
      ((currencyA && currencyEquals(WETH[chainId], currencyA)) ||
        (currencyB && currencyEquals(WETH[chainId], currencyB)))
  )

  const handleSelectCurrencyA = useCallback(
    (currency: Currency) => {
      if (currencyIdB && currencyId(currency) === currencyIdB) {
        history.push(`/remove/${currencyId(currency)}/${currencyIdA}`)
      } else {
        history.push(`/remove/${currencyId(currency)}/${currencyIdB}`)
      }
    },
    [currencyIdA, currencyIdB, history]
  )
  const handleSelectCurrencyB = useCallback(
    (currency: Currency) => {
      if (currencyIdA && currencyId(currency) === currencyIdA) {
        history.push(`/remove/${currencyIdB}/${currencyId(currency)}`)
      } else {
        history.push(`/remove/${currencyIdA}/${currencyId(currency)}`)
      }
    },
    [currencyIdA, currencyIdB, history]
  )

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false)
    setSignatureData(null) // important that we clear signature data to avoid bad sigs
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.LIQUIDITY_PERCENT, '0')
    }
    setTxHash('')
  }, [onUserInput, txHash])

  const [innerLiquidityPercentage, setInnerLiquidityPercentage] = useDebouncedChangeHandler(
    Number.parseInt(parsedAmounts[Field.LIQUIDITY_PERCENT].toFixed(0)),
    liquidityPercentChangeCallback
  )
  const AppWrapper = styled.div`
    //  display: flex;
    //  flex-flow: column;
    //   align-items: flex-start;
    //  overflow-x: hidden;
    //  min-height: 100vh;
  `
  const HeaderWrapper = styled.div`
    ${({ theme }) => theme.flexRowNoWrap}
    // width: 100%;
  justify-content: space-between;
  `
  const BodyWrapper = styled.div`
    position: relative;
    width: 100%;
    max-width: 420px;
    padding: 1rem;
    margin-top: 10px;
  `
  return (
    <>
      <AppWrapper id="mainwrapper">
        <HeaderWrapper>
          <Header />
        </HeaderWrapper>
        <div className="Main-div">
          {/* <div className='sidebar-wrapper'>
            <SideNavbar />
          </div> */}
          <div className="content-wrapper">
            <div className="w-100 d-flex justify-content-center">
              <BodyWrapper>
                <AppBody>
                  <AddRemoveTabs adding={false} />
                  <Wrapper>
                    <TransactionConfirmationModal
                      isOpen={showConfirm}
                      onDismiss={handleDismissConfirmation}
                      attemptingTxn={false}
                      hash={txHash ? txHash : ''}
                      content={() => (
                        <ConfirmationModalContent
                          title={'You will receive'}
                          onDismiss={handleDismissConfirmation}
                          topContent={modalHeader}
                          bottomContent={modalBottom}
                        />
                      )}
                      pendingText={pendingText}
                    />
                    <AutoColumn gap="md">
                      <LightCard>
                        <AutoColumn gap="20px">
                          <RowBetween>
                            <Text fontWeight={500}>Amount</Text>
                            <ClickableText
                              fontWeight={500}
                              onClick={() => {
                                setShowDetailed(!showDetailed)
                              }}
                            >
                              {showDetailed ? 'Simple' : 'Detailed'}
                            </ClickableText>
                          </RowBetween>
                          <Row style={{ alignItems: 'flex-end' }}>
                            <Text fontSize={72} fontWeight={500}>
                              {formattedAmounts[Field.LIQUIDITY_PERCENT]}%
                            </Text>
                          </Row>
                          {!showDetailed && (
                            <>
                              <Slider value={innerLiquidityPercentage} onChange={setInnerLiquidityPercentage} />
                              <RowBetween>
                                <MaxButton onClick={() => onUserInput(Field.LIQUIDITY_PERCENT, '25')} width="20%">
                                  25%
                                </MaxButton>
                                <MaxButton onClick={() => onUserInput(Field.LIQUIDITY_PERCENT, '50')} width="20%">
                                  50%
                                </MaxButton>
                                <MaxButton onClick={() => onUserInput(Field.LIQUIDITY_PERCENT, '75')} width="20%">
                                  75%
                                </MaxButton>
                                <MaxButton onClick={() => onUserInput(Field.LIQUIDITY_PERCENT, '100')} width="20%">
                                  Max
                                </MaxButton>
                              </RowBetween>
                            </>
                          )}
                        </AutoColumn>
                      </LightCard>
                      {!showDetailed && (
                        <>
                          <ColumnCenter>
                            <ArrowDown size="16" color={theme.colors.text2} />
                          </ColumnCenter>
                          <LightCard>
                            <AutoColumn gap="10px">
                              <RowBetween>
                                <Text fontSize={24} fontWeight={500}>
                                  {formattedAmounts[Field.CURRENCY_A] || '-'}
                                </Text>
                                <RowFixed>
                                  <CurrencyLogo currency={currencyA} style={{ marginRight: '12px' }} />
                                  <Text fontSize={24} fontWeight={500} id="remove-liquidity-tokena-symbol">
                                    {currencyA?.symbol}
                                  </Text>
                                </RowFixed>
                              </RowBetween>
                              <RowBetween>
                                <Text fontSize={24} fontWeight={500}>
                                  {formattedAmounts[Field.CURRENCY_B] || '-'}
                                </Text>
                                <RowFixed>
                                  <CurrencyLogo currency={currencyB} style={{ marginRight: '12px' }} />
                                  <Text fontSize={24} fontWeight={500} id="remove-liquidity-tokenb-symbol">
                                    {currencyB?.symbol}
                                  </Text>
                                </RowFixed>
                              </RowBetween>
                              {chainId && (oneCurrencyIsWETH || oneCurrencyIsETH) ? (
                                <RowBetween style={{ justifyContent: 'flex-end' }}>
                                  {oneCurrencyIsETH ? (
                                    <StyledInternalLink
                                      to={`/remove/${currencyA === ETHER ? WETH[chainId].address : currencyIdA}/${
                                        currencyB === ETHER ? WETH[chainId].address : currencyIdB
                                      }`}
                                    >
                                      Receive WHOO
                                    </StyledInternalLink>
                                  ) : oneCurrencyIsWETH ? (
                                    <StyledInternalLink
                                      to={`/remove/${
                                        currencyA && currencyEquals(currencyA, WETH[chainId]) ? 'ETH' : currencyIdA
                                      }/${currencyB && currencyEquals(currencyB, WETH[chainId]) ? 'ETH' : currencyIdB}`}
                                    >
                                      Receive HOO
                                    </StyledInternalLink>
                                  ) : null}
                                </RowBetween>
                              ) : null}
                            </AutoColumn>
                          </LightCard>
                        </>
                      )}

                      {showDetailed && (
                        <>
                          <CurrencyInputPanel
                            value={formattedAmounts[Field.LIQUIDITY]}
                            onUserInput={onLiquidityInput}
                            onMax={() => {
                              onUserInput(Field.LIQUIDITY_PERCENT, '100')
                            }}
                            showMaxButton={!atMaxAmount}
                            disableCurrencySelect
                            currency={pair?.liquidityToken}
                            pair={pair}
                            id="liquidity-amount"
                          />
                          <ColumnCenter>
                            <ArrowDown size="16" color={theme.colors.text2} />
                          </ColumnCenter>
                          <CurrencyInputPanel
                            hideBalance={true}
                            value={formattedAmounts[Field.CURRENCY_A]}
                            onUserInput={onCurrencyAInput}
                            onMax={() => onUserInput(Field.LIQUIDITY_PERCENT, '100')}
                            showMaxButton={!atMaxAmount}
                            currency={currencyA}
                            label={'Output'}
                            onCurrencySelect={handleSelectCurrencyA}
                            id="remove-liquidity-tokena"
                          />
                          <ColumnCenter>
                            <Plus size="16" color="#fff" />
                          </ColumnCenter>
                          <CurrencyInputPanel
                            hideBalance={true}
                            value={formattedAmounts[Field.CURRENCY_B]}
                            onUserInput={onCurrencyBInput}
                            onMax={() => onUserInput(Field.LIQUIDITY_PERCENT, '100')}
                            showMaxButton={!atMaxAmount}
                            currency={currencyB}
                            label={'Output'}
                            onCurrencySelect={handleSelectCurrencyB}
                            id="remove-liquidity-tokenb"
                          />
                        </>
                      )}
                      {pair && (
                        <div className="text-dark" style={{ padding: '10px 20px' }}>
                          <RowBetween>
                            Price:
                            <div>
                              1 {currencyA?.symbol} = {tokenA ? pair.priceOf(tokenA).toSignificant(6) : '-'}{' '}
                              {currencyB?.symbol}
                            </div>
                          </RowBetween>
                          <RowBetween>
                            <div />
                            <div>
                              1 {currencyB?.symbol} = {tokenB ? pair.priceOf(tokenB).toSignificant(6) : '-'}{' '}
                              {currencyA?.symbol}
                            </div>
                          </RowBetween>
                        </div>
                      )}
                      <div style={{ position: 'relative' }}>
                        {!account ? (
                          <ButtonLight onClick={toggleWalletModal}>{i18n(204, 'Connect Wallet')}</ButtonLight>
                        ) : (
                          <RowBetween>
                            <ButtonConfirmed
                              // onClick={onAttemptToApprove}
                              onClick={() => approveLPToken()}
                              confirmed={approval === ApprovalState.APPROVED || signatureData !== null}
                              // disabled={approval !== ApprovalState.NOT_APPROVED || signatureData !== null}
                              disabled={tokenApproving}
                              mr="0.5rem"
                              fontWeight={500}
                              fontSize={16}
                              style={{ padding: '12px' }}
                            >
                              {tokenApproving ? <Dots>Approving</Dots>:"Approve"}
                              {/* {approval === ApprovalState.PENDING ? (
                                <Dots>Approving</Dots>
                              ) : approval === ApprovalState.APPROVED || signatureData !== null ? (
                                'Approved'
                              ) : (
                                'Approve'
                              )} */}
                            </ButtonConfirmed>
                            <ButtonError
                              style={{ padding: '12px' }}
                              onClick={() => {
                                setShowConfirm(true)
                              }}
                              disabled={!tokenApproval}
                              // disabled={!isValid || (signatureData === null && approval !== ApprovalState.APPROVED)}
                              error={!isValid && !!parsedAmounts[Field.CURRENCY_A] && !!parsedAmounts[Field.CURRENCY_B]}
                            >
                              <Text fontSize={16} fontWeight={500}>
                                {error || 'Remove'}
                              </Text>
                            </ButtonError>
                          </RowBetween>
                        )}
                      </div>
                    </AutoColumn>
                  </Wrapper>
                  {pair ? <MinimalPositionCard showUnwrapped={oneCurrencyIsWETH} pair={pair} /> : null}
                </AppBody>
              </BodyWrapper>
            </div>
          </div>
        </div>
      </AppWrapper>
    </>
  )
}
