export function cutAfterDecimal(number, pos, dl, ac) {
  if (Number(number)) {
    if (dl) {
      const limit = dl?.decimalLimit[ac] > 0 ? dl?.decimalLimit[ac] : 5
      const res =
        number?.toString()?.indexOf('.') > -1
          ? number.toString().slice(0, number.toString().indexOf('.') + limit + 1)
          : number
      return res
    } else {
      const res =
        number?.toString()?.indexOf('.') > -1
          ? number.toString().slice(0, number.toString().indexOf('.') + pos + 1)
          : number
      return res
    }
  } else {
    return 0
  }
}

export function toPlainString(num) {
  return ('' + +num).replace(/(-?)(\d*)\.?(\d*)e([+-]\d+)/, function(a, b, c, d, e) {
    return e < 0 ? b + '0.' + Array(1 - e - c.length).join(0) + c + d : b + c + d + Array(e - d.length + 1).join(0)
  })
}

export function formatNumber(value) {
  const absValue = Math.abs(Number(value))
  const trillion = 1e12
  const billion = 1e9
  const million = 1e6
  const thousand = 1e3

  let formattedValue

  if (absValue >= trillion) {
    formattedValue = (absValue / trillion).toFixed(2) + 'T'
  } else if (absValue >= billion) {
    formattedValue = (absValue / billion).toFixed(2) + 'B'
  } else if (absValue >= million) {
    formattedValue = (absValue / million).toFixed(2) + 'M'
  } else if (absValue >= thousand) {
    formattedValue = (absValue / thousand).toFixed(2) + 'K'
  } else {
    formattedValue = absValue.toString()
  }

  return value < 0 ? '-' + formattedValue : formattedValue
}

export const processBalance = data => {
  const { numerator, denominator, currency } = data

  // Sum up the numerator and denominator arrays
  const numeratorSum = numerator.reduce((acc, value) => acc + value, 0)
  const denominatorSum = denominator.reduce((acc, value) => acc + value, 0)

  // Calculate the balance
  const balance = numeratorSum / denominatorSum

  // Adjust the balance based on the currency decimals
  const formattedBalance = balance / Math.pow(10, currency.decimals)

  return formattedBalance
}
