import React, { useEffect } from 'react'
import { useState } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { BiCopy, BiLink } from 'react-icons/bi'
import { BsFillPatchCheckFill } from 'react-icons/bs'
import { createTokenDetails } from '../helper/api'
import TableLoader from './TableLoader'
import { Link } from 'react-router-dom'
import { TiTick } from 'react-icons/ti'
import { RxCross2 } from 'react-icons/rx'
import { ExternalLink as LinkIcon } from 'react-feather'

import { Pagination } from '@material-ui/lab'

import { BsSearch } from 'react-icons/bs'
import { getTokenDetails } from '../helper/Token'
import { useActiveWeb3React } from 'hooks'
import { ArrowUpCircle } from 'react-feather'
import { formatNumber } from 'pages/helper/helperFunctions'

export default function CreateTokenLIst({ refCreateToken }) {
  //   const { wallet } = useSelector(state => state.counter)
  const { account, chainId, library } = useActiveWeb3React()

  const [tokenDetails, setTokenDetails] = useState(undefined)
  const [tooltipState, setTooltipState] = useState('Copy address')
  const [ctAddress, setCtAddress] = useState('')
  const [TDetail, setTDetail] = useState('')
  const [reload, setReload] = useState(false)
  const [page, setPage] = useState(1)
  const [currentData, setCurrentData] = useState(undefined)
  const [showSearch, setShowSearch] = useState('')
  const [paginationStatus, setPaginationStatus] = useState(true)

  const renderTooltip = props => (
    <Tooltip id="button-tooltip" {...props}>
      {tooltipState == 'Copied' ? <BsFillPatchCheckFill color="lightgreen" /> : ''} {tooltipState}
    </Tooltip>
  )

  async function Create_Token_Add(user_token_add, token_symbol, decimals) {
    try {
      if (account) {
        const Added = await window.ethereum.request({
          method: 'wallet_watchAsset',
          params: {
            type: 'ERC20',
            options: {
              address: user_token_add,
              symbol: token_symbol,
              decimals: decimals
              // image: tokenImage,
            }
          }
        })
      }
    } catch (error) {
      console.log(error, 'token importing error')
    }
  }

  const callContract = () => {
    getTokenDetails(ctAddress, account)
      .then(d => {
        console.log(d, 'tokendetails')
        setTDetail(d)
        // console.log(d, "data for d");
        document.getElementById('tokenDetailBox').style.display = 'block'
      })
      .catch(e => {
        console.log(e, 'sdasdasddasd')
      })
  }

  function handleChange(e, p) {
    setPage(p)
    let limit = 6
    const startIndex = (p - 1 + 1) * limit - limit
    const endIndex = startIndex + limit
    setCurrentData(tokenDetails.slice(startIndex, endIndex))
  }
  function filterToken(event) {
    if (event.length == 0) {
      setCurrentData(tokenDetails.slice(0, 6))
      setPage(1)
      setPaginationStatus(true)
    } else {
      setPaginationStatus(false)
      const token = tokenDetails?.filter(ele => {
        // console.log(ele,"ele");
        const tokSymbol1 = ele?.token_symbol?.toUpperCase()
        // const tokSymbol2 = ele?.item?.token1_symbol?.toUpperCase();
        const token0 = ele?.user_token_Address.toUpperCase()
        // const token1 = ele.pair?.item.toUpperCase();
        if (token0?.indexOf(event.toUpperCase()) > -1 || tokSymbol1?.indexOf(event.toUpperCase()) > -1) {
          return ele
        }
      })

      setCurrentData(token)
    }
  }

  useEffect(() => {
    if (account) {
      createTokenDetails(account, 1).then(d => {
        // console.log(d,"DDDD")
        const data = d?.data?.data
          .map(item => {
            return item
          })
          .reverse()
        setTokenDetails(data)
        setCurrentData(data?.slice(0, 6))
        setPage(1)
      })
    }
  }, [account, refCreateToken, reload])

  return (
    <>
      <div className="col-sm">
        <div>
          <div className="d-flex justify-content-between my-3 align-items-center">
            <div className="clearfix">
              <h5 style={{ color: '#fff' }}>Your Created Tokens List</h5>
            </div>
            <div
              className="d-flex align-items-center px-1"
              style={{ background: 'rgb(247 247 247)', borderRadius: '5px' }}
            >
              <div className="px-2">
                <BsSearch />
              </div>

              <input
                id="standard-adornment-amount"
                type="text"
                placeholder="Search Token "
                onChange={e => {
                  filterToken(e.target.value)
                  setShowSearch(e.target.value)
                }}
                value={showSearch}
                style={{ border: 'none', width: '120px', position: 'relative', zIndex: '11' }}
                className="form-control"
              />
            </div>
          </div>
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control "
              placeholder="Enter Token Address"
              aria-label="Enter Contract Address"
              aria-describedby="button-addon2"
              value={ctAddress}
              onChange={e => {
                if (e.target.value == '') {
                  document.getElementById('tokenDetailBox').style.display = 'none'
                }
                setCtAddress(e.target.value)
              }}
              // value={tokenInput}
            />

            <button
              className="btn bg-dark border-light text-light"
              type="button"
              onClick={() => {
                callContract()
              }}
              disabled={!account}
            >
              Fetch
            </button>
          </div>

          <div id="tokenDetailBox" className="setHider">
            <div className="row">
              <div className="col-6">
                <div className="mb-3">
                  <label className="form-label">Name</label>
                  <div className="input-group">
                    <input
                      readOnly
                      type="text"
                      className="form-control"
                      placeholder="Bitcoin"
                      value={TDetail?.name}
                      aria-label="Name"
                    />
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="mb-3">
                  <label className="form-label">Symbol</label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="BTC"
                      value={TDetail?.symbol}
                      aria-label="Symbol"
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="mb-3">
                  <label className="form-label">Total Supply</label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="100000000000000000"
                      value={TDetail?.totalsupply}
                      aria-label="Supply"
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="mb-3">
                  <label className="form-label">Precision</label>

                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="18"
                      aria-label="Precision"
                      value={TDetail?.decimals}
                      // value=""
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="faqsection">
            <div className="accordion" id="accordionExample">
              {currentData == undefined ? (
                <TableLoader />
              ) : // <></>
              currentData?.length > 0 ? (
                currentData &&
                currentData?.map((item, i) => {
                  return (
                    <div className="accordion-item" key={i + 'man'}>
                      <h2 className="accordion-header" id={`heading${i}`}>
                        <Link
                          className="accordion-button collapsed mob_p_3"
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapse${i}`}
                          aria-expanded="true"
                          aria-controls={`collapse${i}`}
                        >
                          <div className="card-body py-0 mob-padding">
                            <div className="d-flex justify-content-center">
                              <div className="col">
                                <div className="d-flex">
                                  <div className="mob-font mob-font123">
                                    {' '}
                                    <i className="fas fa-seedling me-1"></i> Project -{' '}
                                  </div>
                                  <div className="mob-font ms-2 text-white">
                                    <span>{item.token_name}</span>{' '}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </h2>

                      <div
                        id={`collapse${i}`}
                        className="accordion-collapse collapse"
                        aria-labelledby={`heading${i}`}
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body py-0">
                          <div className="row">
                            <div className="col-lg-6 col-md-12 col-sm-12 col-12 mob_marg-0">
                              <div className="p-3 pad-bot-0">
                                <div className="d-flex justify-content-between">
                                  <div className="header-label">Name</div>
                                  <div className="mob-font">{item.token_name}</div>
                                </div>
                                <div className="d-flex justify-content-between">
                                  <div className="header-label">Supply</div>
                                  <div className="mob-font">
                                    {item?.total_supply && formatNumber(Number(item?.total_supply))}
                                  </div>
                                </div>

                                <div className="d-flex justify-content-between">
                                  <div className="header-label">Type</div>
                                  <div className="d-flex mob-font">
                                    <div>{item.token_type}</div>
                                    <div className="inr_img position-relative top4"></div>
                                  </div>
                                </div>
                                <div className="d-flex justify-content-between">
                                  <div className="header-label">Created at</div>
                                  <div className="d-flex mob-font">
                                    <div>{new Date(item.timestamp).toLocaleDateString().replaceAll('/', '-')}</div>
                                    <div className="inr_img position-relative top4"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12 col-12 mob_marg">
                              <div className="py-3">
                                <div className="borderLeft">
                                  <div className="px-3 mob_p_2">
                                    <div className="d-flex justify-content-between">
                                      <div className="header-label">Symbol</div>
                                      <div className="mob-font">{item.token_symbol}</div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <div className="header-label">Precision</div>
                                      <div className="mob-font">{item.decimals}</div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <div className="header-label">Contract</div>
                                      <div className="pointer mob-font">
                                        {' '}
                                        {item.user_token_Address?.slice(0, 7)}
                                        ...
                                        {item.user_token_Address?.slice(-7)}{' '}
                                        <OverlayTrigger
                                          placement="top"
                                          delay={{ show: 100, hide: 300 }}
                                          overlay={renderTooltip}
                                        >
                                          <span className="ms-1 text-info">
                                            <BiCopy
                                              onClick={() => {
                                                navigator.clipboard.writeText(item.user_token_Address)
                                                setTooltipState('Copied')
                                                setTimeout(function() {
                                                  setTooltipState('Copy address')
                                                }, 1000)
                                              }}
                                            />
                                          </span>
                                        </OverlayTrigger>
                                        <a
                                          style={{ cursor: 'pointer', marginLeft: '2px' }}
                                          target="_blank"
                                          href={`https://devnet.taralscan.com/address/${item.user_token_Address}`}
                                        >
                                          {/* <i className="fas fa-external-link-alt fs-10 ms-1 text-cyan"></i> */}
                                          <LinkIcon style={{ color: '#fff' }} size={12} />
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {item.token_type == 'standard' && (
                              <div className="col-12 d-flex justify-content-center flex-column align-items-center">
                                <div className="row w-100 gy-2 ">
                                  <div className=" col-lg-6 col-md-12 col-sm-12 col-12 mob_margarg">
                                    <div className="d-flex justify-content-between">
                                      <div className="header-label">Mint</div>
                                      <div className="mob-font">
                                        {item.can_mint == 1 ? (
                                          <TiTick
                                            style={{
                                              color: 'green',
                                              fontSize: '20px'
                                            }}
                                          />
                                        ) : (
                                          <RxCross2
                                            style={{
                                              color: 'red',
                                              fontSize: '20px'
                                            }}
                                          />
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className=" col-lg-6 col-md-12 col-sm-12 col-12 mob_margarg ">
                                    <div className=" borderLeft pt-3 pt-md-0">
                                      <div className=" mt-3 mt-md-0 px-lg-3 px-0 d-flex justify-content-between">
                                        <div className="header-label">Burn</div>
                                        <div className="d-flex mob-font">
                                          <div>
                                            {item.can_burn == 1 ? (
                                              <TiTick
                                                style={{
                                                  color: 'green',
                                                  fontSize: '20px'
                                                }}
                                              />
                                            ) : (
                                              <RxCross2
                                                style={{
                                                  color: 'red',
                                                  fontSize: '20px'
                                                }}
                                              />
                                            )}
                                          </div>
                                          <div className="inr_img position-relative top4"></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            <div className="col-12 d-flex tokenBtn-flex justify-content-center flex-row gap-2 align-items-center my-3">
                              <div className="mb-2">
                                <a
                                  className="mob-font mob-font123 btn btn-success btn-sm"
                                  onClick={() =>
                                    Create_Token_Add(item.user_token_Address, item.token_symbol, item.decimals)
                                  }
                                >
                                  <img width={20} className="me-1" src="/images/metamask-fox.svg"></img>
                                  ADD <span>{item.token_symbol} </span>
                                  To Wallet
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
              ) : (
                // .reverse()
                <div className="alert alert-warning border-start-0 my-2 text-center">
                  <img className="grayimg" src="/images/norecord.png" width={75} />
                  <p className="fs-16 ms-2 mb-0">No Records Found</p>
                </div>
              )}
            </div>
            {tokenDetails?.length > 0 && (
              <>
                {paginationStatus ? (
                  <div className="d-flex justify-content-center py-2 mb-4">
                    <Pagination
                      count={tokenDetails?.length ? Math.ceil(tokenDetails?.length / 6) : 1}
                      onChange={handleChange}
                      page={Number(page)}
                      variant="outlined"
                      color="primary"
                    />
                  </div>
                ) : null}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
