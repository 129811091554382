import axios from 'axios'
import { BASE_URL } from './config'

export async function getReferrerUser({ walletAddress }, page) {
  const data = await axios.post(
    `${BASE_URL}/getReferrerUser`,
    {
      userAddress: walletAddress,
      page: page
    },
    {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      }
    }
  )
  return data
}

export async function createToken(
  fromData,
  selectValue,
  recriptData,
  fees,
  tokenBalADD,
  setRefCreateToken,
  walletAddress
) {
  console.log(
    fromData,
    selectValue,
    recriptData,
    fees,
    tokenBalADD,
    setRefCreateToken,
    walletAddress,
    'CAATE>>>>>token'
  )
  const standard = {
    token_type: 'standard',
    user_add: walletAddress,
    user_token_add: recriptData.contractAddress,
    admin_user_add: tokenBalADD,
    fees_amt: fees,
    token_name: fromData.tokenname,
    token_symbol: fromData?.tokensymbol,
    total_supply: fromData.tokensupply,
    decimals: fromData.decimal,
    can_mint: fromData?.canmints,
    can_burn: fromData?.canburns
  }

  axios
    .post(
      `${BASE_URL}/create_token`,

      selectValue === 'standard' ? standard : '',

      {
        headers: {
          Accept: 'application/json'
        }
      }
    )
    .then(d => {
      setRefCreateToken(true)
    })
    .catch(err => {
      setRefCreateToken(true)
    })
}

export async function createTokenDetails(walletAddress, page) {
  const TokenDatails = await axios.post(
    `${BASE_URL}/created_token_details`,
    {
      user_add: walletAddress,
      page: page
    },
    {
      headers: {
        Accept: 'application/json'
      }
    }
  )
  return TokenDatails
}
