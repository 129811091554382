import { Grid, Typography, Button, Box, Container, makeStyles } from '@material-ui/core'
import React from 'react'
import { Link, useHistory } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  mainBox: {
    zIndex: '1',
    padding: '20px 0px 20px',
    paddingTop: '180px',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    alignItems: 'center',
    overflow: 'hidden',

    [theme.breakpoints.down('sm')]: {
      padding: '100px 0px 20px'
    },
    '& h4': {
      color: '#fff',
      textAlign: 'center'
    },
    '& h1': {
      color: '#fff',
      textAlign: 'start',
      fontSize: '74px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '34px'
      }
    },
    '& h6': {
      color: '#fff',
      fontSize: '14px',
      fontWeight: '500'
    },
    '& p': {
      color: '#fff',
      fontSize: '12px',
      fontWeight: '300',
      color: theme.palette.text.dull
    },
    '& h2': {
      color: '#fff',
      fontSize: '22px',
      fontWeight: '500',
      [theme.breakpoints.down('sm')]: {
        fontSize: '16px'
      }
    },
    '& h5': {
      color: '#fff',
      fontSize: '16px',
      fontWeight: '300',
      color: theme.palette.text.dull
    }
  },
  gridMainContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'row',
    height: '100%',
    position: 'relative'
  },
  gridContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',
    width: '50%',
    height: '500px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 'auto'
    },
    position: 'relative'
  },
  gridImage: {
    position: 'absolute',
    width: '1000px',
    left: '-200px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      left: '0px',
      position: 'relative'
    }
  },

  btnContainer: {
    display: 'flex',
    gap: '20px'
  },
  coloredText: {
    color: '#4AFCFE'
  },
  // heroSideImage: {
  //   position: "absolute",
  //   left: "-385px",
  //   [theme.breakpoints.down("sm")]: {
  //     display: "none",
  //   },
  // },
  heroEllipseImage1: {
    position: 'absolute',
    top: '-718px',
    width: '900px',
    height: '900px',
    right: '-643px',
    objectFit: 'cover',
    rotate: '34deg',
    border: '1px solid #fff',
    borderRadius: '100%',
    opacity: '25%',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  heroEllipseImage2: {
    position: 'absolute',
    top: '-750px',
    width: '1050px',
    height: '1050px',
    right: '-684px',
    objectFit: 'cover',
    rotate: '34deg',
    border: '1px solid #fff',
    borderRadius: '100%',
    opacity: '25%',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  heroEllipseImage3: {
    position: 'absolute',
    top: '-827px',
    width: '1250px',
    height: '1250px',
    right: '-767px',
    objectFit: 'cover',
    rotate: '34deg',
    border: '1px solid #fff',
    borderRadius: '100%',
    opacity: '25%',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  roadmapcenter: {
    position: 'absolute',
    top: '12%',
    left: '-41%',
    right: '0%',
    zIndex: '2',
    borderRadius: '1000px',
    opacity: '1.55',
    width: '314px',
    height: '314px',
    background: '#FED056',
    opacity: '40%',
    filter: 'blur(94px)',
    transform: 'rotate(-90deg)',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  }
}))

const HeroSection = () => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <div className={classes.mainBox}>
      <Container>
        <Grid container className={classes.gridMainContainer}>
          <div className={classes.roadmapcenter}></div>
          <Box className={classes.gridContainer}>
            <Typography variant="h1" className={classes.mainTextBox}>
              <span
                style={{
                  WebkitTextStroke: '0.1px #FFB900',
                  color: '#FFB900',
                  fontWeight: '300'
                }}
                className="londrina"
              >
                {' '}
                Everyone’s
              </span>{' '}
              Top <span className={classes.coloredText}> Pick</span> for{' '}
              <span className={classes.coloredText}>Web3</span> App
            </Typography>
            <Typography
              variant="subtitle2"
              style={{
                marginTop: '20px',
                marginBottom: '20px'
              }}
            >
              Your one-stop DApp for Swapping, Providing Liquidity, Trading, Earning, and more…
            </Typography>
            <Box className={classes.btnContainer}>
              <Button
                variant="text"
                style={{
                  background: '#4AFCFE',
                  textTransform: 'none',
                  padding: '4px 24px',
                  boxShadow: '0 2px 2px 0 #4AFCFE, 0 2px 2px 0 #4AFCFE',
                  color: '#000'
                }}
                onClick={() => {
                  history.push('/mint')
                }}
              >
                Free Mint
              </Button>
              {/* <Button
                variant="text"
                style={{
                  background: 'none !important',
                  borderImage: 'linear-gradient(to right, #0F212F 0%, #199099 100%) 1',
                  borderWidth: '1px',
                  borderStyle: 'solid',
                  textTransform: 'none',
                  padding: '4px 24px',
                  color: '#fff'
                }}
              >
                How it works
              </Button> */}
            </Box>
          </Box>
          <Box className={classes.gridContainer}>
            <Box className={classes.heroEllipseImage1}></Box>
            <Box className={classes.heroEllipseImage2}></Box>
            <Box className={classes.heroEllipseImage3}></Box>

            <Box className={classes.gridImage} component={'img'} src="new-module/heroPhone.svg" />
          </Box>
        </Grid>
      </Container>
    </div>
  )
}

export default HeroSection
