// const BASE_URL = "https://staking-api.dotblox.io/api"
//  const BASE_URL = "http://localhost:8000/api"
const BASE_URL = "https://api-dapp.tarality.io/api";

const Governace_Address = "0xBd1eC4A664E73B3F5A2c40438093f2Df7a30ccF4";

const Governace_ABI = [
  {
    type: "event",
    name: "ClaimDividend",
    inputs: [
      { type: "address", name: "user", internalType: "address", indexed: true },
      {
        type: "address",
        name: "token",
        internalType: "address",
        indexed: false,
      },
      {
        type: "uint256",
        name: "amount",
        internalType: "uint256",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "ClaimGrowthDividend",
    inputs: [
      { type: "address", name: "user", internalType: "address", indexed: true },
      {
        type: "address",
        name: "token",
        internalType: "address",
        indexed: false,
      },
      {
        type: "uint256",
        name: "amount",
        internalType: "uint256",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "GrowthStake",
    inputs: [
      {
        type: "address",
        name: "token",
        internalType: "address",
        indexed: true,
      },
      { type: "address", name: "user", internalType: "address", indexed: true },
      {
        type: "address",
        name: "sender",
        internalType: "address",
        indexed: true,
      },
      {
        type: "uint256",
        name: "level",
        internalType: "uint256",
        indexed: false,
      },
      {
        type: "uint256",
        name: "amount",
        internalType: "uint256",
        indexed: false,
      },
      {
        type: "uint256",
        name: "startTime",
        internalType: "uint256",
        indexed: false,
      },
      {
        type: "uint256",
        name: "endTime",
        internalType: "uint256",
        indexed: false,
      },
      {
        type: "uint256",
        name: "share",
        internalType: "uint256",
        indexed: false,
      },
      {
        type: "uint256",
        name: "PercentDivider",
        internalType: "uint256",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "Registration",
    inputs: [
      { type: "address", name: "user", internalType: "address", indexed: true },
      {
        type: "address",
        name: "referrer",
        internalType: "address",
        indexed: true,
      },
      {
        type: "uint256",
        name: "userId",
        internalType: "uint256",
        indexed: false,
      },
      {
        type: "uint256",
        name: "referrerId",
        internalType: "uint256",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "Stake",
    inputs: [
      { type: "address", name: "user", internalType: "address", indexed: true },
      {
        type: "address",
        name: "token",
        internalType: "address",
        indexed: true,
      },
      {
        type: "uint256",
        name: "amount",
        internalType: "uint256",
        indexed: false,
      },
      {
        type: "uint256",
        name: "startTime",
        internalType: "uint256",
        indexed: false,
      },
      {
        type: "uint256",
        name: "endTime",
        internalType: "uint256",
        indexed: false,
      },
      {
        type: "uint256",
        name: "exitLoad",
        internalType: "uint256",
        indexed: false,
      },
      {
        type: "uint256",
        name: "share",
        internalType: "uint256",
        indexed: false,
      },
      {
        type: "uint256",
        name: "percentDivider",
        internalType: "uint256",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "TokenAdded",
    inputs: [
      {
        type: "address",
        name: "token",
        internalType: "address",
        indexed: false,
      },
      {
        type: "uint256",
        name: "minDeposit",
        internalType: "uint256",
        indexed: false,
      },
      {
        type: "uint256",
        name: "maxDeposit",
        internalType: "uint256",
        indexed: false,
      },
      {
        type: "uint256",
        name: "share",
        internalType: "uint256",
        indexed: false,
      },
      {
        type: "uint256",
        name: "exitLoad",
        internalType: "uint256",
        indexed: false,
      },
      {
        type: "uint256",
        name: "percentDivider",
        internalType: "uint256",
        indexed: false,
      },
      {
        type: "uint256",
        name: "harvestingPeriod",
        internalType: "uint256",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "WithdrawStaking",
    inputs: [
      {
        type: "address",
        name: "token",
        internalType: "address",
        indexed: true,
      },
      { type: "address", name: "user", internalType: "address", indexed: true },
      {
        type: "uint256",
        name: "amount",
        internalType: "uint256",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "onOwnershipTransferred",
    inputs: [
      {
        type: "address",
        name: "previousOwner",
        internalType: "address",
        indexed: true,
      },
      {
        type: "address",
        name: "newOwner",
        internalType: "address",
        indexed: true,
      },
    ],
    anonymous: false,
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    name: "PERCENT_DIVIDER",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    name: "TIME_STEP",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "address", name: "", internalType: "address" }],
    name: "WDTBX",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "addLevel",
    inputs: [
      { type: "uint256", name: "level", internalType: "uint256" },
      { type: "uint256", name: "share", internalType: "uint256" },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "addToken",
    inputs: [
      { type: "address", name: "_token", internalType: "address" },
      { type: "uint256", name: "_minDeposit", internalType: "uint256" },
      { type: "uint256", name: "_maxDeposit", internalType: "uint256" },
      { type: "uint256", name: "_share", internalType: "uint256" },
      { type: "uint256", name: "_exitLoad", internalType: "uint256" },
      { type: "uint256", name: "_percentDivider", internalType: "uint256" },
      { type: "uint256", name: "_harvestingPeriod", internalType: "uint256" },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "changeLastLevel",
    inputs: [{ type: "uint256", name: "_newLevel", internalType: "uint256" }],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "changeStakingStatusToken",
    inputs: [
      { type: "address", name: "_token", internalType: "address" },
      { type: "bool", name: "_enable", internalType: "bool" },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "changeTokenExitLoad",
    inputs: [
      { type: "address", name: "_token", internalType: "address" },
      { type: "uint256", name: "_exitLoad", internalType: "uint256" },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "changeTokenHarvestingPeriod",
    inputs: [
      { type: "address", name: "_token", internalType: "address" },
      { type: "uint256", name: "_harvestingPeriod", internalType: "uint256" },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "changeTokenMaximumStaking",
    inputs: [
      { type: "address", name: "_token", internalType: "address" },
      { type: "uint256", name: "maximumDeposit", internalType: "uint256" },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "changeTokenMinimunStaking",
    inputs: [
      { type: "address", name: "_token", internalType: "address" },
      { type: "uint256", name: "minimumDeposit", internalType: "uint256" },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "changeTokenPercentDivider",
    inputs: [
      { type: "address", name: "_token", internalType: "address" },
      { type: "uint256", name: "_percentDivider", internalType: "uint256" },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "changeTokenShare",
    inputs: [
      { type: "address", name: "_token", internalType: "address" },
      { type: "uint256", name: "_sharePerMonth", internalType: "uint256" },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "claimDividends",
    inputs: [{ type: "address", name: "_token", internalType: "address" }],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "claimGrowthDividends",
    inputs: [{ type: "address", name: "_token", internalType: "address" }],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    name: "getAvlForWithdraw",
    inputs: [
      { type: "address", name: "_user", internalType: "address" },
      { type: "address", name: "_token", internalType: "address" },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "tuple",
        name: "",
        internalType: "struct DTBXStaking.Deposit",
        components: [
          { type: "uint256", name: "amount", internalType: "uint256" },
          { type: "uint256", name: "start", internalType: "uint256" },
          { type: "uint256", name: "end", internalType: "uint256" },
          { type: "uint256", name: "exitLoad", internalType: "uint256" },
          { type: "uint256", name: "share", internalType: "uint256" },
          { type: "uint256", name: "percentDivider", internalType: "uint256" },
          { type: "bool", name: "status", internalType: "bool" },
        ],
      },
    ],
    name: "getDepositByToken",
    inputs: [
      { type: "address", name: "_token", internalType: "address" },
      { type: "address", name: "_user", internalType: "address" },
      { type: "uint256", name: "index", internalType: "uint256" },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "tuple[]",
        name: "",
        internalType: "struct DTBXStaking.Deposit[]",
        components: [
          { type: "uint256", name: "amount", internalType: "uint256" },
          { type: "uint256", name: "start", internalType: "uint256" },
          { type: "uint256", name: "end", internalType: "uint256" },
          { type: "uint256", name: "exitLoad", internalType: "uint256" },
          { type: "uint256", name: "share", internalType: "uint256" },
          { type: "uint256", name: "percentDivider", internalType: "uint256" },
          { type: "bool", name: "status", internalType: "bool" },
        ],
      },
    ],
    name: "getDepositsByToken",
    inputs: [
      { type: "address", name: "_token", internalType: "address" },
      { type: "address", name: "_user", internalType: "address" },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    name: "getDepositsLengthByToken",
    inputs: [
      { type: "address", name: "_token", internalType: "address" },
      { type: "address", name: "_user", internalType: "address" },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    name: "getGrowthUserDividends",
    inputs: [
      { type: "address", name: "_user", internalType: "address" },
      { type: "address", name: "_token", internalType: "address" },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    name: "getTotalStaked",
    inputs: [
      { type: "address", name: "_token", internalType: "address" },
      { type: "address", name: "_user", internalType: "address" },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      { type: "uint256", name: "checkpoint", internalType: "uint256" },
      { type: "uint256", name: "growthCheckpoint", internalType: "uint256" },
    ],
    name: "getUserCheckpointbyToken",
    inputs: [
      { type: "address", name: "_token", internalType: "address" },
      { type: "address", name: "_user", internalType: "address" },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    name: "getUserDividends",
    inputs: [
      { type: "address", name: "_user", internalType: "address" },
      { type: "address", name: "_token", internalType: "address" },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "address", name: "", internalType: "address" }],
    name: "idToAddress",
    inputs: [{ type: "uint256", name: "", internalType: "uint256" }],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "initialize",
    inputs: [
      { type: "address", name: "_owner", internalType: "address" },
      { type: "address", name: "_wdtbx", internalType: "address" },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "bool", name: "", internalType: "bool" }],
    name: "isTokenExist",
    inputs: [{ type: "address", name: "", internalType: "address" }],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "bool", name: "", internalType: "bool" }],
    name: "isUserExists",
    inputs: [{ type: "address", name: "user", internalType: "address" }],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    name: "lastLevel",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    name: "lastUserId",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    name: "levelShare",
    inputs: [{ type: "uint256", name: "", internalType: "uint256" }],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "address", name: "", internalType: "address" }],
    name: "owner",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "payable",
    outputs: [],
    name: "stake",
    inputs: [
      { type: "address", name: "_token", internalType: "address" },
      { type: "uint256", name: "_amount", internalType: "uint256" },
    ],
  },
  {
    type: "function",
    stateMutability: "payable",
    outputs: [],
    name: "stakeWithReferral",
    inputs: [
      { type: "address", name: "_token", internalType: "address" },
      { type: "uint256", name: "_amount", internalType: "uint256" },
      { type: "address", name: "_refferal", internalType: "address" },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      { type: "uint256", name: "harvestingPeriod", internalType: "uint256" },
      { type: "uint256", name: "minimumDeposit", internalType: "uint256" },
      { type: "uint256", name: "maximumDeposit", internalType: "uint256" },
      { type: "uint256", name: "sharePerMonth", internalType: "uint256" },
      { type: "uint256", name: "exitLoad", internalType: "uint256" },
      { type: "uint256", name: "percentDivider", internalType: "uint256" },
      { type: "bool", name: "stakingEnable", internalType: "bool" },
    ],
    name: "tokenConfig",
    inputs: [{ type: "address", name: "", internalType: "address" }],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    name: "tokenLength",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "address", name: "", internalType: "address" }],
    name: "tokens",
    inputs: [{ type: "uint256", name: "", internalType: "uint256" }],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "transferOwnership",
    inputs: [{ type: "address", name: "_newOwner", internalType: "address" }],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "updateTimeStep",
    inputs: [{ type: "uint256", name: "_timeStep", internalType: "uint256" }],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      { type: "uint256", name: "userId", internalType: "uint256" },
      { type: "address", name: "referrer", internalType: "address" },
    ],
    name: "users",
    inputs: [{ type: "address", name: "", internalType: "address" }],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "withdrawStaking",
    inputs: [{ type: "address", name: "_token", internalType: "address" }],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "withdrawToken",
    inputs: [
      { type: "address", name: "_token", internalType: "address" },
      { type: "address", name: "_to", internalType: "address" },
      { type: "uint256", name: "_amount", internalType: "uint256" },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "withdrawWithExitLoad",
    inputs: [
      { type: "address", name: "_token", internalType: "address" },
      { type: "uint256", name: "index", internalType: "uint256" },
    ],
  },
  { type: "receive", stateMutability: "payable" },
];

const user_Token_Drop_ABI = [
  { inputs: [], stateMutability: "nonpayable", type: "constructor" },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "spender",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "Approval",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "address", name: "from", type: "address" },
      { indexed: true, internalType: "address", name: "to", type: "address" },
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "Transfer",
    type: "event",
  },
  {
    inputs: [
      { internalType: "address", name: "owner", type: "address" },
      { internalType: "address", name: "spender", type: "address" },
    ],
    name: "allowance",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "spender", type: "address" },
      { internalType: "uint256", name: "amount", type: "uint256" },
    ],
    name: "approve",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "account", type: "address" }],
    name: "balanceOf",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "decimals",
    outputs: [{ internalType: "uint8", name: "", type: "uint8" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "spender", type: "address" },
      { internalType: "uint256", name: "subtractedValue", type: "uint256" },
    ],
    name: "decreaseAllowance",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "spender", type: "address" },
      { internalType: "uint256", name: "addedValue", type: "uint256" },
    ],
    name: "increaseAllowance",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "name",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "symbol",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalSupply",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "recipient", type: "address" },
      { internalType: "uint256", name: "amount", type: "uint256" },
    ],
    name: "transfer",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "sender", type: "address" },
      { internalType: "address", name: "recipient", type: "address" },
      { internalType: "uint256", name: "amount", type: "uint256" },
    ],
    name: "transferFrom",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
];
export { BASE_URL, Governace_Address, Governace_ABI, user_Token_Drop_ABI };
