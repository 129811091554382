import React, { useEffect } from 'react'

export default function TableLoader() {
  return (
    <>
      <div className="container-fluid">
        <div className="row mt-4">
          <div className="accordion-item">
            <h2 className="accordion-header" id="heading0">
              <div className="card-body mob-padding">
                <div className="row">
                  <div className="col">
                    <div className="header-label">
                      <span className="skeleton-loader"></span>{' '}
                    </div>
                    <div className="mob-font">
                      <span className="skeleton-loader"></span>{' '}
                    </div>
                  </div>
                  <div className="col">
                    <div className="header-label">
                      <span className="skeleton-loader"></span>{' '}
                    </div>
                    <div className="mob-font">
                      <span className="skeleton-loader"></span>{' '}
                    </div>
                  </div>
                  <div className="col">
                    <div className="header-label">
                      <span className="skeleton-loader"></span>{' '}
                    </div>
                    <div className="mob-font">
                      <span className="skeleton-loader"></span>{' '}
                    </div>
                  </div>
                  <div className="col">
                    <div className="header-label">
                      <span className="skeleton-loader"></span>{' '}
                    </div>
                    <div className="mob-font">
                      <span className="skeleton-loader"></span>{' '}
                    </div>
                  </div>
                </div>
              </div>
            </h2>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="heading0">
              <div className="card-body mob-padding">
                <div className="row">
                  <div className="col">
                    <div className="header-label">
                      <span className="skeleton-loader"></span>{' '}
                    </div>
                    <div className="mob-font">
                      <span className="skeleton-loader"></span>{' '}
                    </div>
                  </div>
                  <div className="col">
                    <div className="header-label">
                      <span className="skeleton-loader"></span>{' '}
                    </div>
                    <div className="mob-font">
                      <span className="skeleton-loader"></span>{' '}
                    </div>
                  </div>
                  <div className="col">
                    <div className="header-label">
                      <span className="skeleton-loader"></span>{' '}
                    </div>
                    <div className="mob-font">
                      <span className="skeleton-loader"></span>{' '}
                    </div>
                  </div>
                  <div className="col">
                    <div className="header-label">
                      <span className="skeleton-loader"></span>{' '}
                    </div>
                    <div className="mob-font">
                      <span className="skeleton-loader"></span>{' '}
                    </div>
                  </div>
                </div>
              </div>
            </h2>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="heading0">
              <div className="card-body mob-padding">
                <div className="row">
                  <div className="col">
                    <div className="header-label">
                      <span className="skeleton-loader"></span>{' '}
                    </div>
                    <div className="mob-font">
                      <span className="skeleton-loader"></span>{' '}
                    </div>
                  </div>
                  <div className="col">
                    <div className="header-label">
                      <span className="skeleton-loader"></span>{' '}
                    </div>
                    <div className="mob-font">
                      <span className="skeleton-loader"></span>{' '}
                    </div>
                  </div>
                  <div className="col">
                    <div className="header-label">
                      <span className="skeleton-loader"></span>{' '}
                    </div>
                    <div className="mob-font">
                      <span className="skeleton-loader"></span>{' '}
                    </div>
                  </div>
                  <div className="col">
                    <div className="header-label">
                      <span className="skeleton-loader"></span>{' '}
                    </div>
                    <div className="mob-font">
                      <span className="skeleton-loader"></span>{' '}
                    </div>
                  </div>
                </div>
              </div>
            </h2>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="heading0">
              <div className="card-body mob-padding">
                <div className="row">
                  <div className="col">
                    <div className="header-label">
                      <span className="skeleton-loader"></span>{' '}
                    </div>
                    <div className="mob-font">
                      <span className="skeleton-loader"></span>{' '}
                    </div>
                  </div>
                  <div className="col">
                    <div className="header-label">
                      <span className="skeleton-loader"></span>{' '}
                    </div>
                    <div className="mob-font">
                      <span className="skeleton-loader"></span>{' '}
                    </div>
                  </div>
                  <div className="col">
                    <div className="header-label">
                      <span className="skeleton-loader"></span>{' '}
                    </div>
                    <div className="mob-font">
                      <span className="skeleton-loader"></span>{' '}
                    </div>
                  </div>
                  <div className="col">
                    <div className="header-label">
                      <span className="skeleton-loader"></span>{' '}
                    </div>
                    <div className="mob-font">
                      <span className="skeleton-loader"></span>{' '}
                    </div>
                  </div>
                </div>
              </div>
            </h2>
          </div>
          {/* <div className="accordion-item">
              <h2 className="accordion-header" id="heading0">
                <div className="card-body mob-padding">
                  <div className="row">
                    <div className="col">
                      <div className="header-label">
                        <span className="skeleton-loader"></span>{' '}
                      </div>
                      <div className="mob-font">
                        <span className="skeleton-loader"></span>{' '}
                      </div>
                    </div>
                    <div className="col">
                      <div className="header-label">
                        <span className="skeleton-loader"></span>{' '}
                      </div>
                      <div className="mob-font">
                        <span className="skeleton-loader"></span>{' '}
                      </div>
                    </div>
                    <div className="col">
                      <div className="header-label">
                        <span className="skeleton-loader"></span>{' '}
                      </div>
                      <div className="mob-font">
                        <span className="skeleton-loader"></span>{' '}
                      </div>
                    </div>
                    <div className="col">
                      <div className="header-label">
                        <span className="skeleton-loader"></span>{' '}
                      </div>
                      <div className="mob-font">
                        <span className="skeleton-loader"></span>{' '}
                      </div>
                    </div>
                  </div>
                </div>
              </h2>
            </div> */}
        </div>
      </div>
    </>
  )
}
