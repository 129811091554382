// import base from './base'

// export default {
//   ...base,
//   colors: {
//     ...base.colors,
//     shadow1: '#000',
//     text1: '#FFFFFF',
//     text2: '#C3C5CB',
//     text3: '#6C7284',
//     text4: '#565A69',

//     // backgrounds / greys
//     bg0: '#2F2E2D',
//     bg1: '#3D3D3D',
//     bg2: '#2F2E2D',
//     bg3: '#40444F',
//     bg4: '#565A69',
//     bg5: '#6C7284',

//     //specialty colors
//     modalBG: 'rgba(0,0,0,.425)',
//     advancedBG: 'rgba(0,0,0,0.1)',

//     //primary colors
//     primary1: '#6E84FB',
//     primary4: '#376bad70',
//     primary5: '#153d6f70',

//     // color text
//     primaryText1: '#6da8ff',
//     buttonText: '#ffffff',

//     // secondary colors
//     secondary1: '#24c7d6',
//     bg: '#2F2E2D',
//     cardBg: '#3D3D3D',
//     cardBg2: '#191326',
//     cardBg3: '#51535E',
//     cardBg4: '#2F2E2D',
//     cardBg5: '#332525',
//     primary: '#EE9715',
//     secondary: '#FFF',
//     normal: '#FFF',
//     inputBg: '#2F2E2D',
//     strokColor: '#2F2E2D',
//     navBg: '#2F2E2D',
//   }
// }
import base from './base'

export default {
  ...base,
  colors: {
    ...base.colors,
    shadow1: '#2F80ED',
    text1: '#d6d6d6',
    text2: '#000',
    text3: '#888D9B',
    text4: '#C3C5CB',
    text5: '#000',
    text6: '#fff',

    // backgrounds / greys
    bg0: 'rgb(244, 247, 250)',
    bg1: '#fff',
    bg2: '#F5F3F3',
    bg3: '#EDEEF2',
    bg4: '#CED0D9',
    bg5: '#C4C4C4',
    bg6: '#e8f0f7',
    modalBG: 'rgba(0,0,0,0.3)',
    advancedBG: 'rgba(255,255,255,0.6)',

    //primary colors
    primary1: '#000',
    primary4: '#173552',
    primary5: '#d7d5d5',
    primaryred: '#d81616',

    // color text
    primaryText1: '#000',
    buttonText: '#ffffff',

    // secondary colors
    secondary1: '#fff',
    bg: '#FFFFFF',
    cardBg: '#fff',
    cardBg2: '#F2F2F2',
    cardBg3: '#ECEFFF',
    cardBg4: '#F6F6F6',
    cardBg5: '#3D3D3D',
    primary: '#000',
    secondary: '#6E84FB',
    normal: '#000',
    inputBg: '#FFF9F0',
    strokColor: '#E1E1E1',
    navBg: '#C4C4C4'
  }
}
