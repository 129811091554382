import React, { useContext, useMemo } from 'react'
// import React, { useRef, useState } from 'react'

import styled, { ThemeContext } from 'styled-components'
import { Pair } from 'calculas-sdk'
import { Link } from 'react-router-dom'
// import { SwapPoolTabs } from '../../components/NavigationTabs'

import Question from '../../components/QuestionHelper'
import FullPositionCard from '../../components/PositionCard'
import { useUserHasLiquidityInAllTokens } from '../../data/V1'
import { useTokenBalancesWithLoadingIndicator } from '../../state/wallet/hooks'
import { BackArrow, StyledInternalLink, TYPE } from '../../components/Shared'
import { Text } from 'rebass'
import { LightCard } from '../../components/Card'
import { RowBetween } from '../../components/Row'
// import { ButtonPrimary } from '../../components/Button'
import { AutoColumn } from '../../components/Column'

import { useActiveWeb3React } from '../../hooks'
import { usePairs } from '../../data/Reserves'
import { toV2LiquidityToken, useTrackedTokenPairs } from '../../state/user/hooks'
// import { BodyWrapper } from '../AppBody'
import { Dots } from '../../components/swap/styleds'
import TranslatedText from '../../components/TranslatedText'
import { useI18n } from 'i18n/i18n-react'
// import CandleGraph from '../../mypages/CandleGraph'
// import styled from 'styled-components'
import { DisplayFlexBT, Top2Card, Topcard } from '../LP_Farm/styleds'
import '../../components/Accordion/Accordion.css'
// import { CircularProgressbar } from 'react-circular-progressbar';
// import { AiOutlineQuestionCircle } from 'react-icons/ai';
// import SideNavbar from 'components/SideNavbar'
import Header from 'components/Header'
import { Box } from '@material-ui/core'
import Footer from 'pages/HomePage1/footer/Footer'
// import AddLiquidity from 'pages/AddLiquidity'

export function Accordion_pool() {
  const i18n = useI18n()
  const theme = useContext(ThemeContext)
  const { account } = useActiveWeb3React()

  // fetch the user 's balances of all tracked V2 LP tokens
  const trackedTokenPairs = useTrackedTokenPairs()
  const tokenPairsWithLiquidityTokens = useMemo(
    () => trackedTokenPairs.map(tokens => ({ liquidityToken: toV2LiquidityToken(tokens), tokens })),
    [trackedTokenPairs]
  )
  // const tokenSet = useMemo(() => trackedTokenPairs.map(item=> { console.log(toV2LiquidityToken(item), item,"item") }),
  // [trackedTokenPairs]
  // )
  // console.log(tokenSet,"tokenSet")
  const liquidityTokens = useMemo(() => tokenPairsWithLiquidityTokens.map(tpwlt => tpwlt.liquidityToken), [
    tokenPairsWithLiquidityTokens
  ])
  const [v2PairsBalances, fetchingV2PairBalances] = useTokenBalancesWithLoadingIndicator(
    account ?? undefined,
    liquidityTokens
  )

  // fetch the reserves for all V2 pools in which the user has a balance
  const liquidityTokensWithBalances = useMemo(
    () =>
      tokenPairsWithLiquidityTokens.filter(({ liquidityToken }) =>
        v2PairsBalances[liquidityToken.address]?.greaterThan('0')
      ),
    [tokenPairsWithLiquidityTokens, v2PairsBalances]
  )

  const v2Pairs = usePairs(liquidityTokensWithBalances.map(({ tokens }) => tokens))
  const v2IsLoading =
    fetchingV2PairBalances || v2Pairs?.length < liquidityTokensWithBalances.length || v2Pairs?.some(V2Pair => !V2Pair)

  const allV2PairsWithLiquidity = v2Pairs.map(([, pair]) => pair).filter((v2Pair): v2Pair is Pair => Boolean(v2Pair))

  const hasV1Liquidity = useUserHasLiquidityInAllTokens()
  //   console.log(trackedTokenPairs,"trackedTokenPairs")
  // console.log(tokenPairsWithLiquidityTokens,"tokenPairsWithLiquidityTokens")
  // console.log(liquidityTokensWithBalances,"liquidityTokensWithBalances")
  // console.log(v2PairsBalances,"fetchingV2PairBalances",fetchingV2PairBalances)
  // console.log(allV2PairsWithLiquidity,"v2Pair",v2Pairs)
  return (
    <>
      {/* <SwapPoolTabs active={'pool'} /> */}
      <AutoColumn gap="lg" justify="center">
        {/* <ButtonPrimary id="join-pool-button" as={Link} style={{ padding: 16 }} to="/add/ETH">
          <Text fontWeight={500} fontSize={20}>
            <TranslatedText translationId={288}>Add Liquidity</TranslatedText>
          </Text>
        </ButtonPrimary> */}

        <AutoColumn gap="12px" style={{ width: '100%' }}>
          <RowBetween padding={'0 8px'}>
            <Text color={theme.colors.text5} fontWeight={500}>
              <TranslatedText translationId={118}>Your Liquidity</TranslatedText>
            </Text>
            <Question
              text={i18n(
                302,
                'When you add liquidity, you are given pool tokens that represent your share. If you don’t see a pool you joined in this list, try importing a pool below.'
              )}
            />
          </RowBetween>

          {!account ? (
            <LightCard padding="40px">
              <TYPE.body color={theme.colors.text3} textAlign="center" sx={{ color: '#000 !important' }}>
                {i18n(768, 'Connect to a wallet to view your liquidity.')}
              </TYPE.body>
            </LightCard>
          ) : v2IsLoading ? (
            <LightCard padding="40px">
              <TYPE.body color={theme.colors.text3} textAlign="center">
                <Dots>Loading</Dots>
              </TYPE.body>
            </LightCard>
          ) : allV2PairsWithLiquidity?.length > 0 ? (
            <>
              {allV2PairsWithLiquidity.map((v2Pair, i) => {
                return <FullPositionCard key={v2Pair.liquidityToken.address} pair={v2Pair} pairData={i} />
              })}
              {/* {allV2PairsWithLiquidity.map(v2Pair => (

                  <FullPositionCard  key={v2Pair.liquidityToken.address} pair={v2Pair}/>
                ))} */}
            </>
          ) : (
            <span>
              <Link className="w-100 mb-2" to="/add/ETH">
                <div
                  style={{ color: '#fff' }}
                  className="w-100 mb-3 btn fw-bold frosted-glass-teal frosted-glass-teal clickable-filter-effect"
                >
                  Add Liquidity
                </div>
              </Link>
              <LightCard padding="20px">
                <TYPE.body color={theme.colors.text3} textAlign="center">
                  <TranslatedText translationId={292}>No liquidity found.</TranslatedText>
                </TYPE.body>
              </LightCard>
            </span>
          )}

          <div>
            <Text textAlign="center" fontSize={14} style={{ padding: '.5rem 0 .5rem 0', color: '#000' }}>
              {hasV1Liquidity ? 'Uniswap V1 liquidity found!' : i18n(304, "Don't see a pool you joined?")}{' '}
              <StyledInternalLink id="import-pool-link" to={hasV1Liquidity ? '/migrate/v1' : '/find'}>
                {hasV1Liquidity ? 'Migrate now.' : i18n(306, 'Import it.')}
              </StyledInternalLink>
            </Text>
          </div>
        </AutoColumn>
      </AutoColumn>
    </>
  )
}

export default function Pool() {
  // const [send, setSend] = useState("24H");

  const HeaderWrapper = styled.div`
    ${({ theme }) => theme.flexRowNoWrap}
    // width: 100%;
  justify-content: space-between;
  `
  const AppWrapper = styled.div`
    //  display: flex;
    //  flex-flow: column;
    //   align-items: flex-start;
    //  overflow-x: hidden;
    //  min-height: 100vh;
  `
  return (
    <>
      <AppWrapper id="mainwrapper">
        <HeaderWrapper>
          <Header />
        </HeaderWrapper>
        <div
          className="Main-div"
          style={{ position: 'relative', paddingBottom: '40px' }}
        >
          <Box style={{zIndex:"-1", position: 'absolute', right: '30px', top: '50px' }}>
            <img src="/images/greenishbg.png" style={{ width: '100%', maxHeight: '700px' }} />
          </Box>
          <Box
            style={{
              top: '60%',
              left: '-70px',
              right: '0%',
              width: '314px',
              filter: 'blur(94px)',
              height: '314px',
              opacity: '40%',
              zIndex: '2',
              position: 'absolute',
              transform: 'rotate(-90deg)',
              background: '#FED056',
              borderRadius: '1000px'
            }}
          >
            {/* <img src='/images/greenishbg.png' style={{width:"100%",maxHeight:"400px"}} /> */}
          </Box>
          <div className="content-wrapper" style={{ paddingTop: '57px' }}>
            {/* <BodyWrapper id="bodywrapper"> */}
            <div className="container" style={{paddingTop:"20px"}}>
             <Box style={{display:"flex",alignContent:"center"}}>
             <BackArrow to="/swap" />
             <h3 className="ms-2 text-white" style={{display:"flex",justifyContent:"center",textAlign:"center",width:"100%"}}>  Pools</h3>
             </Box>
              
              <Topcard>
                <Top2Card  className="mobile-scroll">
                  <DisplayFlexBT>
                   
                  </DisplayFlexBT>
                  
                  <div className="row justify-content-center">
                    <div className="col-12 mt-2">
                      <Accordion_pool />
                    </div>
                  </div>
                </Top2Card>
              </Topcard>
            </div>
          </div>
        </div>
        {/* </div> */}
        <Footer />
      </AppWrapper>
      {/* </BodyWrapper> */}
      {/* <AddLiquidity/> */}
    </>
  )
}
