import React from 'react'

export default function DataNotFound({ title }) {
  return (
    <div className="alert alert-warning border-start-0 my-2 text-center">
      <img className="grayimg" src="/images/norecord.png" width={75} />
      <p className="fs-16 ms-2 mb-0">{title || 'No Data Found'}</p>
    </div>
  )
}
