//UAT LOCAL
export const baseurl = 'https://api-nftmarketplace.tarality.io/'
export const baseurl1 = 'https://backendmarketplace.tarality.io/api/v1'
export const socketURL = 'wss://backendmarketplace.tarality.io'
export const graphURL = "https://api-dapp.tarality.io/api"
let user = `${baseurl}api/v1/user`
let whitelist = `${baseurl}api/v1`
let collection = `${baseurl}api/v1/collection`
let nft = `${baseurl}api/v1/nft`
let order = `${baseurl}api/v1/order`
let bid = `${baseurl}api/v1/bid`
let admin = `${baseurl}api/v1/admin`
let staticlist = `${baseurl}api/v1/static`
const Apiconfigs = {
  connectWallet: `${user}/connectWallet`,
  profile: `${user}/profile`,
  updateProfile: `${user}/updateProfile`,
  myCollectionList: `${collection}/myCollectionList`,
  createCollection: `${collection}/createCollection`,
  viewCollection: `${collection}/viewCollection/`,
  editCollection: `${collection}/editCollection`,
  deleteCollection: `${collection}/deleteCollection`,
  userOwendCount: `${user}/userOwendCount/`,
  followUnfollow: `${user}/followUnfollow/`,
  userLikesCount: `${user}/userLikesCount/`,
  userOnSaleCount: `${user}/userOnSaleCount/`,
  userCreatedCount: `${user}/userCreatedCount/`,
  userFollowerCount: `${user}/userFollowerCount/`,
  userFollowingCount: `${user}/userFollowingCount/`,
  userBuyAndCreatedList: `${user}/userBuyAndCreatedList/`,
  dashboardCount: `${user}/dashboardCount`,
  userSubscribe: `${user}/userSubscribe`,
  userVerifySubscription: `${user}/userVerifySubscription`,
  onSaleCount: `${user}/onSaleCount`,
  userFavourateCount: `${user}/userFavourateCount/`,
  createOrderReports: `${user}/createOrderReports`,
  spinWinner: `${user}/spinWinner`,

  //collection
  myCollectionList: `${collection}/myCollectionList`,
  createCollection: `${collection}/createCollection`,
  collectionList: `${collection}/collectionList`,
  viewCollection: `${collection}/viewCollection/`,

  // nft
  createNFT: `${nft}/createNFT`,
  uploadNFT: `${nft}/uploadNFT`,
  ipfsUpload: `${nft}/ipfsUpload`,
  addNft: `${nft}/addNft`,
  listNFT: `${nft}/listNFT`,
  viewNFT: `${nft}/viewNFT/`,
  showNftHistory: `${nft}/showNftHistory`,
  showActivity: `${nft}/showActivity`,
  uploadImage: `${nft}/uploadImage`,

  //nfttopseller

  topBuyers: `${user}/topBuyers`,
  topSellers: `${user}/topSalers`,
  contactUs: `${user}/contactUs`,
  myNftReportList: `${user}/myNftReportList`,

  //order
  likeDislikeOrder: `${order}/likeDislikeOrder/`,
  createOrder: `${order}/createOrder`,
  editOrder: `${order}/editOrder`,
  viewOrder: `${order}/viewOrder/`,
  feedBack: `${order}/feedBack`,
  listfeedback: `${order}/listfeedback`,
  particularCollectionOrderList: `${order}/particularCollectionOrderList/`,
  favouriteUnFavouriteOrder: `${order}/favouriteUnFavouriteOrder/`,
  floorTradeCount: `${order}/floorTradeCount`,
  allOrderMaxPrice: `${order}/allOrderMaxPrice`,
  expiredNft: `${order}/expiredNft`,

  // Whitelist Address
  userwhitelist: `${whitelist}/wallet/whiteListAddressWithoutToken`,
  addresswhitelist: `${whitelist}/wallet/whiteListAddress`,
  // pool

  ActivePoolList: `${baseurl1}/pool/ActivePoolList`,
  inactivePoolList: `${baseurl1}/pool/inactivePoolList`,
  participatesUser: `${baseurl1}/pool/participatesUser`,
  participateList: `${baseurl1}/pool/participateList`,
  cmcPriceList: `${baseurl1}/pool/cmcPriceList`,
  leaderBoard: `${baseurl1}/pool/leaderBoard`,
  topThree: `${baseurl1}/pool/topThree`,

  // grpah data
  insertGraphQlData: `${graphURL}/insertGraphQlData`,
  getGraphQlData: `${graphURL}/getGraphQlData`
  
}

export default Apiconfigs
