import { Box, Grid, MenuItem, Select } from '@material-ui/core'
import Header from 'components/Header'
import React, { useEffect, useState } from 'react'
import ERC20_Type from '../helper/erc20.json'
import { createToken } from '../helper/api'
import sourcecode from '../helper/sourcecode.json'
import axios from 'axios'
import CreateTokenLIst from './CreateTokenLIst'

import { ethers } from 'ethers'

import './swap.css'
import toast from 'react-hot-toast'
import { useActiveWeb3React } from 'hooks'
import Footer from 'pages/HomePage1/footer/Footer'

export default function CreateToken() {
  const { account, chainId, library } = useActiveWeb3React()
  const [selectValue, setSelectValue] = useState('standard')
  const [isLoading, setIsLoading] = useState(false)
  const [refCreateToken, setRefCreateToken] = useState(false)
  const [network, setNetwork] = useState(97)
  const [tokenBalADD, setTokenBalADD] = useState()

  useEffect(() => {
    setTokenBalADD('0x6B63b63ce0c59D63a263B1B8cF9c52D912b9A608')
  }, [account])

  const [standards, setStandard] = useState({
    tokenname: '',
    tokensymbol: '',
    tokensupply: '1000000000000000',
    decimal: 18,
    canmints: false,
    canburns: false
  })

  console.log('>>>>>>>>>>standards', standards)

  function handleInputChange(e) {
    const { name, value, checked } = e.target
    console.log('>>>>>>>..checked', checked, name)
    if (selectValue == 'standard') {
      if (name == 'canmints' || name == 'canburns') {
        setStandard(preval => {
          return {
            ...preval,
            [name]: checked
          }
        })
      } else {
        setStandard(preval => {
          return {
            ...preval,
            [name]: value === 'on' || value === 'off' ? checked : value
          }
        })
      }
    }
  }

  //   function to create
  function encodeConstructorParams(type, params) {
    console.log('>>>>>>>>>>>type', type, params)
    const abiCoder = new ethers.utils.AbiCoder()
    console.log('>>>>>>>>>>>abiCoder.encode(type, params)', abiCoder.encode(type, params))
    return abiCoder.encode(type, params)
  }

  const handleFormSubmit = async () => {
    setRefCreateToken(false)

    if (!account) {
      toast.error('Please connect Your Wallet.')
      return
    }

    try {
      const user = account
      setNetwork(chainId)

      if (selectValue !== 'standard') {
        return
      }

      const { tokenname, tokensymbol, tokensupply, decimal, canmints, canburns } = standards

      console.log(canmints, canburns, 'aksjdgkasd')

      if (
        tokenname.trim() === '' ||
        tokensymbol.trim() === '' ||
        decimal.length === 0 ||
        tokensupply.trim() === '' ||
        Number(tokensupply) < 1 ||
        decimal < 1 ||
        decimal > 255 ||
        !Number.isInteger(Number(decimal)) ||
        !Number.isInteger(Number(tokensupply))
      ) {
        if (tokenname.trim() === '') {
          document.querySelector('.tokenname').textContent = '(required)'
        } else {
          document.querySelector('.tokenname').textContent = ''
        }

        if (tokensymbol.trim() === '') {
          document.querySelector('.tokensymbol').textContent = '(required)'
        } else {
          document.querySelector('.tokensymbol').textContent = ''
        }

        if (tokensupply.trim() === '' || Number(tokensupply) < 1 || !Number.isInteger(Number(tokensupply))) {
          if (tokensupply.trim() === '') {
            document.querySelector('.tokensupply').textContent = '*(required)'
          }
          if (Number(tokensupply) < 1) {
            document.querySelector('.tokensupply').textContent = '*(Please enter a value greater than or equal to 1)'
          }
          if (!Number.isInteger(Number(tokensupply))) {
            document.querySelector('.tokensupply').textContent = '*(Need to be integer)'
          }
        } else {
          document.querySelector('.tokensupply').textContent = ''
        }

        if (decimal.length === 0 || decimal < 1 || decimal > 255 || !Number.isInteger(Number(decimal))) {
          if (decimal.length === 0) {
            document.querySelector('.decimal').textContent = '*(required)'
          }
          if (decimal < 1) {
            document.querySelector('.decimal').textContent = '*(Please enter a value greater than or equal to 1)'
          }
          if (decimal > 255) {
            document.querySelector('.decimal').textContent = '*(Please enter a value less than or equal to 255)'
          }
          if (!Number.isInteger(Number(decimal))) {
            document.querySelector('.decimal').textContent = '*(Need to be integer)'
          }
        } else {
          document.querySelector('.decimal').textContent = ''
        }
        return
      }

      setIsLoading(true)

      console.log('>>>>>>>>>>chainId123', ERC20_Type.standard.inputs)
      let encode = encodeConstructorParams(ERC20_Type.standard.inputs, [
        tokenname,
        tokensymbol,
        tokensupply,
        decimal,
        canmints,
        canburns,
        tokenBalADD
      ]).slice(2)

      console.log('>>>>>>>>>>>encode>>', encode)

      let data = ERC20_Type.standard.bytesCode + encode

      const provider = new ethers.providers.Web3Provider(window.ethereum)
      await provider.send('eth_requestAccounts', []) // Request account access if needed
      const signer = provider.getSigner()

      let result = await signer.sendTransaction({
        from: account,
        value: 0,
        data: data,
        chainId: chainId
      })

      console.log('>>>>>>>>>>>>>>result', result)

      try {
        await toast.promise(result.wait(), {
          loading: 'Processing token deployment...',
          success: 'Token is deployed.',
          error: 'Something went wrong.'
        })

        console.log('>>>>>>>>hash', result.hash)
        let res = await provider.waitForTransaction(result.hash)
        console.log('>>>>>>>>>>>reschecking', res)
        if (Number(res.status) === 1) {
          createToken(standards, selectValue, res, '0', tokenBalADD, setRefCreateToken, account)
        }

        verifyToken(res, sourcecode.standards, 'StandardToken', encode)
      } catch (transactionError) {
        console.error('Transaction Error:', transactionError)
      }
    } catch (error) {
      console.error('Error during token creation:', error)
      toast.error(error.message ?? 'Something went wrong.')
      setIsLoading(false)
    } finally {
      //   setIsLoading(false)
    }
  }

  const verifyToken = async (recriptData, source, name, argument) => {
    try {
      toast('Processing token verification...')
      console.log(recriptData, 'recriptData')
      toast('It takes 30-60 seconds to verify and publish token. Please do not refresh the page.')
      setTimeout(async () => {
        if (recriptData && Number(recriptData.status) === 1) {
          try {
            const dat = await axios.post(
              'https://devnet.taralscan.com/api',
              {
                module: 'contract',
                action: 'verify',
                addressHash: recriptData.contractAddress,
                name: name,
                compilerVersion: 'v0.8.17+commit.8df45f5f',
                optimization: true,
                contractSourceCode: source,
                constructorArguments: argument,
                optimizationRuns: 200
              },
              {
                headers: { 'content-type': 'application/json' }
              }
            )

            toast.success('Token verified.')
            window.location.reload()
            setStandard({
              tokenname: '',
              tokensymbol: '',
              tokensupply: '1000000000000000',
              decimal: 18,
              canmints: false,
              canburns: false
            })
            console.log('d::', dat)
            setIsLoading(false)
          } catch (error) {
            toast.error('Token verification failed.')
            console.log(error)
          } finally {
            setIsLoading(false)
          }
        } else {
          toast.error('error')
          setIsLoading(false)
        }
      }, 8000)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div style={{ width: '100%', position: 'relative' }}>
      <Header />

      <Box style={{ position: 'absolute', right: '30px', top: '50px' }}>
        <img src="/images/greenishbg.png" style={{ width: '100%', maxHeight: '700px' }} />
      </Box>
      <Box
        style={{
          top: '60%',
          left: '-70px',
          right: '0%',
          width: '314px',
          filter: 'blur(94px)',
          height: '314px',
          opacity: '40%',
          zIndex: '2',
          position: 'absolute',
          transform: 'rotate(-90deg)',
          background: '#FED056',
          borderRadius: '1000px'
        }}
      >
        {/* <img src='/images/greenishbg.png' style={{width:"100%",maxHeight:"400px"}} /> */}
      </Box>
      <section className="content-wrapper" style={{ paddingTop: '37px' }}>
        <div id="bodywrapper" className="container-fluid" style={{ paddingBottom: '20px' }}>
          <div className="row">
            <section className="rewardwrapper">
              <div className="container">
                <div
                // className="exchange"
                >
                  <div className="text-center">
                    <h3 style={{ color: '#fff' }} className="fw-bold text-uppercase">
                      Create Token
                    </h3>
                  </div>

                  <Grid
                    container
                    spacing={2}
                    //   className="row mt-5"
                  >
                    <Grid
                      item
                      xs={12}
                      md={5}
                      // className="col-md-5 mb-4"
                    >
                      <h5 style={{ color: '#fff' }} className="">
                        Create Token
                      </h5>
                      <div>
                        <div className="tab-pane active" id="create_token" role="tabpanel">
                          <div className="cardBg card">
                            <div className="card-body cardBgInner">
                              <div className="col-sm">
                                {/* <Select
                                  fullWidth
                                  value={selectValue}
                                  onChange={e => {
                                    setSelectValue(e.target.value)
                                  }}
                                >
                                  <MenuItem value="standard">Standard</MenuItem>
                                </Select> */}
                                <select
                                  className="form-select token_type"
                                  aria-label="Select token type"
                                  value={selectValue == 'standard' ? 'ERC-20' : 'ERC-20'}
                                  onChange={e => {
                                    setSelectValue(e.target.value)
                                  }}
                                >
                                  <option value="standard" defaultValue={selectValue}>
                                    ERC-20
                                  </option>
                                </select>

                                <div className="col">
                                  <span className="badge bg-dark" id="tag_popular">
                                    Popular
                                  </span>
                                  <span className="badge bg-dark" id="tag_hot">
                                    🔥Hot
                                  </span>
                                  <span className="badge bg-dark" id="tag_new">
                                    New
                                  </span>
                                  <span className="badge bg-dark" id="tag_early">
                                    🐣Early Access
                                  </span>
                                </div>
                              </div>
                              <div id="token_container">
                                <form id="formReset">
                                  {selectValue == 'standard' && (
                                    <div className="form-group row divBackdrop">
                                      <div className="col-sm-6">
                                        <div>
                                          <label className="col-form-lable fw-bold">
                                            Token name <span className="text-danger">*</span>
                                            <span className="text-danger tokenname"></span>
                                          </label>
                                        </div>
                                        <div>
                                          <input
                                            className="token_name "
                                            type="text"
                                            placeholder="Token Name"
                                            required=""
                                            name="tokenname"
                                            title="Enter token name"
                                            onChange={handleInputChange}
                                            autoComplete="off"
                                            minLength={1}
                                            maxLength={18}
                                            value={standards.tokenname || ''}
                                          />
                                          <p className="hint">Choose a name for your token.</p>
                                        </div>
                                      </div>
                                      <div className="col-sm-6">
                                        <div>
                                          <label className="col-form-lable fw-bold">
                                            Token symbol <span className="text-danger">*</span>
                                            <span className="text-danger tokensymbol"></span>
                                          </label>
                                        </div>
                                        <div>
                                          <input
                                            className="token_symbol"
                                            type="text"
                                            placeholder="Token Symbol"
                                            required=""
                                            name="tokensymbol"
                                            title="Enter token symbol"
                                            onChange={handleInputChange}
                                            autoComplete="off"
                                            minLength="3"
                                            maxLength="6"
                                            value={standards.tokensymbol.toUpperCase() || ''}
                                          />
                                          <p className="hint">Choose a symbol for your token (usually 3-6 chars).</p>
                                        </div>
                                      </div>
                                    </div>
                                  )}

                                  {selectValue == 'standard' && (
                                    <div className="form-group row divBackdrop">
                                      <div className="col-sm-6">
                                        <div>
                                          <label className="col-form-lable fw-bold">
                                            Total supply <span className="text-danger tokensupply"></span>
                                          </label>
                                        </div>
                                        <div>
                                          <input
                                            className="token_supply"
                                            type="number"
                                            placeholder="1000000000000"
                                            name="tokensupply"
                                            integer="true"
                                            min="0"
                                            onChange={handleInputChange}
                                            value={standards.tokensupply || ''}
                                            pattern="^[1-9]\d*$"
                                          />
                                          <p className="hint">
                                            Insert the initial number of tokens available. Will be put in your account.
                                          </p>
                                        </div>
                                      </div>
                                      <div className="col-sm-6">
                                        <div>
                                          <label className="col-form-lable fw-bold">
                                            Decimals <span className="text-danger decimal"></span>
                                          </label>
                                          <input
                                            className="decimals"
                                            type="number"
                                            placeholder="18"
                                            name="decimal"
                                            min="1"
                                            value="18"
                                            max="255"
                                            onChange={handleInputChange}
                                          />
                                          <p className="hint">Decimal precision of your token.</p>
                                        </div>
                                      </div>
                                    </div>
                                  )}

                                  {selectValue == 'standard' && (
                                    <>
                                      <div className="form-group row divBackdrop">
                                        <div className="col-md-6">
                                          <input
                                            type="checkbox"
                                            className="input-checkbox"
                                            id="canMints"
                                            name="canmints"
                                            value={standards.canmints}
                                            onChange={e => {
                                              handleInputChange(e)
                                            }}
                                          />
                                          <label className="input-checkbox-label fw-bold" htmlFor="canMints">
                                            Mintable
                                          </label>
                                          <br />
                                        </div>

                                        <div className="col-md-6">
                                          <input
                                            type="checkbox"
                                            className="input-checkbox"
                                            id="canBurns"
                                            value={standards.canburns}
                                            name="canburns"
                                            onChange={e => {
                                              handleInputChange(e)
                                            }}
                                          />
                                          <label className="input-checkbox-label fw-bold" htmlFor="canBurns">
                                            Burnable
                                          </label>

                                          <br />
                                        </div>
                                      </div>
                                    </>
                                  )}

                                  <hr />

                                  <div className="d-grid tokenlistSubmit py-3">
                                    {selectValue !== 'customizedToken' && (
                                      <button
                                        type="button"
                                        className="btn btn btn-submit text-uppercase"
                                        style={{
                                          borderRadius: '12px'
                                        }}
                                        onClick={handleFormSubmit}
                                        disabled={isLoading}
                                      >
                                        {isLoading ? 'Creating...' : ' Create Token'}
                                      </button>
                                    )}
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={7}
                      // className="col-md-7"
                    >
                      <CreateTokenLIst refCreateToken={refCreateToken} />
                    </Grid>
                  </Grid>
                </div>
              </div>
            </section>
          </div>
        </div>
        <Footer />
      </section>
    </div>
  )
}
