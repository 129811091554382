import { Box, Button, Container, Grid, Typography, IconButton, Hidden } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { useContext, useState, useEffect } from 'react'
import { useWeb3React } from '@web3-react/core'
import ButtonCircularProgress from '../../component/ButtonCircularProgress'
import NormalNft from './NormalNft'
import axios from 'axios'
import Apiconfig from '../../ApiConfig/ApiConfig'
import { UserContext } from '../../context/User'
import toast from 'react-hot-toast'
import { shortenAddress } from 'utils'
import copyIcon from '../../assets/images/copy.png'
import Header from 'components/Header'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',

    zIndex: '1',
    '& .shade': {
      top: '-8%',
      left: 'auto',
      right: '0',
      width: '600px',
      background: 'linear-gradient(162.34deg, rgba(0, 251, 251, 0.5) 57.54%, rgb(32 12 245) 85.06%)',
      filter: 'blur(200px)',
      bottom: 'auto',
      height: '600px',
      opacity: '0.55',
      zIndex: '-1',
      position: 'absolute',
      borderRadius: '1000px'
    },
    '& .shape7': {
      top: '-150px',
      right: '0px',
      position: 'absolute',
      maxWidth: '1000px',
      opacity: '0.3'
    },
    '& .shade1': {
      top: '500px',
      left: '-5%',
      right: 'auto',
      width: '400px',
      bottom: 'auto',
      filter: 'blur(200px)',
      minHeight: 'calc(100vh - 205px)',

      zIndex: '-1',
      position: 'absolute',
      borderRadius: '1000px',
      background: 'linear-gradient(109.76deg, rgba(131, 0, 131, 0.4) 40.85%, rgba(46, 30, 219, 0.4) 69.69%)',
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      }
    },
    padding: '110px 0px'
  },
  bannerimg: {
    position: 'relative',
    overflow: 'hidden',
    backgroundPosition: 'center !important',
    backgroundSize: '100% !important',
    backgroundRepeat: ' no-repeat !important',
    height: '300px',
    borderRadius: '50px',
    '@media(max-width:1010px)': {
      height: '140px',
      borderRadius: '25px'
    },
    '& img': {
      minHeight: '100%',
      minWidth: '100%',
      height: 'auto',
      width: 'auto'
    }
  },

  text1: {
    marginLeft: '16px',
    '@media(max-width:375px)': {
      marginTop: '5px',
      marginLeft: '0px',
      wordBreak: 'break-word'
    },
    '& h4': {
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '40px',
      color: '#fff',
      lineHeight: '130%',
      '@media(max-width:1010px)': {
        fontSize: '30px'
      },
      '@media(max-width:930px)': {
        fontSize: '25px'
      }
    },
    '& h5': {
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '130%',
      color: '#00FBFB'
    }
  },
  whitebox: {
    background: 'rgba(166, 162, 162, 0.2)',
    border: '1.5px solid rgba(255, 255, 255, 0.1)',
    boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.15)',
    borderRadius: '10px',
    paddingTop: '10px',
    paddingBottom: '10px'
  },

  idtxt: {
    display: 'flex',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
    alignItems: 'center',
    '@media(max-width:818px)': {
      display: 'block'
    }
  },
  file: {
    padding: '10px 10px 10px 10px',
    // background: "#FCF2FA",
    borderRadius: '50%',
    color: '#fff'
  },

  headbox2: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 20px',
    marginBottom: '15px',
    '@media(max-width:767px)': {
      display: 'block',
      padding: '0 10px'
    },
    '& h6': {
      fontWeight: '500',
      fontSize: '41px',
      lineHeight: '47px',
      color: '#FFFFFF',
      whiteSpace: 'pre'
    }
  },

  profileimg: {
    marginTop: '-96px',
    // overflow: "hidden",
    backgroundPosition: 'center !important',
    backgroundSize: '100% !important',
    backgroundRepeat: ' no-repeat !important',
    width: '175px',
    marginLeft: '30px',
    height: '175px',
    borderRadius: '100px',
    position: 'relative',
    border: '10px solid #000',
    backgroundColor: '#8e9493 !important',
    '@media(max-width:1010px)': {
      marginTop: '-65px',
      width: '110px',
      height: '110px'
    },
    '@media(max-width:800px)': {
      marginTop: '-65px',
      width: '90px',
      height: '90px'
    },
    '& .editprofilebutton': {
      background: 'linear-gradient(261.87deg, #62D3F0 13.12%, #35A5F5 83.57%)',
      position: 'absolute',
      right: '3px',
      bottom: '3px',
      '@media(max-width:800px)': {
        width: '35px',
        height: '35px'
      },
      '& svg': {
        color: '#FFFFFF'
      }
    },
    '& img': {
      minHeight: '100%',
      minWidth: '100%',
      height: 'auto',
      width: 'auto'
    }
  },

  profileWallet: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '@media(max-width:767px)': {
      borderBottom: '1px solid gray'
    },

    '& h6': {
      color: '#00000',
      '@media(max-width:800px)': { fontSize: '17px' }
    }
  },

  tabBtn: {
    '@media(max-width:767px)': {
      marginTop: '10px'
    },

    '& button': {
      borderRadius: '10px',
      fontWeight: '5  00',
      fontSize: '14px',
      marginRight: '4px',
      color: '#fff',
      '&:hover': {
        background: 'rgba(166, 162, 162, 0.2)'
      },
      '&.active': {
        color: '#fff',
        boxShadow: '0px 4px 4px rgb(0 0 0 / 25%)',
        background: 'linear-gradient(261.87deg, #62D3F0 13.12%, #35A5F5 83.57%)'
      }
    }
  }
}))

export default function Profile() {
  const { account } = useWeb3React()
  const [tabview, setTabView] = useState('normal')
  const user = useContext(UserContext)
  const [isLoading, setIsLoading] = useState(true)
  const [normalNFTList, setNormalNFTList] = useState([])
  const classes = useStyles()
  const normalNFTListHandler = async id => {
    try {
      const res = await axios.get(Apiconfig.listNFT, {
        headers: {
          token: window.sessionStorage.getItem('token')
        }
      })
      if (res.data.statusCode === 200) {
        const dataList = res.data.result.docs.filter(
          data =>
            data.isPlace === false &&
            // data.isCancel == true &&
            // data.collectionId[0]?.isLazyMinting == false &&
            data.isResale === false
        )

        setNormalNFTList(dataList)
        setIsLoading(false)
      }
    } catch (error) {
      console.log('ERROR', error)
      setIsLoading(false)
    }
  }

  // const id = user?.userData?._id;

  useEffect(() => {
    updateDatahandler()
  }, [user?.userData?._id])

  const updateDatahandler = () => {
    if (user?.userData?._id) {
      const id = user?.userData._id
      normalNFTListHandler(id)
      user.getProfileHandler(window.sessionStorage.getItem('token'))
    }
  }

  return (
    <Box className={classes.root} style={{ width: '100%' }}>
      <Header />

      <Box className="shade1"></Box>
      <Box className="shade"></Box>
      <Container maxWidth="lg">
        <Box
          className={classes.bannerimg}
          style={
            user.userData?.coverPic ? { background: 'url(' + user.userData?.coverPic + ')' } : { background: '#000' }
          }
        ></Box>

        <Box className={classes.headbox2}>
          <Box style={{ display: 'flex', flexWrap: 'wrap' }}>
            <Box style={{ background: 'url(' + user?.userData?.profilePic + ')' }} className={classes.profileimg}></Box>

            <Box className={classes.text1}>
              <Typography variant="h4" style={{ wordBreak: 'break-word' }}>
                {user?.userData?.name}
              </Typography>
              <Typography variant="h5">{user?.userData?.email}</Typography>
            </Box>
          </Box>
        </Box>

        <Box className={classes.whitebox}>
          <Container>
            <Box className={classes.idtxt}>
              <Box className={classes.profileWallet}>
                <Typography variant="h6" style={{ color: '#fff' }}>
                  Address:
                </Typography>
                &nbsp;&nbsp;
                <Typography variant="body2" style={{ color: '#fff' }}>
                  {user &&
                    user?.userData &&
                    user?.userData?.walletAddress &&
                    shortenAddress(user?.userData?.walletAddress)}
                </Typography>
                <Box className={classes.file}>
                  <img
                    style={{ cursor: 'pointer', width: '25px', height: '25px' }}
                    src={copyIcon}
                    alt=""
                    onClick={() => {
                      navigator.clipboard.writeText(user?.userData?.walletAddress)
                      toast.success('Copied')
                    }}
                  />
                </Box>
              </Box>
              <Box className={classes.tabBtn}>
                <Button className={tabview === 'normal' ? 'active' : ' '} onClick={() => setTabView('normal')}>
                  My NFT
                </Button>
              </Box>
            </Box>
          </Container>
        </Box>
        <Grid container spacing={3}>
          <Grid item md={9} sm={12} xs={12} lg={12}>
            {isLoading ? (
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%'
                }}
              >
                <ButtonCircularProgress />
              </Box>
            ) : (
              <>
                <Box style={{ marginTop: '26px' }}>
                  {tabview === 'normal' ? <NormalNft nftList={normalNFTList} /> : ''}
                </Box>
              </>
            )}
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}
