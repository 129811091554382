import React from 'react'
import './index.css'
import { Box } from '@material-ui/core'
import HeroSection from './HeroSection'
import Section2 from './Section2'
import CircleRoadMap from './CircleSection'
import ConnectWalletPage from './ConnectWalletPage'
import FAQpage from './FAQpage'
import OneAppSection from './OneAppSection'
import BlockchainPageSolution from './BlockchainPageSolution'
import SmartSolutionPage from './SmartSolutionPage'
import Footer from './footer/Footer'
import NewHeader from './header/NewHeader'
import Header from 'components/Header'

const HomePage = () => {
  return (
    <Box
      style={{
        background: '#0d151f',
        width: '100%'
      }}
    >
      {/* <NewHeader /> */}
      <Header />
      <HeroSection />
      <Section2 />
      <CircleRoadMap />
      <BlockchainPageSolution />
      <OneAppSection />
      {/* <SmartSolutionPage /> */}
      <FAQpage />
      <ConnectWalletPage />
      <Footer />
    </Box>
  )
}

export default HomePage
