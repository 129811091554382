import { Box, Container, Grid, makeStyles, Typography, Button } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles(theme => ({
  heading: {
    color: '#fff',
    fontSize: '36px',
    fontWeight: '500',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  },
  phoneImage: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100% !important',
      maxHeight: '100% !important'
    }
  },
  phoneImageContainer: {
    // padding: '0px 50px',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'hidden',
      flexDirection: 'column'
    }
  },

  phoneImage: {
    height: '800px',
    width: '800px',
    objectFit: 'scale-down',
    position: 'absolute',
    top: '-233px',
    left: '-62px',
    [theme.breakpoints.down('sm')]: {
      position: 'static',
      height: '100%',
      width: '100%'
    }
  }
}))

const OneAppSection = () => {
  const classes = useStyles()
  const content = (
    <>
      <Typography
        variant="h5"
        style={{
          // fontSize: "25px",
          paddingBottom: '9px',
          // color: "#fff",
          marginBottom: '16px'
        }}
        className={classes.heading}
      >
        One App <span style={{ color: '#4AFCFE' }}> Unlimited </span> Possibilities
      </Typography>
      <Box className={classes.phoneImageContainer}>
        <Typography
          variant="body1"
          style={{
            fontSize: '20px',
            margin: '10px 0px'
          }}
        >
          Connect your Web3 wallet (MetaMask)
        </Typography>
        <Box
          style={{ width: '100%', height: '1px', background: 'linear-gradient(rgb(34 132 255), rgb(77 59 59))' }}
        ></Box>
        <Typography
          variant="body1"
          style={{
            fontSize: '20px',
            margin: '10px 0px'
          }}
        >
          Swap tokens or add liquidity to start earning
        </Typography>
        <Box
          style={{ width: '100%', height: '1px', background: 'linear-gradient(rgb(34 132 255), rgb(77 59 59))' }}
        ></Box>

        <Typography
          variant="body1"
          style={{
            fontSize: '20px',
            margin: '10px 0px'
          }}
        >
          Explore our other features like token creation and NFTs
        </Typography>
        <Box
          style={{ width: '100%', height: '1px', background: 'linear-gradient(rgb(34 132 255), rgb(77 59 59))' }}
        ></Box>

        <Typography
          variant="body1"
          style={{
            fontSize: '20px',
            margin: '10px 0px'
          }}
        >
          Join our community and stay up-to-date with the latest Tarality news
        </Typography>
      </Box>
    </>
  )

  return (
    <Box sx={{ marginTop: '100px', marginBottom: '100px' }}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} style={{ display: 'flex', alignItems: 'center' }}>
            <Box
              style={{
                height: '100%',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column'
              }}
            >
              {content}
            </Box>
          </Grid>
          <Grid item xs={12} md={6} style={{ position: 'relative' }}>
            <img src="new-module/phoneMolecule.png" alt="" className={classes.phoneImage} />
          </Grid>

          {/* <Grid
            item
            xs={12}
            md={6}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Box
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              {content}
            </Box>
          </Grid> */}
          {/* <Grid item xs={12} md={6}>
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'end'
              }}
              className={classes.phoneImageContainer}
            >
              <img
                className={classes.phoneImage}
                src="new-module/phoneMolecule.png"
                alt="left"
                style={{
                  width: '100%',
                  height: '100%',
                  maxWidth: '500px',
                  maxHeight: '500px',
                  transform: 'rotate(60deg)'
                }}
              />
            </Box>
          </Grid> */}
        </Grid>
      </Container>
    </Box>
  )
}

export default OneAppSection
