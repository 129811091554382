import { JSBI, Pair, Percent } from 'calculas-sdk'
import { darken } from 'polished'
import React, { useState } from 'react'
import { ChevronsLeft, ChevronsRight } from 'react-feather'
import {
  //  AiOutlineLink,
  AiOutlineMinus
} from 'react-icons/ai'
import { BiTransferAlt } from 'react-icons/bi'
import { Link } from 'react-router-dom'
import { Text } from 'rebass'
import styled from 'styled-components'
import { useTotalSupply } from '../../data/TotalSupply'

import { useActiveWeb3React } from '../../hooks'
import { useTokenBalance } from '../../state/wallet/hooks'
import { currencyId } from '../../utils/currencyId'
import { unwrappedToken } from '../../utils/wrappedCurrency'
// import { ButtonSecondary } from '../Button'

import Card, { LightCard } from '../Card'
import { AutoColumn } from '../Column'
import CurrencyLogo from '../CurrencyLogo'
import DoubleCurrencyLogo from '../DoubleLogo'
import { RowBetween, RowFixed } from '../Row'
import { Dots } from '../swap/styleds'
// const iconarrow = require('../../assets/images/left-arrow.svg');
import '../../components/Accordion/Accordion.css'
import { HiOutlineStar } from 'react-icons/hi'
import './style.css'

export const FixedHeightRow = styled(RowBetween)`
  height: 25px;
`

export const HoverCard = styled(Card)`
border-radius: 20px;
border: 1px solid #272266;
background-color: #141041;
 // border: 1px solid ${({ theme }) => theme.colors.bg2};
  :hover {
    //border: 1px solid ${({ theme }) => darken(0.06, theme.colors.bg6)};
    border: 1px solid #1f517d;
  }
`

interface PositionCardProps {
  pair: Pair
  showUnwrapped?: boolean
  border?: string
  pairData?: number
  active?: boolean
  handleToggle?: (index: any) => string
  faq?: []
}

export function MinimalPositionCard({ pair, showUnwrapped = false, border }: PositionCardProps) {
  const { account } = useActiveWeb3React()

  const currency0 = showUnwrapped ? pair.token0 : unwrappedToken(pair.token0)
  const currency1 = showUnwrapped ? pair.token1 : unwrappedToken(pair.token1)

  const [showMore, setShowMore] = useState(false)
  const [lf, setLF] = useState(true)

  const userPoolBalance = useTokenBalance(account ?? undefined, pair.liquidityToken)
  const totalPoolTokens = useTotalSupply(pair.liquidityToken)

  const [token0Deposited, token1Deposited] =
    !!pair &&
    !!totalPoolTokens &&
    !!userPoolBalance &&
    // this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
    JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? [
          pair.getLiquidityValue(pair.token0, totalPoolTokens, userPoolBalance, false),
          pair.getLiquidityValue(pair.token1, totalPoolTokens, userPoolBalance, false)
        ]
      : [undefined, undefined]

  function handleShow() {
    let slidebox: any = {
      style: ''
    }
    let innercard: any = {
      style: ''
    }

    slidebox = document.getElementById('sliderRight')
    innercard = document.getElementById('innercard')

    let cardwidth: any = slidebox?.style.width
    cardwidth = cardwidth?.split('px')
    console.log('width:::', cardwidth[0])
    if (Number(cardwidth[0]) === 300) {
      slidebox.style.width = '0px'
      innercard.style.visibility = 'hidden'
      setLF(false)
    } else {
      slidebox.style.width = '300px'
      innercard.style.visibility = 'visible'
      setLF(true)
    }
  }

  return (
    <>
      {userPoolBalance && (
        <LightCard id="sliderRight" border={border} style={{ width: '300px' }}>
          <AutoColumn gap="12px" id="innercard" style={{ padding: '15px' }}>
            <FixedHeightRow>
              <RowFixed>
                <Text fontWeight={500} fontSize={16}>
                  Your LP's Balance
                </Text>
              </RowFixed>
            </FixedHeightRow>
            <FixedHeightRow onClick={() => setShowMore(!showMore)}>
              <RowFixed>
                <DoubleCurrencyLogo currency0={currency0} currency1={currency1} margin={true} size={20} />
                <Text fontWeight={500} fontSize={20}>
                  {currency0.symbol}/{currency1.symbol}
                </Text>
              </RowFixed>
              <RowFixed>
                <Text fontWeight={500} fontSize={20}>
                  {userPoolBalance ? userPoolBalance.toSignificant(4) : '-'}
                </Text>
              </RowFixed>
            </FixedHeightRow>
            <AutoColumn gap="4px">
              <FixedHeightRow>
                <Text color="#fff" fontSize={16} fontWeight={500}>
                  {currency0.symbol}:
                </Text>
                {token0Deposited ? (
                  <RowFixed>
                    <Text color="#fff" fontSize={16} fontWeight={500} marginLeft={'6px'}>
                      {token0Deposited?.toSignificant(6)}
                    </Text>
                  </RowFixed>
                ) : (
                  '-'
                )}
              </FixedHeightRow>
              <FixedHeightRow>
                <Text color="#fff" fontSize={16} fontWeight={500}>
                  {currency1.symbol}:
                </Text>
                {token1Deposited ? (
                  <RowFixed>
                    <Text color="#fff" fontSize={16} fontWeight={500} marginLeft={'6px'}>
                      {token1Deposited?.toSignificant(6)}
                    </Text>
                  </RowFixed>
                ) : (
                  '-'
                )}
              </FixedHeightRow>
            </AutoColumn>
          </AutoColumn>
          <span
            style={{
              position: 'absolute',
              top: '45%',
              left: '-4%',
              cursor: 'pointer',
              background: '#5d0b52',
              borderRadius: '50%',
              width: '20px',
              height: '20px'
            }}
            onClick={() => handleShow()}
          >
            {lf ? <ChevronsRight color="white" size={20} /> : <ChevronsLeft color="white" size={20} />}
          </span>
        </LightCard>
      )}
    </>
  )
}

export default function FullPositionCard({ pair, border, pairData, active, handleToggle, faq }: PositionCardProps) {
  // const contentEl: any = useRef();
  // const { handleToggle, active, faq } = props;
  // const { id, token, apr, pair_logo, Liquidity, Volume, Fees } = faq;
  const { account } = useActiveWeb3React()

  const currency0 = unwrappedToken(pair.token0)
  const currency1 = unwrappedToken(pair.token1)

  // const [showMore, setShowMore] = useState(false)

  const userPoolBalance = useTokenBalance(account ?? undefined, pair.liquidityToken)
  const totalPoolTokens = useTotalSupply(pair.liquidityToken)

  const poolTokenPercentage =
    !!userPoolBalance && !!totalPoolTokens && JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? new Percent(userPoolBalance.raw, totalPoolTokens.raw)
      : undefined

  const [token0Deposited, token1Deposited] =
    !!pair &&
    !!totalPoolTokens &&
    !!userPoolBalance &&
    // this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
    JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? [
          pair.getLiquidityValue(pair.token0, totalPoolTokens, userPoolBalance, false),
          pair.getLiquidityValue(pair.token1, totalPoolTokens, userPoolBalance, false)
        ]
      : [undefined, undefined]

  // useEffect(()=>{
  //  pair?.map(()=>{

  //  })
  // },[])
  return (
    <>
      <div className="accordion accordion-flush" id="accordionFlushExample">
        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingOne">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#flush-collapse${pairData}`}
              aria-expanded="false"
              aria-controls={`flush-collapse${pairData}`}
            >
              <div className="row ms-1 me-1 pt-2 pb-2 text-head bg-head w-100">
                <div className="row col-11 align-items-center">
                  <div className="col-1 text-center">
                    <HiOutlineStar style={{ fontSize: '25px' }} />
                  </div>
                  <div className="col">
                    {' '}
                    <RowFixed>
                      <DoubleCurrencyLogo currency0={currency0} currency1={currency1} margin={true} size={20} />
                      <Text fontWeight={500} fontSize={20}>
                        {!currency0 || !currency1 ? <Dots>Loading</Dots> : `${currency0.symbol}/${currency1.symbol}`}
                      </Text>
                    </RowFixed>
                  </div>
                  <div className="col">
                    <div className="text-white fw-bold">{userPoolBalance ? userPoolBalance.toSignificant(4) : '-'}</div>
                  </div>
                  <div className="col">Volume</div>
                  <div className="col">{poolTokenPercentage ? poolTokenPercentage.toFixed(2) + '%' : '-'}</div>
                  {/* <div className="col">{Name}</div> */}
                </div>
                <div className="col-1 text-end">
                  <i className="fa fa-chevron-down rc-accordion-icon"></i>
                </div>
              </div>
            </button>
          </h2>
          <div
            id={`flush-collapse${pairData}`}
            className="accordion-collapse collapse"
            aria-labelledby="flush-headingOne"
            data-bs-parent="#accordionFlushExample"
          >
            <div className="accordion-body">
              {' '}
              <div className="rc-accordion-body">
                <div className="row align-items-center justify-content-center">
                  <div className="col-8">
                    <div className="row p-3 align-items-center">
                      <div className="col-4">
                        <div className="d-flex justify-content-start ms-3">
                          <div className="text-color">
                            <div style={{ color: '#000' }}>Your Liquidity</div>
                            <div className="text-black fw-bold">
                              {userPoolBalance ? userPoolBalance.toSignificant(4) : '-'}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-5">
                        <div className="d-flex justify-content-center">
                          <div className="text-color">
                            <div style={{ color: '#000' }}>Assets Pooled</div>
                            <div className="text-black fw-bold">
                              {token0Deposited ? (
                                <RowFixed>
                                  <Text fontSize={16} fontWeight={500} marginLeft={'6px'}>
                                    {token0Deposited?.toSignificant(6)}
                                  </Text>
                                  <CurrencyLogo size="20px" style={{ marginLeft: '8px' }} currency={currency0} />
                                </RowFixed>
                              ) : (
                                '-'
                              )}
                            </div>
                            <div className="fw-bold">
                              {token1Deposited ? (
                                <RowFixed>
                                  <Text style={{ color: '#111' }} fontSize={16} fontWeight={500} marginLeft={'6px'}>
                                    {token1Deposited?.toSignificant(6)}
                                  </Text>
                                  <CurrencyLogo size="20px" style={{ marginLeft: '8px' }} currency={currency1} />
                                </RowFixed>
                              ) : (
                                '-'
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="d-flex justify-content-end">
                          <div className="text-color">
                            <div style={{ color: '#000' }}>Your Pool Share</div>
                            <div className="text-black fw-bold">
                              {' '}
                              {poolTokenPercentage ? poolTokenPercentage.toFixed(2) + '%' : '-'}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="d-flex justify-content-center align-items-center mt-1 text-color">
                      <div className="text-end me-1">
                        <Link to={`/add/${currencyId(currency0)}/${currencyId(currency1)}`}>
                          <div
                            style={{ color: '#fff' }}
                            className="btn fw-bold frosted-glass-teal frosted-glass-teal clickable-filter-effect"
                          >
                            Add Liquidity
                          </div>
                        </Link>
                      </div>
                      {/* <Link to="/lp-farm"><div className="card-border p-2 m-1 d-flex align-items-center">
                    <AiOutlineLink
                        style={{ fontSize: "25px", cursor: "pointer" }}
                      />
                    </div>
                    </Link> */}
                      <Link to={`/remove/${currencyId(currency0)}/${currencyId(currency1)}`}>
                        <div className="card-border p-2 m-1  d-flex align-items-center">
                          <AiOutlineMinus style={{ fontSize: '25px', cursor: 'pointer' }} />
                        </div>
                      </Link>
                      <Link to="/swap">
                        <div className="card-border p-2 m-1 d-flex align-items-center">
                          <BiTransferAlt style={{ fontSize: '25px', cursor: 'pointer' }} />
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <HoverCard border={border}>
        <AutoColumn gap="12px" style={{ color: '#a0c3de', fontSize: '12px' }}>
          <FixedHeightRow onClick={() => setShowMore(!showMore)} style={{ cursor: 'pointer' }}>
            <RowFixed>
              <DoubleCurrencyLogo currency0={currency0} currency1={currency1} margin={true} size={20} />
              <Text fontWeight={500} fontSize={20}>
                {!currency0 || !currency1 ? <Dots>Loading</Dots> : `${currency0.symbol}/${currency1.symbol}`}
              </Text>
            </RowFixed>
            <RowFixed>
              {showMore ? (
                <ChevronUp size="20" style={{ marginLeft: '10px' }} />
              ) : (
                <ChevronDown size="20" style={{ marginLeft: '10px' }} />
              )}
            </RowFixed>
          </FixedHeightRow>
          {showMore && (
            <AutoColumn gap="8px">
              <FixedHeightRow>
                <RowFixed>
                  <Text fontSize={16} fontWeight={500}>
                    Pooled {currency0.symbol}:
                  </Text>
                </RowFixed>
                {token0Deposited ? (
                  <RowFixed>
                    <Text fontSize={16} fontWeight={500} marginLeft={'6px'}>
                      {token0Deposited?.toSignificant(6)}
                    </Text>
                    <CurrencyLogo size="20px" style={{ marginLeft: '8px' }} currency={currency0} />
                  </RowFixed>
                ) : (
                  '-'
                )}
              </FixedHeightRow>

              <FixedHeightRow>
                <RowFixed>
                  <Text fontSize={16} fontWeight={500}>
                    Pooled {currency1.symbol}:
                  </Text>
                </RowFixed>
                {token1Deposited ? (
                  <RowFixed>
                    <Text fontSize={16} fontWeight={500} marginLeft={'6px'}>
                      {token1Deposited?.toSignificant(6)}
                    </Text>
                    <CurrencyLogo size="20px" style={{ marginLeft: '8px' }} currency={currency1} />
                  </RowFixed>
                ) : (
                  '-'
                )}
              </FixedHeightRow>
              <FixedHeightRow>
                <Text fontSize={16} fontWeight={500}>
                  Your pool tokens:
                </Text>
                <Text fontSize={16} fontWeight={500}>
                  {userPoolBalance ? userPoolBalance.toSignificant(4) : '-'}
                </Text>
              </FixedHeightRow>
              <FixedHeightRow>
                <Text fontSize={16} fontWeight={500}>
                  Your pool share:
                </Text>
                <Text fontSize={16} fontWeight={500}>
                  {poolTokenPercentage ? poolTokenPercentage.toFixed(2) + '%' : '-'}
                </Text>
              </FixedHeightRow>

              <RowBetween marginTop="10px">
                <ButtonSecondary as={Link} to={`/add/${currencyId(currency0)}/${currencyId(currency1)}`} width="48%">
                  Add
                </ButtonSecondary>
                <ButtonSecondary as={Link} width="48%" to={`/remove/${currencyId(currency0)}/${currencyId(currency1)}`}>
                  Remove
                </ButtonSecondary>
              </RowBetween>
            </AutoColumn>
          )}
        </AutoColumn>
      </HoverCard> */}
    </>
  )
}
