import { Box, Container, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import { CiCircleInfo } from 'react-icons/ci'

const useStyles = makeStyles(theme => ({
  mainBox: {
    zIndex: '1',
    padding: '20px 0px 100px',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    alignItems: 'center',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      padding: '20px 0px 20px'
    },

    '& h4': {
      color: '#fff',
      textAlign: 'center'
    },
    '& h1': {
      color: '#fff',
      textAlign: 'start',
      fontSize: '74px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '34px'
      }
    },
    '& h6': {
      color: '#fff',
      fontSize: '14px',
      fontWeight: '500'
    },
    '& p': {
      color: '#fff',
      fontSize: '12px',
      fontWeight: '300',
      color: theme.palette.text.dull
    },
    '& h2': {
      color: '#fff',
      fontSize: '36px',
      fontWeight: '500',
      [theme.breakpoints.down('sm')]: {
        fontSize: '16px'
      }
    },
    '& h5': {
      color: '#fff',
      fontSize: '16px',
      fontWeight: '300',
      color: theme.palette.text.dull
    },
    '& h3': {
      color: '#fff',
      fontSize: '32px',
      color: '#fff'
    }
  },
  gridMainContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'row',
    height: '100%'
  },
  gridContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',
    width: '50%',
    height: '500px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 'auto'
    }
  },

  btnContainer: {
    display: 'flex',
    gap: '20px'
  },

  cardContainer: {
    width: '100%',
    border: '1px solid #fff',
    borderRadius: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '50px',
    marginTop: '20px',
    flexWrap: 'none',
    [theme.breakpoints.down('sm')]: {
      border: 'none',
      flexWrap: 'wrap',
      justifyContent: 'center',
      gap: '40px'
    }
  }
}))

const cardData = [
  {
    currency: 'USD',
    title: 'Total Trades',
    value: '----'
  },
  {
    currency: 'USD',
    title: 'Total Transactions',
    value: '----'
  },
  {
    currency: 'USD',
    title: 'Total Tokens Created',
    value: '----'
  },
  {
    currency: 'USD',
    title: 'Total Minted NFTs',
    value: '----'
  }
]

const Section2 = () => {
  const classes = useStyles()

  return (
    <div className={classes.mainBox}>
      <Container>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
          }}
        >
          <Typography variant="h2" style={{ marginBottom: '16px' }}>
            <span
              style={{
                color: '#4AFCFE'
              }}
            >
              Explore
            </span>{' '}
            The Numbers
          </Typography>
          <Typography variant="subtitle2" style={{ fontSize: '14px' }}>
            Dive into the latest market statistics and stay updated with real-time data. Our platform provides
            comprehensive insights to help you make informed decisions.
          </Typography>
          <Typography variant="subtitle2" style={{ fontSize: '14px', marginBottom: '20px' }}>
            Stay ahead of the market with our up-to-date analytics.
          </Typography>

          <Box className={classes.cardContainer}>
            {cardData.map((item, idx) => {
              return (
                <Box
                  key={idx}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    alignItems: 'center'
                  }}
                >
                  <Box style={{ display: 'flex', gap: 4 }}>
                    <Box
                      style={{
                        backgroundColor: '#4AFCFE',
                        borderTopRightRadius: '10px',
                        borderBottomLeftRadius: '10px',
                        padding: '10px 24px',

                        color: '#000'
                      }}
                    >
                      {item?.title}
                    </Box>
                    <Box
                      style={{
                        background: '#fff',
                        color: '#111',
                        height: '22px',
                        borderRadius: '8px',
                        textAlign: 'center',
                        padding: '0px 10px'
                      }}
                    >
                      <Typography variant="h6" style={{ color: '#111' }}>
                        {item?.currency}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: 6,
                      marginTop: '20px'
                    }}
                  >
                    <Typography variant="h3">${item?.value}</Typography>
                    <CiCircleInfo style={{ fontSize: '32px', color: '#fff' }} />
                  </Box>
                </Box>
              )
            })}
          </Box>
        </Box>
      </Container>
    </div>
  )
}

export default Section2
