import React from 'react'
import styled from 'styled-components'
import userlogo from '../../assets/images/user-logo-gray.png'
// import useHTPrice from '../../hooks/useHtPrice'
import useTokenBalance from '../../hooks/useTokenBalance'
import { getBalanceNumber } from '../../utils/formatBalance'
import { getPipiAddress } from '../../utils/addressHelpers'
import { useI18n } from 'i18n/i18n-react'
import { useWalletModalToggle } from '../../state/application/hooks'
import { useActiveWeb3React } from '../../hooks'
import TranslatedText from '../TranslatedText'
import { shortenAddress } from '../../utils'
import copyIcon from '../../assets/images/copy.png'
import { walletlink, bsc } from '../../connectors'
import toast from 'react-hot-toast'
// import { useHistory } from 'react-router-dom'

export const CONNECTOR_STORAGE_ID = 'CONNECTOR_STORAGE_ID'

const AccountButton: React.FC<{}> = () => {
  // const history = useHistory()
  const i18n = useI18n()
  // const { pippiPrice } = useHTPrice()
  const { account, connector } = useActiveWeb3React()
  const sushiBalance = useTokenBalance(getPipiAddress() as any)
  console.log(">>>>>>sushiBalance",sushiBalance);
  
  const toggleWalletModal = useWalletModalToggle()
  // const handleSignOutClick = useCallback(() => {
  //   window.localStorage.removeItem(CONNECTOR_STORAGE_ID)
  // }, [])
  // const copy = () => {
  //   const input = document.createElement('input')
  //   input.setAttribute('readonly', 'readonly')
  //   input.setAttribute('value', account || '')
  //   document.body.appendChild(input)
  //   input.setSelectionRange(0, 9999)
  //   if (document.execCommand('copy')) {
  //     document.execCommand('copy')
  //   }
  //   document.body.removeChild(input)
  // }
  return (
    <StyledAccountButton>
      {!account ? (
        <Button
          style={{
            background: '#4AFCFE',
            textTransform: 'none',
            padding: '8px 24px',
            boxShadow: '0 2px 2px 0 #4AFCFE, 0 2px 2px 0 #4AFCFE',
            color: '#000',
            borderRadius: '5px'
          }}
          onClick={toggleWalletModal}
        >
          <TranslatedText translationId={204}>Connect Wallet</TranslatedText>
        </Button>
      ) : (
        <AccountCn>
          <AccountInner onClick={toggleWalletModal}>
            <Account style={{ background: '#152232' }}>
              {/* <img src={require('../../assets/images/link.png')} alt="" className="link" /> */}
              <span className="text">{shortenAddress(account)}</span>
              <img src={userlogo} alt="" className="user-account" />
            </Account>
          </AccountInner>
          <Modal className="modal">
            {/* <Content>
              <div onClick={() => history.push('/profile')} style={{ cursor: 'pointer' }} className="title">
                {i18n(758, 'Profile')}
              </div>
            </Content> */}
            <Content>
              <div className="title">{i18n(758, 'Your wallet')}</div>
              <div className="subtitle">
                <span>{shortenAddress(account)}</span>

                <img
                  style={{ cursor: 'pointer' }}
                  src={copyIcon}
                  alt=""
                  onClick={() => {
                    navigator.clipboard.writeText(account)
                    toast.success('Copied')
                  }}
                />
              </div>
            </Content>
            <Content>
              <div className="title">
                <TranslatedText translationId={226}>Your Taral Balance</TranslatedText>
              </div>
              <div className="money">{getBalanceNumber(sushiBalance)}</div>
              {/* <div className="title usdt">=${getBalanceNumber(sushiBalance) * pippiPrice}</div> */}
              <Link href={`https://devnet.taralscan.com/address/${account}`} target="_blank">
                <span className="text-danger">
                  <TranslatedText translationId={250}> View on Explorer </TranslatedText>
                </span>
              </Link>
            </Content>
            <div className="flex">
              <ButtonCustom
                onClick={() => {
                  if (connector && walletlink && bsc) {
                    ;(connector as any).handleClose()
                  }
                }}
              >
                <TranslatedText translationId={252}>Sign out</TranslatedText>
              </ButtonCustom>
            </div>
          </Modal>
        </AccountCn>
      )}
    </StyledAccountButton>
  )
}
const Button = styled.div`
  align-items: center;
  background: ${props => props.theme.colors.primary};
  border-radius: 100px;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  height: 36px;
  font-weight: 700;
  justify-content: center;
  outline: none;
  padding: 10px;
  width: 100%;
  white-space: nowrap;
  &:hover {
    background-color: ${props => props.theme.colors.primary};
    box-shadow: 0px 4px 4px rgb(0 0 0 / 0.2);
  }
`
const Link = styled.a`
  display: block;
  margin-top: 20px;
  color: ${props => props.theme.colors.primary};
  font-size: 14px;
  line-height: 16px;
  font-weight: bolder;
  text-decoration: none;
`
const ButtonCustom = styled.div`
  margin: 12px 0;
  padding: 5px 12px;
  border: 1px solid #e53935;
  background: #ef5350;
  color: #fff;
  text-align: center;
  border-radius: 24px;
  cursor: pointer;
`
const Content = styled.div`
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid #453371;
  font-size: 14px;
  line-height: 24px;
  font-weight: bolder;
  color: #90afed;
  .title {
    color: #b3ddff;
  }
  .money {
    font-size: 16px;
  }
  .usdt {
    font-size: 12px;
  }
  .subtitle {
    display: flex;
    justify-content: space-between;
    img {
      height: 18px;
    }
  }
`
const AccountCn = styled.div`
  position: relative;
  width: 155px;
  height: 40px;
  &:hover {
    .modal {
      display: block;
    }
  }
`
const AccountInner = styled.div`
  position: absolute;
  height: 70px;
  left: 0;
  //top:8px;
  width: 100%;
`
const Account = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  border-radius: 100px;
  padding: 7px 12px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  justify-items: center;
  cursor: pointer;
  background: #000;
  color: #fff;
  .user-account {
    width: 25px;
  }
  .text {
    width: 95px;
  }
  .link {
    width: 15px;
  }
`
const StyledAccountButton = styled.div`
  button {
    :hover {
      background: ${props => props.theme.colors.primary};
    }
  }
  @media (max-width: 850px) {
    button {
      padding: 6px 10px;
      font-size: 12px;
      border-radius: 8px;
    }
  }
`
const Modal = styled.div`
  position: absolute;
  // right: 0;
  left: -145px;
  top: 56px;
  width: 300px;
  padding: 4px 12px 12px 12px;
  display: none;
  padding-left: 20px;
  padding-bottom: 0;
  box-sizing: border-box;
  background: #1b2c42;
  // background: linear-gradient(to right,#1b1d1f,#1b1d1f);
  box-shadow: 0px 4px 20px rgba(117, 117, 117, 0.1);
  border-radius: 12px;
  height: auto;
  .flex {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
`
export default AccountButton
