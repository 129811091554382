import { Currency, ETHER, JSBI, TokenAmount } from 'calculas-sdk'
import React, { useCallback, useEffect, useState } from 'react'
import { Box, Plus } from 'react-feather'
import { Text } from 'rebass'
import { ButtonDropdownLight } from '../../components/Button'
import { LightCard } from '../../components/Card'
import { AutoColumn, ColumnCenter } from '../../components/Column'
import CurrencyLogo from '../../components/CurrencyLogo'
import { FindPoolTabs } from '../../components/NavigationTabs'
import { MinimalPositionCard } from '../../components/PositionCard'
import Row from '../../components/Row'
import CurrencySearchModal from '../../components/SearchModal/CurrencySearchModal'
import { PairState, usePair } from '../../data/Reserves'
import { useActiveWeb3React } from '../../hooks'
import { usePairAdder } from '../../state/user/hooks'
import { useTokenBalance } from '../../state/wallet/hooks'
import { StyledInternalLink } from '../../components/Shared'
import { currencyId } from '../../utils/currencyId'
import AppBody from '../AppBody'
import { Dots } from '../Pool/styleds'
import TranslatedText from '../../components/TranslatedText'
import styled from 'styled-components'
import Header from 'components/Header'
import Footer from 'pages/HomePage1/footer/Footer'

enum Fields {
  TOKEN0 = 0,
  TOKEN1 = 1
}

export default function PoolFinder() {
  const { account } = useActiveWeb3React()

  const [showSearch, setShowSearch] = useState<boolean>(false)
  const [activeField, setActiveField] = useState<number>(Fields.TOKEN1)

  const [currency0, setCurrency0] = useState<Currency | null>(ETHER)
  const [currency1, setCurrency1] = useState<Currency | null>(null)
  console.log(">>>>>>>>>>currency0",currency0,currency1);
  

  const [pairState, pair] = usePair(currency0 ?? undefined, currency1 ?? undefined)
  console.log(">>>>>>>>>>>>>pair",pair);
  
  const addPair = usePairAdder()
  useEffect(() => {
    if (pair) {
      addPair(pair)
    }
  }, [pair, addPair])

  const validPairNoLiquidity: boolean =
    pairState === PairState.NOT_EXISTS ||
    Boolean(
      pairState === PairState.EXISTS &&
        pair &&
        JSBI.equal(pair.reserve0.raw, JSBI.BigInt(0)) &&
        JSBI.equal(pair.reserve1.raw, JSBI.BigInt(0))
    )

  const position: TokenAmount | undefined = useTokenBalance(account ?? undefined, pair?.liquidityToken)
  const hasPosition = Boolean(position && JSBI.greaterThan(position.raw, JSBI.BigInt(0)))

  const handleCurrencySelect = useCallback(
    (currency: Currency) => {
      if (activeField === Fields.TOKEN0) {
        setCurrency0(currency)
      } else {
        setCurrency1(currency)
      }
    },
    [activeField]
  )

  const handleSearchDismiss = useCallback(() => {
    setShowSearch(false)
  }, [setShowSearch])

  const prerequisiteMessage = (
    <LightCard padding="45px 10px">
      <Text textAlign="center">
        {!account ? 'Connect wallet to find pools' : 'Select a token to find your liquidity.'}
      </Text>
    </LightCard>
  )
  const BodyWrapper = styled.div`
    position: relative;
    width: 100vw;
    max-width: 420px;
    padding: 1rem;
    margin-top: 10px;
  `

  const HeaderWrapper = styled.div`
    ${({ theme }) => theme.flexRowNoWrap}
    // width: 100%;
justify-content: space-between;
  `
  const AppWrapper = styled.div`
    //  display: flex;
    //  flex-flow: column;
    //   align-items: flex-start;
    //  overflow-x: hidden;
    //  min-height: 100vh;
  `
  return (
    <AppWrapper id="mainwrapper">
      <HeaderWrapper>
        <Header />
      </HeaderWrapper>
      <div className="Main-div" style={{ position: 'relative', paddingBottom: '40px', height: 'calc(100vh)' }}>
        <Box style={{ position: 'absolute', right: '30px', top: '50px' }}>
          <img src="/images/greenishbg.png" style={{ width: '100%', maxHeight: '700px' }} />
        </Box>

        <Box
          style={{
            top: '10%',
            right: '0%',
            width: '314px',
            filter: 'blur(94px)',
            height: '314px',
            opacity: '40%',
            zIndex: '2',
            position: 'absolute',
            transform: 'rotate(-90deg)',
            background: '#0fd4f3',
            borderRadius: '1000px'
          }}
        ></Box>
        <Box
          style={{
            top: '60%',
            left: '-70px',
            right: '0%',
            width: '314px',
            filter: 'blur(94px)',
            height: '314px',
            opacity: '40%',
            zIndex: '2',
            position: 'absolute',
            transform: 'rotate(-90deg)',
            background: '#FED056',
            borderRadius: '1000px'
          }}
        ></Box>
        <div className="content-wrapper" style={{ paddingTop: '57px' }}>
          <div className="w-100 d-flex justify-content-center">
            <BodyWrapper id="bodywrapper">
              <AppBody>
                <FindPoolTabs />
                <AutoColumn gap="md">
                  <ButtonDropdownLight
                    onClick={() => {
                      setShowSearch(true)
                      setActiveField(Fields.TOKEN0)
                    }}
                  >
                    {currency0 ? (
                      <Row>
                        <CurrencyLogo currency={currency0} />
                        <Text fontWeight={500} fontSize={20} marginLeft={'12px'}>
                          {currency0.symbol}
                        </Text>
                      </Row>
                    ) : (
                      <Text fontWeight={500} fontSize={20} marginLeft={'12px'}>
                        <TranslatedText translationId={186}>Select a Token</TranslatedText>
                      </Text>
                    )}
                  </ButtonDropdownLight>

                  <ColumnCenter>
                    <Plus size="16" color="#888D9B" />
                  </ColumnCenter>

                  <ButtonDropdownLight
                    onClick={() => {
                      setShowSearch(true)
                      setActiveField(Fields.TOKEN1)
                    }}
                  >
                    {currency1 ? (
                      <Row>
                        <CurrencyLogo currency={currency1} />
                        <Text fontWeight={500} fontSize={20} marginLeft={'12px'}>
                          {currency1.symbol}
                        </Text>
                      </Row>
                    ) : (
                      <Text fontWeight={500} fontSize={20} marginLeft={'12px'}>
                        <TranslatedText translationId={186}>Select a Token</TranslatedText>
                      </Text>
                    )}
                  </ButtonDropdownLight>

                  {hasPosition && (
                    <ColumnCenter
                      style={{ justifyItems: 'center', backgroundColor: '', padding: '12px 0px', borderRadius: '12px' }}
                    >
                      <Text textAlign="center" color="white" fontWeight={500}>
                        Pool Found!
                      </Text>
                    </ColumnCenter>
                  )}

                  {currency0 && currency1 ? (
                    pairState === PairState.EXISTS ? (
                      hasPosition && pair ? (
                        <MinimalPositionCard pair={pair} border="1px solid #CED0D9" />
                      ) : (
                        <LightCard padding="45px 10px">
                          <AutoColumn gap="sm" justify="center">
                            <Text textAlign="center">You don’t have liquidity in this pool yet.</Text>
                            <StyledInternalLink
                              style={{ color: '#fff' }}
                              to={`/add/${currencyId(currency0)}/${currencyId(currency1)}`}
                            >
                              <Text textAlign="center">
                                <TranslatedText translationId={202}>Add Liquidity</TranslatedText>
                              </Text>
                            </StyledInternalLink>
                          </AutoColumn>
                        </LightCard>
                      )
                    ) : validPairNoLiquidity ? (
                      <LightCard padding="45px 10px">
                        <AutoColumn gap="sm" justify="center">
                          <Text textAlign="center">No pool found.</Text>
                          <StyledInternalLink to={`/add/${currencyId(currency0)}/${currencyId(currency1)}`}>
                            Create pool.
                          </StyledInternalLink>
                        </AutoColumn>
                      </LightCard>
                    ) : pairState === PairState.INVALID ? (
                      <LightCard padding="45px 10px">
                        <AutoColumn gap="sm" justify="center">
                          <Text textAlign="center" fontWeight={500}>
                            <TranslatedText translationId={208}>Invalid pair.</TranslatedText>
                          </Text>
                        </AutoColumn>
                      </LightCard>
                    ) : pairState === PairState.LOADING ? (
                      <LightCard padding="45px 10px">
                        <AutoColumn gap="sm" justify="center">
                          <Text textAlign="center">
                            Loading
                            <Dots />
                          </Text>
                        </AutoColumn>
                      </LightCard>
                    ) : null
                  ) : (
                    prerequisiteMessage
                  )}
                </AutoColumn>

                <CurrencySearchModal
                  isOpen={showSearch}
                  onCurrencySelect={handleCurrencySelect}
                  onDismiss={handleSearchDismiss}
                  showCommonBases
                  selectedCurrency={(activeField === Fields.TOKEN0 ? currency1 : currency0) ?? undefined}
                />
              </AppBody>
            </BodyWrapper>
          </div>
        </div>
      </div>
      <Footer />
    </AppWrapper>

    // <div className="w-100vw d-flex justify-content-center">
    //   <HeaderWrapper>
    //     <Header />
    //   </HeaderWrapper>

    // </div>
  )
}
