import React, { useState, useContext, useEffect } from 'react'
import { Grid, Box, Container, Typography, makeStyles, Button } from '@material-ui/core'
import { getWeb3Obj, getContract, getWeb3ContractObject, spinnerarray } from '../../../mint-utils'
import {
  contractKovan,
  ChainIDBinance,
  ChainIDDevNet,
  contractTaral,
  genrativeNFTSepolia,
  ChainIDEthereum,
  ChainIDPolygon,
  genrativeNFTPolygon
} from '../../../mint-constants'
import { UserContext } from '../../../context/User'
import axios from 'axios'
import ApiConfig from '../../../ApiConfig/ApiConfig'
import { useHistory } from 'react-router-dom'
import { useWeb3React } from '@web3-react/core'
import GenerativeNFTABI from '../../../mint-constants/ABI/GenerativeNFTABI'
import Web3 from 'web3'

import { RPC_URL } from '../../../mint-constants/index'
import ButtonCircularProgress from '../../../component/ButtonCircularProgress'
import toast from 'react-hot-toast'
import useTokenBalance from 'hooks/useTokenBalance'
import { getPipiAddress } from 'utils/addressHelpers'
import { getBalanceNumber } from 'utils/formatBalance'
import { borderRadius } from 'polished'

const useStyles = makeStyles(theme => ({
  bannerBox: {
    position: 'relative',
    // height: '100vh',
    marginTop: '110px',
    paddingBottom: '130px',

    [theme.breakpoints.down('md')]: {
      backgroundSize: '550px'
    },

    '& .shade': {
      top: '-8%',
      left: 'auto',
      right: '0',
      width: '600px',
      background: 'linear-gradient(162.34deg, rgba(0, 251, 251, 0.5) 57.54%, rgb(32 12 245) 85.06%)',
      filter: 'blur(200px)',
      bottom: 'auto',
      height: '600px',
      opacity: '0.55',
      zIndex: '-1',
      position: 'absolute',
      borderRadius: '1000px'
    },
    '& .shape7': {
      top: '0px',
      right: '0px',
      position: 'absolute',
      maxWidth: '1000px',
      opacity: '0.3'
    },

    '& .animationimage2': {
      position: 'absolute',
      top: '17%',
      left: '10%',
      [theme.breakpoints.down('md')]: {
        display: 'none'
      }
    },
    '& .animationimage1': {
      position: 'absolute',
      top: '9%',
      left: '5%',
      [theme.breakpoints.down('md')]: {
        display: 'none'
      }
    },
    '& .mintImg': {
      width: '100%',
      marginTop: '50px',
      maxWidth: '580px',
      [theme.breakpoints.down('sm')]: {
        marginTop: '10px'
      }
    }
  },

  gridflex: {
    display: 'flex',
    alignItems: 'center',
    height: '70vh',
    [theme.breakpoints.down('md')]: {
      height: 'auto'
    }
  },
  marginleft: {
    marginLeft: '10px !important'
  },
  textbox: {
    '& h1': {
      fontSize: '50px',
      fontWeight: '600',
      whiteSpace: 'pre',
      color: '#fff',
      position: 'relative',
      display: 'inline-block',
      textAlign: 'center',
      [theme.breakpoints.down('md')]: {
        fontSize: '40px',
        lineHeight: '40px'
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '25px',
        lineHeight: '40px'
      }
    },
    '& p': {
      color: '#fff',
      margin: '10px 0',
      fontSize: '22px',
      fontWeight: '300'
    },

    '& label': {
      fontSize: '23px',
      color: '#fff',
      fontWeight: '600',
      [theme.breakpoints.down('xs')]: {
        fontSize: '20px'
      }
    }
  },

  amount: {
    '& label': {
      color: '#fff',
      fontSize: '18px',
      marginBottom: '10px'
    }
  },
  amountdiv: {
    maxWidth: '100%',
    height: '60px',
    border: '1px solid #00ffab',
    borderRadius: ' 5px',
    display: 'flex',
    padding: '0 20px',
    alignItems: 'center',
    fontSize: '45px'
  },
  quantity: {
    color: '#fff !important',
    height: '39px',
    display: 'flex',
    padding: '5px 19px',
    fontSize: '14px !important',
    maxWidth: '100%',
    background: '#000',
    boxShadow: '0px 8px 15px rgb(0 0 0 / 15%)',
    borderRadius: '7px',
    alignItems: 'center',
    fontWeight: '500 !important',
    justifyContent: 'space-between',
    '& button': {
      fontSize: '20px',
      color: '#fff',
      padding: '1px 9px',
      minWidth: '35px',
      height: '35px',
      '&:first-child': {
        color: '#ffffff'
      },
      '&:last-child': {
        color: '#ffffff'
      },
      '&:hover': {
        backgroundColor: 'rgb(34 167 240)'
      }
    },
    '& input': {
      textAlign: 'center',
      backgroundColor: 'transparent',
      color: '#fff',
      border: 'none',
      fontSize: '25px',
      fontWeight: '900',
      width: '30%',
      '&:focus-visible': {
        outline: 'none'
      },
      '&::placeholder': {
        color: '#fff'
      }
    }
  },
  textInput: {
    width: '100%',
    height: '48px',
    backgroundColor: '#3e3e3e',
    border: 'none',
    borderRadius: '7px',
    paddingLeft: ' 10px',
    color: '#fff',
    '&::placeholder': {
      color: '#fff'
    },
    '&:focus-visible': {
      outline: ' none'
    }
  },
  image2: {
    marginLeft: '-20px !important',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '0px !important'
    }
  },
  buttonright: {
    marginLeft: '10px !important',
    minWidth: '150px'
  },
  minth1: {
    fontSize: '80px !important',
    fontWeight: '100px !important',
    lineHeight: '80px'
  },
  imgBox: {
    '& img': {
      position: 'absolute',
      left: '0',
      zIndex: '-1'
    }
  },
  newgrid: {
    marginTop: '16px'
  },
  quantitybox: {
    marginTop: '30px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '20px'
    }
  },

  bannerImg: {
    display: 'flex',
    position: 'relative',
    // overflow: " hidden",
    '& .shape1': {
      position: 'absolute',
      top: '-90px',
      left: '-90px',
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      }
    },
    '& .shape2': {
      position: 'absolute',
      top: '-99px',
      /* left: 434px; */
      right: '279px',
      [theme.breakpoints.down('md')]: {
        display: 'none'
      },
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      }
    },
    '& .shape3': {
      position: 'absolute',
      left: '181px',
      top: '-119px',
      [theme.breakpoints.down('md')]: {
        display: 'none'
      },
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      }
    },
    '& .shape4': {
      position: 'absolute',
      bottom: '20px',
      right: '40px',
      [theme.breakpoints.down('md')]: {
        display: 'none'
      }
    },
    '& .shape5': {
      position: 'absolute',
      bottom: '100px',
      left: '-90px',
      [theme.breakpoints.down('md')]: {
        display: 'none'
      },
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      }
    },
    '& .shape6': {
      position: 'absolute',
      top: '-20px',
      right: '-15px',
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      }
    },

    '& .shape7': {
      position: 'absolute',
      /* left: 21px; */
      top: '246px',
      [theme.breakpoints.down('md')]: {
        display: 'none'
      },
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      }
    },
    '& .shape8': {
      position: 'absolute',
      /* left: 21px; */
      top: '223px',
      left: '56px',
      [theme.breakpoints.down('md')]: {
        display: 'none'
      },
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      }
    },
    '& figure': {
      margin: '0',
      width: '100%',
      overflow: 'hidden',
      marginBottom: '15px',
      borderRadius: '10px',
      height: 'auto',
      '&:hover': {
        '& img': {
          transform: 'scale(1.3)'
        }
      },
      '& img': {
        width: '100%',
        height: '100%',
        margin: '0',
        transform: 'scale(1.1)',
        transition: '0.5s'
      }
    }
  },

  mintedBox: {
    background: 'rgba(166, 162, 162, 0.2)',
    border: '1.5px solid rgba(255, 255, 255, 0.1)',
    boxShadow: '0px 8px 15px rgb(0 0 0 / 15%)',
    borderRadius: '10px',
    padding: '10px'
  },

  marginsec: {
    marginTop: '5px'
  },
  btnText: {
    fontSize: '20px !important',
    position: 'relative',
    zIndex: '1111',
    background: '#4afcfe',
    borderRadius: '10px'
  },

  parentWheel: {
    '& .wheelSpin11': {
      position: 'relative',
      borderRadius: '20px',
      overflow: 'hidden',
      height: '604px'
    },
    '& .spinwheel': {
      width: '450px',
      position: 'absolute',
      left: '50%',
      top: '50%',
      maxWidth: '100%',
      transform: 'translate(-50%, -50%)',
      '& img': {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)'
      }
    },
    '& #line1': {
      transition: 'all 4s ease-in-out'
    }
  }
}))

export default function BestSeller() {
  const sushiBalance = useTokenBalance(getPipiAddress())
  console.log('>>>>>>>>>>checkingbal', getBalanceNumber(sushiBalance))
  const classes = useStyles()
  const history = useHistory()
  const [userBalance, setUserBalance] = useState(0)
  const [mouseOver, setMouseOver] = useState(false)
  const [amount, setAmount] = useState(0)
  const [nftPrice, setNftPrice] = useState(0)
  const [listcategoryforid, setlistCategoryforid] = useState('')

  const [numberofnft, setNumberofnft] = useState(1)
  const user = useContext(UserContext)
  const [isLoadingAuth, setIsLoadingAuth] = useState(false)
  const [spinPoints, setSpinPoints] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const { account, library, chainId } = useWeb3React()
  const [saleisActive, setSaleisActive] = useState()
  const [statusdata, setstatusdata] = useState()
  const [balanceValue, setBalanceValue] = React.useState()
  const httpProvider = new Web3.providers.HttpProvider(RPC_URL)
  const web3 = new Web3(httpProvider)
  const [whiteliststatus, setWhiteliststatus] = useState(false)
  const [mintbuttonstatus, setmintbuttonstatus] = useState(true)
  const [statusdatasetvalue, setstatusdatasetvalue] = useState(false)
  const [previouswalletAddress, setpreviouswalletAddress] = useState()
  const spinnerstatusdata = window.localStorage.getItem('datastatus')
  const walletaddressset = window.localStorage.getItem('walletaddressdata')

  const GetBalance = async () => {
    try {
      console.log('>>>>>>account', account)
      const value = await web3.eth.getBalance(account)

      setBalanceValue(web3.utils.fromWei(value))
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    GetBalance()
    setAmount(numberofnft * nftPrice)
    setIsLoadingAuth(user?.isLoadingData)
  }, [numberofnft, nftPrice, user.isLoadingData, account])

  const getContractDetailsHandler = async () => {
    try {
      // setIsLoadingData(true);
      const web3 = await getWeb3Obj()
      const contractObj = await getWeb3ContractObject(
        GenerativeNFTABI,
        chainId === ChainIDDevNet ? contractTaral : chainId === ChainIDEthereum ? genrativeNFTSepolia : contractKovan,
        chainId
      )

      const NFT_PRICEE = await contractObj.methods.NFT_PRICE().call()
      const getNFTPrice = await web3.utils.fromWei(NFT_PRICEE.toString())
      setNftPrice(getNFTPrice)
    } catch (err) {
      console.log(err)
      // setIsLoadingData(false);
    }
  }

  const saleIsActivenft = async () => {
    console.log('>>>>>chainId', chainId)
    try {
      const web3 = await getWeb3Obj()
      const contractObj = await getWeb3ContractObject(
        GenerativeNFTABI,
        Number(chainId) === Number(ChainIDDevNet)
          ? contractTaral
          : chainId === ChainIDEthereum
          ? genrativeNFTSepolia
          : contractKovan,
        chainId
      )
      // if (hasFinalSaleStarted) {
      const saleIsActive = await contractObj.methods.saleIsActive().call()
      console.log('>>>>>>saleIsActive', saleIsActive)
      setSaleisActive(saleIsActive)
    } catch (err) {
      console.log(err)
      // setIsLoadingData(false);
    }
  }
  useEffect(() => {
    if (chainId) {
      saleIsActivenft()
      getContractDetailsHandler()
    }
  }, [account, chainId])

  const listCategoryapi = async () => {
    try {
      const res = await axios({
        method: 'Get',
        url: ApiConfig.listCategory
      })
      if (res.data.statusCode == 200) {
        if (res.data.result.docs) {
          const resultdata = res.data.result.docs.filter(data => {
            return data?.isSubCategory !== false
          })
          listsubCategoryApi(resultdata[0]?._id)
          // setlistCategoryforid();
          // console.log("setlistCategoryforid++", resultdata[0]?._id);
        }

        // setSelectCData(resultdata[0]?.categoryTitle);
      }
    } catch (error) {
      console.log('ERROR', error)
    }
  }
  useEffect(() => {
    listCategoryapi()
  }, [])

  const listsubCategoryApi = async data => {
    try {
      const res = await axios({
        method: 'GET',
        url: ApiConfig.listSubCategory,
        params: {
          categoryId: data?._id
        }
      })
      if (res.data.statusCode === 200) {
        console.log('res.data.result.docs', res.data.result.docs[0]?._id)
        setlistCategoryforid(res.data.result.docs[0]?._id)
      }
    } catch (error) {
      console.log(error)
    }
  }

  //Mint
  console.log(
    '>>>>>>>ACTIVE_NETWORK',
    balanceValue,
    amount,
    parseFloat(getBalanceNumber(sushiBalance)) < parseFloat(amount)
  )

  const mintNFT = async () => {
    try {
      if (
        chainId !== ChainIDPolygon &&
        chainId !== ChainIDBinance &&
        chainId !== ChainIDDevNet &&
        chainId !== ChainIDEthereum
      ) {
        // switchNetworkHandler();
        setIsLoading(false)
        return
      }

      if (!saleisActive) {
        toast.error('Sale must be active to mint NFTs')
        return
      }

      if (statusdatasetvalue || parseFloat(balanceValue) < parseFloat(amount)) {
        toast.error('Insufficient funds to perform this action')
        return
      }

      // if (
      //   Number(user?.totalSupply) + Number(numberofnft) >
      //   Number(user?.MAX_NFT_SUPPLY)
      // ) {
      //   toast.error("Purchase would exceed max supply of NFTs");
      //   return;
      // }

      if (!account || !numberofnft || numberofnft === '') {
        toast.error('Please select correct data')
        setIsLoading(false)
        return
      }
      setIsLoading(true)
      const web3 = await getWeb3Obj()
      const contract = getContract(
        chainId === ChainIDPolygon
          ? genrativeNFTPolygon
          : chainId === ChainIDDevNet
          ? contractTaral
          : chainId === ChainIDEthereum
          ? genrativeNFTSepolia
          : contractKovan,
        GenerativeNFTABI,
        library,
        account
      )
      const totalSupply = await contract.totalSupply()
      const totalSupplyConv = parseInt(totalSupply.toString())
      const amount1 = parseFloat(amount).toFixed(18)
      const nftprice = statusdatasetvalue === true ? '' : web3.utils.toWei(amount1.toString()).toString()
      console.log('>>>>>>amountchecking', amount, amount1, nftprice)
      const tx = await contract.mintNFT(numberofnft, {
        value: nftprice
      })
      await tx.wait()
      let mintCount = 1
      for (let i = totalSupplyConv; i < totalSupplyConv + Number(numberofnft); i++) {
        try {
          console.log('>>>>>>i>>>>', i)
          // const tokenURI = await contract.tokenURI(i);
          const tokenURI = await contract.baseURI()
          console.log('>>>>>>>>>>>>>>tokenURI', tokenURI)
          const response = await axios.get(tokenURI)
          console.log('>>>>>>>>>>response', response)

          if (response.status === 200 && response.data.name !== '') {
            // const dataResult = await response.json();
            // console.log(">>>>>>dataResult", dataResult);
            const res = await axios({
              method: 'POST',
              url: ApiConfig.createNFT,
              headers: {
                token: window.sessionStorage.getItem('token')
              },
              data: {
                currentOwnerId: user?.userData?._id,
                collectionId: user?.generativeid,
                tokenId: i.toString(),
                tokenName: `${response?.data?.name} ${Number(response?.data?.attributes[0]?.value) + Number(i)}`,
                title: `${response?.data?.name} ${Number(response?.data?.attributes[0]?.value) + Number(i)}`,
                mediaFile: response?.data?.image,
                coverImage: response?.data?.image,
                uri: response?.data?.image,
                network: chainId.toString(),
                description: '10,000 of the largest cities in the world that will be used in a P2E Metaverse game.',
                properties: 'String',
                subCategoryId: listcategoryforid,
                alternativeTextForNFT: 'String',
                mediaType: 'image',
                royalties: '0',
                recipientWalletAddress: 'String',
                recipientBackupWalletAddress: 'String'
              }
            })

            if (res.data.statusCode === 200) {
              console.log('>>>>>>>checking>>>', res.data)
              toast.success(`${mintCount} of ${numberofnft} minted`)
              window.localStorage.removeItem('datastatus')
            } else {
              toast.error(`${mintCount} of ${numberofnft} not minted`)
            }
          } else {
            throw new Error('Failed to fetch token URI')
          }

          mintCount++
        } catch (error) {
          toast.error(`${mintCount} of ${numberofnft} not minted`)
          toast.error(error.message)
          console.log('ERROR>>>>>>>>>>>>>>123', error)
        }
      }
    } catch (error) {
      toast.error(error.message)
      console.log('ERROR', error)
    } finally {
      setIsLoading(false)
    }
  }

  const getContractBalance = async () => {
    const web3 = await getWeb3Obj()
    const bal = await web3.eth.getBalance(
      chainId === ChainIDDevNet ? contractTaral : chainId === ChainIDEthereum ? genrativeNFTSepolia : contractKovan
    )
    let balance = await web3.utils.fromWei(bal)

    setUserBalance(balance)
  }

  useEffect(() => {
    getContractBalance()
  }, [])

  function moveWheel() {
    let line1 = document.getElementById('line1')
    if (line1) {
      const finalData = 360 + spinPoints
      line1.style.transform = 'translate(-50%, -50%) rotate(' + finalData + 'deg)'
    }
  }

  useEffect(() => {
    moveWheel()
  }, [spinPoints])

  const generateRandomPointsHandler = async e => {
    try {
      const res = await axios({
        method: 'GET',
        url: ApiConfig.spinWinner,
        headers: {
          token: window.sessionStorage.getItem('token')
        }
      })
      if (res.data.statusCode === 200) {
        toast("Please don't refresh")
        const finddata = spinnerarray.find(data => {
          return data === res.data.result.position
        })
        setSpinPoints(finddata * 13.33)
        user.connectWalletHandler(account)
        setTimeout(() => {
          window.localStorage.setItem('datastatus', res.data.result.winner)
          if (res.data.result.winner === true) {
            setstatusdatasetvalue(true)
            window.localStorage.setItem('walletaddressdata', previouswalletAddress)
          } else {
            setstatusdatasetvalue(false)
            window.localStorage.removeItem('datastatus')
          }
          setstatusdata(res.data.result.winner)
          setmintbuttonstatus(false)
          setWhiteliststatus(false)
        }, 5500)
        setTimeout(() => {
          setstatusdata()
        }, 15000)
      } else {
        toast.error(res.data.responseMessage)
      }
    } catch (error) {
      toast.error(error.response.data.responseMessage)
    }
  }
  const whitelistuser = async () => {
    try {
      const url = ApiConfig.userwhitelist
      const res = await axios.get(url, {})
      if (res?.data?.statusCode === 200) {
        const filterdata = res?.data?.result?.filter(data => {
          return data?.walletAddress === account ? true : false
        })
        console.log('skfkjsd', filterdata[0]?.walletAddress)
        setpreviouswalletAddress(filterdata[0]?.walletAddress)
        if (filterdata.length != 0) {
          // toast.success("success");
          setWhiteliststatus(true)
          window.localStorage.setItem('whiteliststatusdata', true)
        } else {
          // toast.error("error");
          setmintbuttonstatus(false)
          setstatusdatasetvalue(false)
        }
      }
    } catch {}
  }
  useEffect(() => {
    if (account) {
      whitelistuser()
    }
  }, [account])

  // useEffect(() => {
  //   if (user?.checkspin) {
  //     setmintbuttonstatus(false);
  //   }
  // }, [user?.checkspin]);

  // useEffect(() => {
  //   if (account) {
  //     user.connectWalletHandler(account);
  //   }
  // }, [account]);
  useEffect(() => {
    console.log('ksdfksjd', spinnerstatusdata)
    if (spinnerstatusdata) {
      setstatusdatasetvalue(true)
      // toast.success("local success");
    } else if (!spinnerstatusdata) {
      setstatusdatasetvalue(false)
      // toast.error("local error");
    }
  }, [spinnerstatusdata])

  useEffect(() => {
    if (account) {
      setstatusdatasetvalue(false)
    }
  }, [account])
  console.log('>>>>>>user?.MAX_NFT_SUPPLY', user?.MAX_NFT_SUPPLY)
  return (
    <Box className={classes.bannerBox}>
      {/* <img src="images/landing/right.png" className="shape7 " /> */}
      {/* <img
        src="images/Mint/leftimg_1.png"
        alt="image"
        className="animationimage2 moveLeft"
      /> */}
      {/* <img
        src="images/Mint/leftimg.png"
        alt="image"
        className="animationimage1 moveTop"
      /> */}
      <Box className="shade"></Box>
      <Box mt={5}>
        <Container>
          <Grid container spacing={3} alignItems="center" style={{ position: 'relative', zIndex: '2' }}>
            <Grid item xs={12} md={4} className={classes.gridflex}>
              <Box className={classes.textbox}>
                <Typography variant="h1">
                  Spin & Mint {''}
                  <span style={{ color: '#4afcfe' }}>Free NFT</span>
                </Typography>
                <Typography variant="body1">{`${user?.totalSupply} OF ${user?.MAX_NFT_SUPPLY} MINTED`}</Typography>
                <Box>
                  {' '}
                  <label>Quantity</label>
                </Box>
                <Box className={classes.mintedBox}>
                  <Box className={classes.quantity} mt={1}>
                    <Button
                      variant="outline"
                      color="primary"
                      size="small"
                      onClick={() => {
                        if (numberofnft > 1) {
                          setNumberofnft(numberofnft - 1)
                        }
                      }}
                      disabled={isLoading}
                    >
                      -
                    </Button>

                    <input
                      name="number"
                      maxlength="2"
                      onKeyPress={event => {
                        if (event?.key === '-' || event?.key === '+') {
                          event.preventDefault()
                        }
                      }}
                      // placeholder={numberofnft}
                      className="webkitcss"
                      value={numberofnft}
                      onChange={e => {
                        setNumberofnft(e.target.value)
                      }}
                    />
                    <Button
                      variant="outline"
                      color="secondary"
                      size="small"
                      onClick={() => {
                        if (Number(numberofnft) < 10) {
                          setNumberofnft(Number(numberofnft) + 1)
                        }
                      }}
                      disabled={isLoading}
                    >
                      +
                    </Button>
                  </Box>

                  <Grid container>
                    <Grid item xs={6} sm={6} className={classes.newgrid}>
                      <label style={{ paddingRight: '10px', fontSize: '14px' }}>Total price:</label>
                    </Grid>
                    <Grid item xs={6} sm={6} className={classes.newgrid} align="right">
                      <label
                        style={{
                          color: '#fff',
                          fontSize: '16px'
                        }}
                      >
                        {amount ? amount.toFixed(7) : '0'}{' '}
                        {chainId === ChainIDEthereum ? 'ETH' : chainId === ChainIDBinance ? 'BNB' : 'TARAL'}
                      </label>
                    </Grid>
                  </Grid>
                  {!account ? (
                    <Box mt={2}>
                      {/* <Button
                        variant="contained"
                        size="large"
                        color="secondary"
                        fullWidth
                        to="/conect-wallet"
                        component={Link}
                      >
                        Connect Wallet
                      </Button> */}
                    </Box>
                  ) : (
                    <>
                      {' '}
                      {chainId === ChainIDPolygon ||
                      chainId === ChainIDBinance ||
                      chainId === ChainIDDevNet ||
                      chainId === ChainIDEthereum ? (
                        <Box mt={2}>
                          {console.log('lsjkfsldf', statusdatasetvalue)}
                          <Button
                            variant="contained"
                            size="large"
                            color="secondary"
                            fullWidth
                            onClick={() => {
                              if (account) {
                                mintNFT(statusdatasetvalue)
                              } else {
                                toast('Please connect your wallet')
                              }
                            }}
                            disabled={isLoading || !statusdatasetvalue}
                          >
                            {statusdatasetvalue === true ? 'Free Mint' : 'No Mint'}

                            {isLoading && <ButtonCircularProgress />}
                          </Button>
                          <Box className={classes.marginsec}>
                            {whiteliststatus && (
                              <Typography variant="h6" style={{ color: '#fff' }}>
                                {user?.checkspin ? '' : <>Please test your luck by spinning the fortune wheel</>}
                              </Typography>
                            )}
                            <Typography variant="h6" style={{ color: '#fff' }}>
                              {statusdata === true ? (
                                <Box style={{ color: 'green' }}> Whooa! You won a free mint chance. Avail now!</Box>
                              ) : statusdata === false ? (
                                <Box style={{ color: 'red' }}>Oops! You lost chance</Box>
                              ) : (
                                ''
                              )}
                            </Typography>
                          </Box>
                        </Box>
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          fullWidth
                          mb={2}
                          onClick={() => {
                            user.setSelectNetwork(parseInt(ChainIDBinance))
                          }}
                        >
                          Switch Network
                        </Button>
                      )}
                    </>
                  )}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={1} align="center"></Grid>
            <Grid className={classes.parentWheel} item xs={12} md={7} align="center">
              <Box className="wheelSpin11" align="center">
                <Box className="spinwheel" mb={3}>
                  <img src="images/spin/round.png" alt=" " />
                  <img src="images/spin/finalimage.png" alt=" " id="line1" />
                  <img
                    src="images/spin/centerbtn.png"
                    alt=" "
                    // id="line1"
                  />
                  <img
                    id="line1"
                    src="images/spin/play.png"
                    alt=" "
                    style={{ marginTop: ' -37%', marginLeft: '25%' }}
                    // id="line1"
                  />
                </Box>
              </Box>
              <Button
                id="start-btn"
                variant="contained"
                style={{ height: '50px', color: '#000' }}
                // color="primary"
                className={classes.btnText}
              >
                Comming Soon!
              </Button>
              {/* {!account ? (
                <Button
                  id="start-btn"
                  variant="contained"
                  style={{ height: '50px' }}
                  color="primary"
                  className={classes.btnText}
                  onMouseOver={() => {
                    if (!account) {
                      setMouseOver(true)
                    }
                  }}
                  onMouseOut={() => {
                    setMouseOver(false)
                  }}
                >
                  
                  {mouseOver ? 'Please connect your wallet.' : 'Play'}
                </Button>
              ) : (
                <Button
                  id="start-btn"
                  variant="contained"
                  style={{ width: '200px', height: '50px' }}
                  color="primary"
                  className={classes.btnText}
                  onClick={generateRandomPointsHandler}
                  disabled={user?.checkspin || statusdatasetvalue || !account}
                  onMouseOver={() => {
                    if (!account) {
                      setMouseOver(true)
                    }
                  }}
                >
                  
                  Play
                </Button>
              )} */}

              <Typography></Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  )
}
