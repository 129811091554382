import React from 'react'
import { Card, CardContent, CardMedia, Typography, Button, Grid, makeStyles, Box } from '@material-ui/core'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#153c4a',
    color: 'white',
    margin: '16px',
    width: '291px',
    borderRadius: '20px',
    border: '1px solid #256477',

    [theme.breakpoints.down('sm')]: {
      width: '208px'
    },
    [theme.breakpoints.down('xs')]: {
      width: '95%'
    }
  },
  media: {
    height: 140,
    borderRadius: 10,
    margin: '0px 10px 10px 10px'
  },
  content: {
    backgroundColor: '#153c4a',
    paddingTop: '40px',
    minHeight: '200px'
  },
  content1: {
    backgroundColor: '#153c4a',
    padding: '5px 16px'
  },
  button: {
    color: '#4AFCFE',
    borderColor: 'white',
    border: 'none'
  },
  gridContainer: {
    display: 'flex',
    justifyContent: 'center'
  }
}))

const cardsData = [
  {
    title: 'Swap',
    description:
      "Ape into new tokens with our lightning-fast swap feature. We ensure you always get the best bang for your buck. Say goodbye to high fees and slippage – we've got you covered with the most optimized routes across multiple liquidity pools.",
    image: 'new-module/card1.png'
  },
  {
    title: 'Liquidity',
    description:
      "Become a liquidity provider and start stacking those rewards. Add your tokens to our liquidity pools and earn a slice of the trading fees. It's like yield farming, but easier!",
    image: 'new-module/blockchain1.png' // replace with your image URL
  },
  {
    title: 'Create Token',
    description:
      "Feeling bullish about your project? Launch your token on the Taral Chain with just a few clicks. No coding skills are required – we've made it easier than ever to mint your cryptocurrency. Who knows, maybe you'll create the next moonshot!",
    image: 'new-module/blockchain2.png' // replace with your image URL
  },
  {
    title: 'Explorer',
    description:
      "DYOR is like a pro with our built-in explorer. Track transactions, check wallet balances, and keep an eye on the latest on-chain activities. It's like having a blockchain magnifying glass right at your fingertips.",
    image: 'new-module/blockchain3.png' // replace with your image URL
  },
  {
    title: 'Free NFT',
    description:
      "Wen NFT? Right now! Grab yourself a free NFT and join the digital collectibles craze. These aren't just pictures – they're your ticket to exclusive perks in the Tarality ecosystem. Don't sleep on this opportunity to get in early!",
    image: 'new-module/blockchain2.png' // replace with your image URL
  },
  {
    title: 'Faucet',
    description:
      "Need a little gas to get started? Hit up our faucet for some free TARAL coins. It's a little, but it's enough to help you make your first swap or provide liquidity. Consider it our way of onboarding you to the wonderful world of Web3.",
    image: 'new-module/blockchain1.png' // replace with your image URL
  },
  {
    title: 'Games',
    description:
      'Participate and play Games in our exciting ecosystem and collect tokens with the stand a chance to win big!',
    image: 'new-module/blockchain1.png' // replace with your image URL
  }
]
let settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
}

export default function BlockchainCard() {
  const classes = useStyles()

  return (
    <Grid container className={classes.gridContainer}>
      <Box className="slider-container" style={{ width: '100%' }}>
        <Slider {...settings}>
          {cardsData.map((card, index) => (
            <Grid item key={index} xs={12} sm={6} md={3}>
              <Card className={classes.root}>
                <CardContent className={classes.content}>
                  <Typography gutterBottom variant="h5" component="h2">
                    {card.title}
                  </Typography>
                  <Typography variant="body2" color="inherit" component="p">
                    {card.description}
                  </Typography>
                </CardContent>
                {/* <CardContent className={classes.content1}>
                  <Button className={classes.button}>See More...</Button>
                </CardContent> */}
                <CardMedia fi className={classes.media} image={card.image} title={card.title} />
              </Card>
            </Grid>
          ))}
        </Slider>
      </Box>
    </Grid>
  )
}
