import { NetworkDetails, RPC_URL, RPC_URL_ETH, RPC_URL_POLYGON, RPC_URL_TARAL } from '../mint-constants'
import { Contract } from '@ethersproject/contracts'
import Web3 from 'web3'
import toast from 'react-hot-toast'

// export function sortAddress(add) {
//   const sortAdd = `${add.slice(0, 6)}...${add.slice(add.length - 4)}`;
//   return sortAdd;
// }
export function sortAddress(add) {
  if (add) {
    const sortAdd = `${add.slice(0, 6)}...${add.slice(add.length - 4)}`
    return sortAdd
  } else return add
}
// export function sortAddressamount(add) {
//   if (add) {
//     const sortAdd = `${add?.slice(add.length - 10)}`;
//     return sortAdd;
//   } else return add;
// }
export const deadAddress = '0x0000000000000000000000000000000000000000'

export function firstAddress(add) {
  if (add) {
    const sortAdd = `${add.slice(0, 6)}...`
    return sortAdd
  } else return add
}

export function getSigner(library, account) {
  return library.getSigner(account).connectUnchecked()
}

export function getProviderOrSigner(library, account) {
  return account ? getSigner(library, account) : library
}

export function getContract(address, ABI, library, account) {
  return new Contract(address, ABI, getProviderOrSigner(library, account))
}

export const getWeb3Provider = async () => {
  const httpProvider = new Web3.providers.HttpProvider(RPC_URL)
  return httpProvider
}

export const getWeb3Obj = async () => {
  const httpProvider = new Web3.providers.HttpProvider(RPC_URL)
  const web3 = await new Web3(httpProvider)
  return web3
}
export const getTaralWeb3Obj = async () => {
  const httpProvider = new Web3.providers.HttpProvider(RPC_URL_TARAL)
  const web3 = await new Web3(httpProvider)
  return web3
}
export const getPolygonWeb3Obj = async () => {
  const httpProvider = new Web3.providers.HttpProvider(RPC_URL_POLYGON)
  const web3 = await new Web3(httpProvider)
  return web3
}
export const getWeb3ObjETH = async () => {
  const httpProvider = new Web3.providers.HttpProvider(RPC_URL_ETH)
  const web3 = await new Web3(httpProvider)
  return web3
}

export const getWeb3ContractObject = async (abi, contractAddress, network) => {
  let web3
  if (Number(network) === 80002) {
    const web = await getPolygonWeb3Obj()

    web3 = web
  } else if (Number(network) === 4442) {
    const web = await getTaralWeb3Obj()

    web3 = web
  } else if (Number(network) === 97) {
    const web = await getWeb3Obj()
    web3 = web
  } else {
    const web = await getWeb3ObjETH()
    console.log('>>>>>web1', web)
    web3 = web
  }
  const contract = await new web3.eth.Contract(abi, contractAddress)

  return contract
}

export const getBalanceOf = async (abi, address, account, chainId) => {
  try {
    const contract = await getWeb3ContractObject(abi, address, chainId)
    const balanceOf = await contract.methods.balanceOf(account).call()

    return balanceOf.toString()
  } catch (error) {
    console.log('ERROR', error)

    return 0
  }
}

export const calculateTimeLeft = endDate => {
  if (endDate) {
    let difference = +new Date(endDate) - +new Date()
    let timeLeft = {}

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      }
    }
    return timeLeft
  } else {
    return false
  }
}

export function copyTextByID(id) {
  var copyText = document.getElementById(id)
  copyText.select()
  copyText.setSelectionRange(0, 99999) /* For mobile devices */
  navigator.clipboard.writeText(copyText.value)
  toast.info(`Copied ${copyText.value}`)
}

export const getBase64 = (file, cb) => {
  let reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = function() {
    cb(reader.result)
  }
  reader.onerror = function(err) {
    console.log('Error: ', err)
  }
}
export const spinnerarray = [
  0,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26
]

// Function to get gradient color based on status
export const getGradientColor = status => {
  switch (status) {
    case 'Expired':
      return 'linear-gradient(90deg, rgba(255,0,0,1) 0%, rgba(255,154,0,1) 100%)'
    case 'Live':
      return 'linear-gradient(90deg, rgba(0,255,0,1) 0%, rgba(0,123,255,1) 100%)'
    case 'Upcoming':
      return 'linear-gradient(90deg, rgba(0,0,255,1) 0%, rgba(123,0,255,1) 100%)'
    default:
      return 'linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(200,200,200,1) 100%)'
  }
}

// Function to get status color based on status
export const getStatusColors = status => {
  switch (status) {
    case 'Expired':
      return { chipColor: '#FF0000', boxColor: '#FFE5E5', cardContentColor: '#FFCCCC' } // Red for Expired
    case 'Live':
      return { chipColor: '#019031', boxColor: '#E5FFE5', cardContentColor: '#CCFFCC' } // Green for Live
    case 'Upcoming':
      return { chipColor: '#0000FF', boxColor: '#E5E5FF', cardContentColor: '#CCCCFF' } // Blue for Upcoming
    default:
      return { chipColor: '#000000', boxColor: '#E5E5E5', cardContentColor: '#F5F5F5' } // Default color
  }
}

// Function to get gradient color for number button
export const getNumberButtonGradient = number => {
  const colors = [
    'linear-gradient(135deg, #ff7e5f 0%, #feb47b 100%)',
    'linear-gradient(135deg, #6a11cb 0%, #2575fc 100%)',
    'linear-gradient(135deg, #ff512f 0%, #dd2476 100%)',
    'linear-gradient(135deg, #00c6ff 0%, #0072ff 100%)',
    'linear-gradient(135deg, #ff6a00 0%, #ee0979 100%)',
    'linear-gradient(135deg, #00f260 0%, #0575e6 100%)',
    'linear-gradient(135deg, #fc00ff 0%, #00dbde 100%)',
    'linear-gradient(135deg, #ffafbd 0%, #ffc3a0 100%)',
    'linear-gradient(135deg, #56ab2f 0%, #a8e063 100%)'
  ]
  return colors[number - 1]
}
