import { Box, Button } from '@material-ui/core'
import React from 'react'

export default function Devnet() {
  return (
    <Box
      style={{
        background: '#152232',
        border: '1px solid #ffffff52',
        borderRadius: '26px',

        width: '120px',
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <Button
        variant="text"
        style={{ color: '#fff', padding: '6px 10px', backgroundColor: '#152232', borderRadius: '20px' }}
      >
        Devnet
      </Button>
    </Box>
  )
}
