import { Box, Grid } from '@material-ui/core'
import React, { useState } from 'react'
import NormalNftCards from '../../component/NormalNftCards'

const NormalNft = ({ nftList }) => {
  return (
    <>
      <Grid container spacing={2}>
        {nftList &&
          nftList.map((data, index, type) => {
            return (
              <Grid item lg={4} md={6} sm={6} xs={12}>
                <NormalNftCards data={data} type="creator" index={index} />
              </Grid>
            )
          })}

        {nftList && nftList?.length === 0 && 'Data not found'}
      </Grid>
    </>
  )
}

export default NormalNft
