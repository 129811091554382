import React, { useState, useEffect, useRef } from 'react'

const NumberAnimation = ({ targetNumber, duration = 2000 }) => {
  const [currentNumber, setCurrentNumber] = useState(0)
  const requestRef = useRef()
  const startTimeRef = useRef()

  const animate = time => {
    if (!startTimeRef.current) {
      startTimeRef.current = time
    }

    const progress = time - startTimeRef.current
    const current = Math.min(progress / duration, 1) * targetNumber

    setCurrentNumber(current.toFixed(2))

    if (progress < duration) {
      requestRef.current = requestAnimationFrame(animate)
    } else {
      cancelAnimationFrame(requestRef.current)
    }
  }

  useEffect(() => {
    startTimeRef.current = null // Reset start time
    requestRef.current = requestAnimationFrame(animate)

    return () => cancelAnimationFrame(requestRef.current)
  }, [targetNumber, duration])

  return <>{currentNumber}</>
}

export default NumberAnimation
