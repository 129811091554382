import {
  Box,
  Container,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";

const items = [
  { title: "Amazing Service", topSpace: false, bottomBorder: true },
  {
    title: "The complexity of simplicity",
    topSpace: true,
    bottomBorder: false,
  },
  {
    title: "For better quality of experience",
    topSpace: false,
    bottomBorder: false,
  },
  { title: "Do great things every day", topSpace: true, bottomBorder4: true },
];

const useStyles = makeStyles((theme) => ({
  borderResponsive: {
    width: "100%",
  },
  heading: {
    color: "#fff",
    fontSize: "36px",
    fontWeight: "500",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
}));

export default function SmartSolutionPage() {
  const classes = useStyles();

  return (
    <Container>
      <Container maxWidth="md">
        <Box
          mt={5}
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <Typography
            variant="h5"
            style={{
              //  fontSize: "25px",
              //  paddingBottom: "9px",
              color: "#fff",
              marginBottom: "16px",
            }}
            className={classes.heading}
          >
            <span style={{ color: "#4AFCFE" }}> Smart Solutions </span> For The
            Automated World
          </Typography>
          <Typography
            variant="h6"
            color="primary"
            style={{ fontWeight: "300", fontSize: "14px" }}
          >
            In publishing and graphic design, Lorem ipsum is a placeholder text
            commonly used to demonstrate the visual form of a document.
          </Typography>
        </Box>
      </Container>
      <Container maxWidth="md">
        <Grid
          container
          spacing={6}
          style={{ marginTop: "20px", marginBottom: "30px" }}
        >
          {items.map((item, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              key={index}
              style={{
                position: "relative",
                paddingBottom: item.bottomBorder ? "16px" : "0",
                marginTop: item.topSpace ? "56px" : "0",
                // borderRight: item.bottomBorder ? "1px solid #4AFCFE" : "none",
                height: item.bottomBorder ? "100%" : "auto",
              }}
            >
              <Box
                style={{
                  borderBottom: item.bottomBorder
                    ? "none"
                    : "1px solid transparent",
                  paddingBottom: item.bottomBorder ? "16px" : "0",
                }}
              >
                <img
                  style={{ height: "45px", width: "45px" }}
                  src="new-module/icon.png"
                  alt="icon"
                />
                <Typography
                  variant="h5"
                  style={{ fontSize: "30px", color: "#fff" }}
                >
                  {item.title}
                </Typography>
                <Typography style={{ paddingTop: "10px" }}>
                  Be a holder of your generative crypto savings account and
                  start your second source of income while sipping a coffee.
                  Daily Interest of 10% APY on your crypto holdings will be
                  deposited in your account.
                </Typography>
              </Box>
              {item?.bottomBorder && (
                <Box
                  style={{
                    position: "absolute",
                    right: "-1px",
                    top: "0",
                    bottom: "0",
                    height: "100%",
                    // overflow: "hidden",
                    // borderRight: "1px solid #4AFCFE",
                  }}
                >
                  <img
                    style={{ height: "calc(100vh - 280px)" }}
                    src="new-module/line.png"
                    alt="line"
                  />
                </Box>
              )}
              {item?.bottomBorder4 && (
                <Box
                  className={classes.borderResponsive}
                  style={{
                    position: "absolute",
                    left: "0",
                    top: "-30px",
                    overflow: "hidden",
                    // borderRight: "1px solid #4AFCFE",
                  }}
                >
                  <img
                    style={
                      {
                        //   height: "calc(100vh - 280px)",
                      }
                    }
                    src="new-module/line2.png"
                    alt="line"
                  />
                </Box>
              )}
              {item?.bottomBorder && (
                <Box
                  style={{
                    position: "absolute",
                    right: "0",
                    bottom: "-30",
                    // overflow: "hidden",
                    // borderRight: "1px solid #4AFCFE",
                  }}
                >
                  <img
                    style={
                      {
                        //   height: "calc(100vh - 280px)",
                      }
                    }
                    src="new-module/line1.png"
                    alt="line"
                  />
                </Box>
              )}
            </Grid>
          ))}
        </Grid>
      </Container>
    </Container>
  );
}
