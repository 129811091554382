import React from 'react'
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  makeStyles,
  Select,
  MenuItem
} from '@material-ui/core'
import { right } from '@popperjs/core'
import Slider from 'react-slick'
import { sortAddress } from 'mint-utils'
import { Skeleton } from '@material-ui/lab'
import { BiCopy } from 'react-icons/bi'
import toast from 'react-hot-toast'

// Custom styles
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
    // padding: theme.spacing(2)
  },
  topUserCard: {
    overflow: 'inherit',
    background: '#fff',
    borderRadius: '24px',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    position: 'relative',
    overflow: 'hidden',
    margin: '0px 3px'
  },
  topUserCardContent: {
    padding: '150px 20px 30px',
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    borderRadius: '16px',
    textAlign: 'center',
    backdropFilter: 'blur(10px)',
    zIndex: 1,
    '& img': {
      width: '100%',
      height: '140px',
      maxWidth: '140px',
      objectFit: 'contain',
      position: 'fixed',
      top: '0',
      left: '35%'
    }
  },
  table: {
    minWidth: 650
  },
  headerCell: {
    fontWeight: 'bold'
  },
  rankingTop: {
    '&:before': {
      backgroundColor: '#ffc700',
      content: '""',
      height: '100%',
      margin: '0px -1px',
      position: 'absolute',
      top: '0px',
      width: '100%',
      right: '100%'
    },
    '&:after': {
      backgroundColor: '#ffc700',
      content: '""',
      height: '100%',
      margin: '0px -1px',
      position: 'absolute',
      top: '0px',
      width: '100%'
    }
  },
  select: {
    '& .MuiSelect-icon': {
      color: 'white'
    },
    '& .MuiOutlinedInput-root': {
      color: 'white',
      '& fieldset': {
        borderColor: 'white'
      },
      '&:hover fieldset': {
        borderColor: 'white'
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white'
      }
    }
  }
}))
let settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
}

const PredictionLeaderboard = ({
  poolHistoryData,
  type,
  isLoading,
  filterData,
  setFilterData,
  topIsLoading,
  topPoolHistoryData
}) => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      {type === 'leaderboard' && (
        <>
          {topIsLoading ? (
            <>
              <Slider {...settings}>
                {[...Array(3)].map((_, index) => (
                  <Box key={index}>
                    <Skeleton variant="rect" width="97%" height={150} style={{ borderRadius: '10px' }} />
                    <Skeleton width="60%" style={{ borderRadius: '10px' }} />
                    <Skeleton width="80%" style={{ borderRadius: '10px' }} />
                    <Skeleton width="40%" style={{ borderRadius: '10px' }} />
                  </Box>
                ))}
              </Slider>
            </>
          ) : (
            <Slider {...settings}>
              {topPoolHistoryData &&
                topPoolHistoryData?.slice(0, 3).map((user, index) => (
                  <Box
                    key={user.id}
                    style={{
                      background: '#e7e3eb',
                      borderRadius: '24px',
                      color: '#280d5f',
                      overflow: 'hidden',
                      position: 'relative',
                      padding: '1px 1px 3px',
                      margin: '0px 3px'
                    }}
                  >
                    <Box className={classes.topUserCard}>
                      <Box
                        className={classes.rankingTop}
                        style={{
                          zIndex: '9',
                          backgroundColor: '#ffc700',
                          color: 'white',
                          margin: '0px',
                          padding: '8px 0px',
                          position: 'absolute',
                          right: 'auto',
                          top: '0px',
                          textAlign: 'center',
                          transform: 'translateX(0%) translateY(200%) rotate(-45deg)',
                          transformOrigin: 'left top',
                          width: '96px'
                        }}
                      >
                        #{index + 1}
                      </Box>
                      <Box className={classes.topUserCardContent}>
                        <img
                          // style={{ height: index === 1 ? '110px' : '140px' }}
                          src={
                            index === 0
                              ? 'new-module/rank1.png'
                              : index === 1
                              ? 'new-module/rank2.png'
                              : 'new-module/rank3.png'
                          }
                        />
                        <Typography variant="h5" component="h2">
                          {user?.walletAddress && sortAddress(user?.walletAddress)}&nbsp;
                          <BiCopy
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              navigator.clipboard.writeText(user?.walletAddress)
                              toast.success('Copied')
                            }}
                          />
                        </Typography>
                        <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Typography
                            style={{
                              color: '#7a6eaa',
                              fontWeight: '400',
                              lineHeight: '1.5',
                              fontSize: '12px'
                            }}
                            color="textSecondary"
                          >
                            Win Ratio
                          </Typography>
                          <Typography
                            style={{ color: '#280d5f', lineHeight: '1.5', fontWeight: 'bold', fontSize: '16px' }}
                            color="textSecondary"
                          >
                            {' '}
                            {user?.winningRatio && Number(user?.winningRatio).toFixed(2)}%
                          </Typography>
                        </Box>
                        <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Typography
                            style={{
                              color: '#7a6eaa',
                              fontWeight: '400',
                              lineHeight: '1.5',
                              fontSize: '12px'
                            }}
                            color="textSecondary"
                          >
                            Net Winnings
                          </Typography>
                          <Typography
                            style={{
                              color: '#31d0aa',
                              lineHeight: '1.5',
                              fontWeight: 'bold',
                              fontSize: '16px'
                            }}
                            color="textSecondary"
                          >
                            {user?.totalWinAmount && Number(user?.totalWinAmount).toFixed(2)} TARAL
                          </Typography>
                        </Box>
                        <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Typography
                            style={{
                              color: '#7a6eaa',
                              fontWeight: '400',
                              lineHeight: '1.5',
                              fontSize: '12px'
                            }}
                            color="textSecondary"
                          >
                            Rounds Won{' '}
                          </Typography>
                          <Typography
                            style={{ color: '#280d5f', lineHeight: '1.5', fontWeight: 'bold', fontSize: '16px' }}
                            color="textSecondary"
                          >
                            {user?.participateWinCount}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                ))}
            </Slider>
          )}
        </>
      )}

      {type === 'leaderboard' && (
        <Box width="100%" style={{ display: 'flex', justifyContent: 'end', margin: '10px 0px' }}>
          <Box style={{ width: '200px' }}>
            <Select
              value={filterData}
              fullWidth
              variant="outlined"
              onChange={e => setFilterData(e.target.value)}
              className={classes.select}
            >
              {['Hourly', 'Daily', 'Weekly', 'Monthly'].map(value => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
      )}

      <TableContainer style={{ marginTop: '30px' }} component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.headerCell}>User</TableCell>
              <TableCell className={classes.headerCell}>Win Ratio</TableCell>
              <TableCell className={classes.headerCell}>Net Winnings</TableCell>
              <TableCell className={classes.headerCell}>Rounds Won</TableCell>
              <TableCell className={classes.headerCell}>Participant Count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading
              ? [...Array(5)].map((_, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Skeleton width="80%" />
                    </TableCell>
                    <TableCell>
                      <Skeleton width="40%" />
                    </TableCell>
                    <TableCell>
                      <Skeleton width="60%" />
                    </TableCell>
                    <TableCell>
                      <Skeleton width="50%" />
                    </TableCell>
                    <TableCell>
                      <Skeleton width="30%" />
                    </TableCell>
                  </TableRow>
                ))
              : poolHistoryData.map(user => (
                  <TableRow key={user.id}>
                    <TableCell>
                      {user?.walletAddress && sortAddress(user?.walletAddress)}&nbsp;
                      <BiCopy
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          navigator.clipboard.writeText(user?.walletAddress)
                          toast.success('Copied')
                        }}
                      />
                    </TableCell>
                    <TableCell>{user?.winningRatio && Number(user?.winningRatio).toFixed(2)}%</TableCell>
                    <TableCell>{user?.totalWinAmount && Number(user?.totalWinAmount).toFixed(2)} TARAL</TableCell>
                    <TableCell>{user?.participateWinCount}</TableCell>
                    <TableCell>{user?.participateCount}</TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

const App = ({ type, poolHistoryData, isLoading, setFilterData, filterData, topIsLoading, topPoolHistoryData }) => {
  return (
    <PredictionLeaderboard
      filterData={filterData}
      setFilterData={setFilterData}
      isLoading={isLoading}
      poolHistoryData={poolHistoryData}
      type={type}
      topPoolHistoryData={topPoolHistoryData}
      topIsLoading={topIsLoading}
    />
  )
}

export default App
