import React from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  IconButton
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { Dots } from 'components/swap/styleds'

const ConfirmationModal = ({ open, handleClose, handleConfirm, isLoading, classes }) => {
  const dialogStyle = {
    backgroundColor: '#1b2c42',
    color: 'white',
    borderRadius: '24px'
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        if (!isLoading) {
          handleClose()
        }
      }}
      aria-labelledby="confirmation-dialog-title"
      aria-describedby="confirmation-dialog-description"
      fullWidth
      maxWidth="xs"
      PaperProps={{ style: dialogStyle }}
    >
      <DialogTitle
        id="confirmation-dialog-title"
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', ...dialogStyle }}
      >
        Confirm Action
        {/* <IconButton
          disabled={isLoading}
          aria-label="close"
          onClick={handleClose}
          style={{ position: 'absolute', right: 16, top: 5, color: 'white' }}
        >
          <CloseIcon />
        </IconButton> */}
      </DialogTitle>
      <DialogContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', ...dialogStyle }}>
        <DialogContentText id="confirmation-dialog-description" style={{ color: 'white' }}>
          Are you sure you want to participate in this pool?
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center', marginBottom: '10px', ...dialogStyle }}>
        <Button
          className={classes.button}
          onClick={handleClose}
          variant="contained"
          color="primary"
          disabled={isLoading}
          style={{ color: 'white', width: '120px' }}
        >
          Cancel
        </Button>
        <Button
          className={classes.button}
          onClick={handleConfirm}
          variant="contained"
          color="primary"
          autoFocus
          disabled={isLoading}
          style={{ color: 'white', marginLeft: '40px', width: '120px' }}
        >
          {isLoading ? <Dots>Participating</Dots> : 'Confirm'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationModal
