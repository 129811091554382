import React, { createContext, useEffect, useState } from 'react'
import Web3 from 'web3'
import { useWeb3React } from '@web3-react/core'
import {
  contractKovan,
  ChainIDBinance,
  getMarketplaceContractAddress,
  ChainIDDevNet,
  contractTaral,
  ChainIDEthereum,
  genrativeNFTSepolia,
  ChainIDPolygon,
  genrativeNFTPolygon
} from '../mint-constants'
import { getNetworkDetails } from '../mint-constants'
// import { SUPPORTED_WALLETS } from "src/connectors";
import GenerativeNFTABI from '../mint-constants/ABI/GenerativeNFTABI'
import MarketplaceABI from '../mint-constants/ABI/MarketplaceABI.json'
import Apiconfig from '../ApiConfig/ApiConfig'
import axios from 'axios'
import { getWeb3ContractObject, getBalanceOf, getContract } from '../mint-utils'
import toast from 'react-hot-toast'
import { InjectedConnector } from '@web3-react/injected-connector'

export const injected = new InjectedConnector({
  supportedChainIds: [1, 3, 4, 5, 42, 56, 97, 1285, 1287, 361, 9731, 9732, 4442, 11155111, 80002]
})

export const SUPPORTED_WALLETS = [
  {
    name: 'METAMASK',
    data: {
      connector: injected,
      name: 'MetaMask',
      href: null,
      color: '#E8831D'
    }
  }
]

export const UserContext = createContext()

const setSession = userAddress => {
  if (userAddress) {
    sessionStorage.setItem('userAddress', userAddress)
  } else {
    sessionStorage.removeItem('userAddress')
  }
}
const setTokenSession = token => {
  if (token) {
    sessionStorage.setItem('token', token)
  } else {
    sessionStorage.removeItem('token')
  }
}
export default function AuthProvider(props) {
  const { activate, account, chainId, deactivate, library } = useWeb3React()
  const [collectionList, setCollectionList] = useState([])
  const [selectNetwork, setSelectNetwork] = useState(
    window.localStorage.getItem('currentNetwork') ? window.localStorage.getItem('currentNetwork') : ChainIDBinance
  )
  const [isLoadingData, setIsLoadingData] = useState(true)
  const [nftPrice, setNftPrice] = useState(0)
  const [userData, setUserData] = useState()
  const [isLogin, setIsLogin] = useState(false)
  const [totalSupply, setTotalSupply] = useState(0)
  const [successMSG, setSuccessMSG] = useState('')
  const [dashboarddata, setDashboarddata] = useState('')
  const [walletdata, setwalletData] = useState('')
  const [walletuserId, setwalletuserId] = useState('')
  const [generativeid, setGenerative] = useState('')
  const [generativeidc, setGenerativecon] = useState('')
  const [balanceOfValue, setBalanceOfValue] = useState(0)
  const [userNFtLoading, setUserNftLoading] = useState(false)
  const [userNFTList, setUserNFTList] = useState([])
  const [ownerAccount, setOwnerAccount] = useState('')
  const [yourWalletBalance, setYourWalletBalance] = useState(0)
  const [errorMsg, setErrorMsg] = useState('')
  const [walletError, setwalletError] = useState(false)
  const [checkspin, setCheckspin] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [MAX_NFT_SUPPLY, setMAX_NFT_SUPPLY] = useState(0)
  // console.log(">>>>>>>>>>>>>MAX_NFT_SUPPLY", MAX_NFT_SUPPLY);
  const connectToWallet = data => {
    if (data) {
      window.localStorage.setItem('currentNetwork', selectNetwork)
      const connector = data.data?.connector
      window.sessionStorage.removeItem('walletName')
      window.sessionStorage.setItem('walletName', data.name)
      setErrorMsg('')
      setSuccessMSG('')
      if (connector && connector.walletConnectProvider?.wc?.uri) {
        connector.walletConnectProvider = undefined
      }
      activate(connector, undefined, true).catch(error => {
        if (error) {
          console.log('error', error.message)
          setwalletError(true)
          setErrorMsg(error.message)
          activate(connector)
          setIsLoading(false)
          // setErrorPop(true);
        }
      })
    } else {
      setIsLoading(false)
    }
  }

  const swichNetworkHandler = async network => {
    try {
      window.localStorage.setItem('currentNetwork', network)

      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x' + network.toString(16) }]
      })
      return true
    } catch (error) {
      setSelectNetwork(chainId)
      if (error.code === 4902) {
        addNetworkHandler()
      }
    }
  }

  const addNetworkHandler = async () => {
    try {
      const NetworkDetails = getNetworkDetails(selectNetwork)

      await window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: NetworkDetails
      })
    } catch (error) {}
  }

  const getUserbalce = async () => {
    var web3 = new Web3(library.provider)
    const balance = await web3.eth.getBalance(account)
    const balanceImETH = await web3.utils.fromWei(balance)
    setYourWalletBalance(parseFloat(balanceImETH).toFixed(2))
  }

  const connectWalletHandler = async walletAddress => {
    try {
      const res = await axios.post(Apiconfig.connectWallet, {
        walletAddress
      })
      if (res.data.responseCode === 200) {
        getProfileHandler(res.data.result.token)
        setTokenSession(res.data.result.token)
        setCheckspin(res.data.result.isSpin)
        setwalletData(res.data.result.status)
        setwalletuserId(res.data.result.userId)
        window.localStorage.setItem('userAddresstoken', res.data.result.token)
        if (!window.sessionStorage.getItem('walletName')) {
          // toast.success(res.data.responseMessage)
        }
      } else {
        deactivate()
        setIsLogin(false)
        setUserData()
        setIsLoading(false)
        toast.error(res.data.responseMessage)
      }
    } catch (error) {
      deactivate()
      setIsLogin(false)

      setIsLoading(false)
    }
  }
  const getProfileHandler = async token => {
    try {
      const res = await axios.get(Apiconfig.profile, {
        headers: {
          token
        }
      })
      if (res.data.statusCode === 200) {
        setUserData(res.data.result)

        setIsLogin(true)
      } else {
        setIsLogin(false)
      }
      setTimeout(() => {
        setIsLoading(false)
      })
    } catch (error) {
      setIsLogin(false)
      setIsLoading(false)
    }
  }

  const getCurrentMintingDetails = async () => {
    const contractObj = await getWeb3ContractObject(
      GenerativeNFTABI,
      chainId === ChainIDPolygon
        ? genrativeNFTPolygon
        : chainId === ChainIDDevNet
        ? contractTaral
        : chainId === ChainIDEthereum
        ? genrativeNFTSepolia
        : contractKovan,
      chainId
    )
    const MAX_NFT_SUPPLY = await contractObj.methods.MAX_NFT_SUPPLY().call()

    setMAX_NFT_SUPPLY(Number(MAX_NFT_SUPPLY))
    const totalSupply = await contractObj.methods.totalSupply().call()

    setTotalSupply(Number(totalSupply))
  }

  const getContractDetailsHandler = async () => {
    try {
      const marketPlaceContract = getMarketplaceContractAddress(parseInt(selectNetwork))
      const contractObj = await getWeb3ContractObject(MarketplaceABI, marketPlaceContract, chainId)
      const ownerfun = await contractObj.methods.owner().call()
      setOwnerAccount(ownerfun)
    } catch (error) {}
  }

  const userNFTListHadler = async balanceOf => {
    setUserNFTList([])
    setUserNftLoading(true)
    const contract = getContract(
      chainId === ChainIDPolygon
        ? genrativeNFTPolygon
        : chainId === ChainIDDevNet
        ? contractTaral
        : chainId === ChainIDEthereum
        ? genrativeNFTSepolia
        : contractKovan,
      GenerativeNFTABI,
      library,
      account
    )

    try {
      for (let i = 0; i < balanceOf; i++) {
        const id = await contract.tokenOfOwnerByIndex(account, i)
        const filter = await contract.tokenURI(id.toString())

        const res = await axios.get(filter)

        if (res.status === 200) {
          setUserNFTList(prev => [...prev, { id: id.toString(), nfdData: res.data }])
          setUserNftLoading(false)
        }
      }
    } catch (e) {
      console.log(e)
      setUserNftLoading(false)
    }
  }

  const disconnectWallte = async () => {
    deactivate()
  }

  const getCollectionList = async () => {
    try {
      const res = await axios.get(Apiconfig.myCollectionList, {
        headers: {
          token: sessionStorage.getItem('token')
        }
      })
      if (res.data.statusCode === 200) {
        if (res.data.result.docs) {
          setCollectionList(res.data.result.docs)
          const result = res.data.result.docs.filter(
            data =>
              data?.displayName === 'GenerativeNFT' &&
              data?.contractAddress ===
                (chainId === 97
                  ? contractKovan
                  : chainId === 4442
                  ? contractTaral
                  : chainId === 11155111
                  ? genrativeNFTSepolia
                  : genrativeNFTPolygon)
          )

          setGenerative(result[0]?._id)
          setGenerativecon(result[0]?.contractAddress)
        } else {
          setCollectionList(res.data.result.docs)
        }
      }
    } catch (error) {}
  }

  //Dashboard

  const getDashboardData = async () => {
    try {
      const res = await axios.get(Apiconfig.dashboardCount)
      if (res.data.statusCode === 200) {
        if (res.data.result) {
          setDashboarddata(res.data.result)
        } else {
          setDashboarddata(res.data.result.docs)
        }
      }
    } catch (error) {
      console.error('ERROR', error)
    }
  }

  useEffect(() => {
    const { ethereum } = window

    if (ethereum) {
      const handleAccountsChanged = accounts => {
        if (accounts.length > 0) {
          window.location.reload()
        }
      }

      ethereum.on('accountsChanged', handleAccountsChanged)

      return () => {
        if (ethereum.removeListener) {
          ethereum.removeListener('accountsChanged', handleAccountsChanged)
        }
      }
    }
  }, [])

  let data = {
    updateUser: account => {
      setSession(account)
    },
    connectWallet: data => connectToWallet(data),
    connectWalletHandler: data => connectWalletHandler(data),

    getCollectionList: () => getCollectionList(),
    userData,
    isLogin,
    nftPrice,
    totalSupply,
    isLoading,
    balanceOfValue,
    MAX_NFT_SUPPLY,
    userNFTList,
    collectionList,
    generativeid,
    generativeidc,
    dashboarddata,
    walletdata,
    walletuserId,
    walletError,
    setwalletError,
    ownerAccount,
    selectNetwork,
    checkspin,
    setSelectNetwork: data => setSelectNetwork(data),
    getProfileHandler: token => getProfileHandler(token),
    swichNetworkHandler: chain => swichNetworkHandler(chain),
    dicconectWalletFun: () => {
      disconnectWallte()
      setIsLoading(false)
    },
    logoutHandler: () => {
      setIsLogin(false)
      setUserData()
      // setYourWalletBalance(0);
      deactivate()
      sessionStorage.removeItem('token')
      sessionStorage.removeItem('walletName')
      window.localStorage.removeItem('userAddresstoken')
      window.location.href = '/mint'
      // window.location.href = "/";
    }
  }
  useEffect(() => {
    if (window.sessionStorage.getItem('walletName')) {
      const selectectWalletDetails = SUPPORTED_WALLETS.filter(
        data => data.name === window.sessionStorage.getItem('walletName')
      )
      connectToWallet(selectectWalletDetails[0])
    } else {
      setIsLoading(false)
    }
  }, [])
  useEffect(() => {
    const userAddress = window.sessionStorage.getItem('userAddress')
    if (userAddress) {
      data.connectWallet()
    }
  }, [])

  useEffect(() => {
    if (account && chainId) {
      connectWalletHandler(account)
      data.updateUser(account)
      getBalanceOfFun()
      getUserbalce()
    } else {
      setIsLogin(false)
      setUserData()
    }
  }, [account, chainId])
  useEffect(() => {
    if (balanceOfValue > 0) {
      userNFTListHadler(balanceOfValue)
    }
  }, [balanceOfValue, account])
  useEffect(() => {
    if (account && chainId) {
      if (chainId != selectNetwork) {
        window.scrollTo(0, 0)
        if (window.ethereum) {
          swichNetworkHandler(selectNetwork)
        }
      }
    }
  }, [chainId, account, selectNetwork])
  useEffect(() => {
    if (chainId) {
      getContractDetailsHandler()
      getCurrentMintingDetails()
    }
  }, [chainId])
  useEffect(() => {
    if (userData && userData._id && chainId) {
      getCollectionList()
      getDashboardData()
    }
  }, [userData, chainId])
  async function getBalanceOfFun() {
    setBalanceOfValue(
      await getBalanceOf(
        GenerativeNFTABI,
        chainId === ChainIDPolygon
          ? genrativeNFTPolygon
          : chainId === ChainIDDevNet
          ? contractTaral
          : chainId === ChainIDEthereum
          ? genrativeNFTSepolia
          : contractKovan,
        account,
        chainId
      )
    )
  }

  return <UserContext.Provider value={data}>{props.children}</UserContext.Provider>
}
