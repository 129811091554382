/* eslint-disable */

import React, { createContext, useEffect, useState, useCallback } from 'react'
import BigNumber from 'bignumber.js'
import multicall from '../utils/multicall'
import erc20 from '../constants/abis/erc20.json'
import { getBalanceNumber } from '../utils/formatBalance'
import { useBlock } from '../hooks/useBlock'

export interface PriceContext {
  htPrice: number
  pippiPrice: number
}

export const Context = createContext<PriceContext>({
  htPrice: 0,
  pippiPrice: 0
})

const PriceProvider: React.FC = ({ children }) => {
  const block = useBlock()
  const [price, setPrice] = useState({
    htPrice: 0,
    pippiPrice: 0
  })

  const fetchBalance = useCallback(async () => {
    const calls = [
      {
        // todo: replace address
        // usdt
        address: '0x1a3E80cA492bd18b2a5b81d4D297F813e1c93692',
        name: 'balanceOf',
        // hoo-usdt lp
        params: ['0xa75a487b8cCF335f35BD11bbeFB6f82c9BffacBd']
      }
    ]

    try {
      const [busd, bnb0, cake, bnb1] = await multicall(erc20, calls)
      console.log(busd, bnb0, cake, bnb1)

      const htPrice = getBalanceNumber(new BigNumber(busd), 6) / getBalanceNumber(new BigNumber(bnb0))
      const cakebnb = getBalanceNumber(new BigNumber(bnb1)) / getBalanceNumber(new BigNumber(cake))
      const pippiPrice = cakebnb * htPrice

      // console.log(htPrice,cakebnb,"MMM")
      setPrice({
        htPrice,
        pippiPrice
      })
    } catch (error) {
      console.log('error..', error)
    }
  }, [])

  useEffect(() => {
    fetchBalance()
  }, [setPrice, block, fetchBalance])

  return <Context.Provider value={{ ...price }}>{children}</Context.Provider>
}

export default PriceProvider
