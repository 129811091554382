import React, { useEffect, useMemo, useState } from 'react'
import Slider from 'react-slick'
import PredictionCard from './PredicationCard'
import Header from 'components/Header'
import {
  Box,
  Button,
  Container,
  IconButton,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  withStyles,
  Table,
  TablePagination
} from '@material-ui/core'
import { Pagination, Skeleton } from '@material-ui/lab'
import styled from 'styled-components'
import Footer from 'pages/HomePage1/footer/Footer'
import { MdGamepad, MdHistory, MdLeaderboard } from 'react-icons/md'
import SportsEsportsIcon from '@material-ui/icons/SportsEsports'
import LeaderBoard from './leaderboard'
import DataNotFound from '../../component/DataNotFound'
import './game.css'
import { ethers } from 'ethers'
import { predicationContract } from 'mint-constants'
import PredicationABI from '../../mint-constants/ABI/PredicationABI.json'
import io from 'socket.io-client'
import { apiRouterCall } from '../../ApiConfig/services'
import { socketURL } from 'ApiConfig/ApiConfig'
import { useWeb3React } from '@web3-react/core'
import SelectComponent from './SelectComponent'
import GameRuleModal from './confirmation-modal/GameRuleModal'
import { sortAddress } from 'mint-utils'
import { BiCopy } from 'react-icons/bi'
import toast from 'react-hot-toast'

const CustomTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.black,
    color: 'rgb(49 208 170)',
    boxShadow: theme.shadows[1],
    fontSize: 11
  }
}))(Tooltip)

const IconButtonWithTooltip = ({ title, onClick, activeTab, tabName, IconComponent }) => (
  <CustomTooltip title={title} arrow>
    <IconButton onClick={onClick}>
      <IconComponent style={{ color: activeTab === tabName ? 'rgb(49 208 170)' : '#fff' }} />
    </IconButton>
  </CustomTooltip>
)

const addresses = {
  BTC: '0x039D97881199fDf559492f65c6247209DF502Fe3'
  // ETH: '0xd33C6583f3852e2bF6422EABF41486bed3D817a4',
  // SOL: '0xEb2F8b873CbeD25C76eAb183c368946ed0132AC9',
  // BNB: '0x9E708999c4Cf8e28A69397ff14Aa83EDD0761077',
  // XRP: '0xEfBFB738A5f45DFBfCf2e25c72868E5a690845C7'
}

const HeaderWrapper = styled.div`
  ${'' /* justify-content: space-between; */}
`
const AppWrapper = styled.div`
  //  display: flex;
  //  flex-flow: column;
  //   align-items: flex-start;
  //  overflow-x: hidden;
  //  min-height: 100vh;
`

const BodyWrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 1rem;
  margin-top: 10px;
`

const useStyles = makeStyles(theme => ({
  select: {
    '& .MuiSelect-icon': {
      color: 'white'
    },
    '& .MuiOutlinedInput-root': {
      color: 'white',
      '& fieldset': {
        borderColor: 'white'
      },
      '&:hover fieldset': {
        borderColor: 'white'
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white'
      }
    }
  },
  mainHeading: {
    display: 'flex',
    justifyContent: 'space-between',
    '@media(max-width:767px)': {
      display: 'block'
    },
    '& h3': {
      fontSize: '40px',
      paddingBottom: '10px',
      '@media(max-width:767px)': {
        fontSize: '30px'
      }
    },
    '& p': {
      paddingBottom: '10px'
    }
  }
}))

const convertInNumber = rewardAmount => {
  const bigNumber = ethers.BigNumber.from(rewardAmount)
  const number = bigNumber.toString()
  return number
}

const PredictionSlider = () => {
  const classes = useStyles()
  const { account, library } = useWeb3React()

  const [poolData, setPoolData] = useState([])
  const [poolHistoryData, setPoolHistoryData] = useState([])
  const [poolParticularHistoryData, setPoolParticularHistoryData] = useState([])
  const [topPoolHistoryData, setTopPoolHistoryData] = useState([])
  const [priceData, setPriceData] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [topIsLoading, setTopIsLoading] = useState(true)
  const [isLoading1, setIsLoading1] = useState(true)
  const [activeTab, setActiveTab] = useState('Game')
  const [filterData, setFilterData] = useState('Hourly')
  const [totalPage, setTotalPage] = useState(1)
  const [gameRule, setGameRule] = useState(false)
  const [page, setPage] = useState(1)
  const [particularPoolParticipantList, setParticularPoolParticipantList] = useState([])
  const [filterSymbol, setFilterSymbol] = useState('')
  const [selectedAddress, setSelectedAddress] = useState(addresses['BTC'])
  const [isPastparticipantLoading, setIsPastparticipantLoading] = useState(false)
  const [totalCountPast, setTotalCountPast] = useState(1)
  const [pastPage, setpastPage] = useState(1)
  const [currentPastItem, setCurrentPastItem] = useState({})

  // API to get previous selected pool history inactivePoolList
  const poolsDataHistpory = async symbol => {
    try {
      setIsLoading(true)
      const res = await apiRouterCall({
        method: 'POST',
        endPoint: 'inactivePoolList',
        data: {
          limit: activeTab === 'Game' ? 1 : 20,
          search: symbol,
          filterBy: activeTab === 'Game' ? 'active' : 'inactive'
        }
      })
      if (res.data.responseCode === 200) {
        setPoolHistoryData(res.data.result.docs)

        //  else {
        //   const filteredDocs = res.data.result.docs.length > 0 ? res.data.result.docs.slice(1) : []
        //   setPoolHistoryData(filteredDocs)
        // }

        setIsLoading(false)
      } else {
        setIsLoading(false)
      }
    } catch (error) {
      setPoolHistoryData([])
      setIsLoading(false)
      console.log('>>>>>>>>>>>..errorpools', error)
    }
  }
  // function to get leader board data leaderBoard

  const poolsleaderBoardDataHistpory = async symbol => {
    try {
      setIsLoading(true)
      const res = await apiRouterCall({
        method: 'POST',
        endPoint: 'leaderBoard',
        data: {
          limit: 10,
          search: symbol,
          page: page,
          date:
            filterData === 'Hourly'
              ? 'hour'
              : filterData === 'Daily'
              ? 'day'
              : filterData === 'Weekly'
              ? 'week'
              : 'month'
        }
      })
      if (res.data.responseCode === 200) {
        setPoolHistoryData(res.data.result.docs)
        setTotalPage(res.data.result.totalPages)
        setIsLoading(false)
      } else {
        setIsLoading(false)
      }
    } catch (error) {
      setPoolHistoryData([])
      setIsLoading(false)
    }
  }

  // function to get particular user data
  const poolsParticularDataHistpory = async symbol => {
    try {
      setIsLoading1(true)
      const res = await apiRouterCall({
        method: 'POST',
        endPoint: 'leaderBoard',
        data: {
          limit: 10,
          search: symbol,
          page: page,
          walletAddress: account
        }
      })
      if (res.data.responseCode === 200) {
        setPoolParticularHistoryData(res.data.result.docs)
        setTotalPage(res.data.result.totalPages)
        setIsLoading1(false)
      } else {
        setIsLoading1(false)
      }
    } catch (error) {
      setPoolHistoryData([])
      setIsLoading1(false)
    }
  }

  // top leaderboard api
  const pooltopThreeBoardDataHistpory = async symbol => {
    try {
      setTopIsLoading(true)
      const res = await apiRouterCall({
        method: 'POST',
        endPoint: 'topThree',
        data: {
          search: symbol,
          date:
            filterData === 'Hourly'
              ? 'hour'
              : filterData === 'Daily'
              ? 'day'
              : filterData === 'Weekly'
              ? 'week'
              : 'month'
        }
      })
      if (res.data.responseCode === 200) {
        setTopPoolHistoryData(res.data.result)
        setTopIsLoading(false)
      } else {
        setTopIsLoading(false)
      }
    } catch (error) {
      setTopPoolHistoryData([])
      setTopIsLoading(false)
    }
  }
  const particularParticipantListGetter = async () => {
    try {
      setIsPastparticipantLoading(true)

      const res = await apiRouterCall({
        method: 'POST',
        endPoint: 'participateList',
        data: {
          limit: 10,
          page: pastPage,
          symbol: currentPastItem?.symbol,
          poolId: currentPastItem?.poolId
        }
      })
      if (res.data.responseCode === 200) {
        setParticularPoolParticipantList(res?.data?.result?.docs)
        setTotalCountPast(res?.data?.result?.totalPages)
        setIsPastparticipantLoading(false)
      } else {
        setIsPastparticipantLoading(false)
        setParticularPoolParticipantList([])
      }
    } catch (error) {
      setIsPastparticipantLoading(false)
      setParticularPoolParticipantList([])
    }
  }
  useEffect(() => {
    if (currentPastItem.symbol) {
      particularParticipantListGetter()
    }
  }, [currentPastItem, pastPage])

  useEffect(() => {
    setParticularPoolParticipantList([])
  }, [activeTab])

  // useEffect(() => {
  //   console.log(poolData, 'aljskas')
  //   if (activeTab == 'Past') {
  //     particularParticipantListGetter()
  //   }
  // }, [activeTab, poolData])

  useEffect(() => {
    const coinData = [
      {
        symbol: 'ETH'
      },
      {
        symbol: 'BNB'
      },
      {
        symbol: 'BTC'
      },
      {
        symbol: 'XRP'
      },
      {
        symbol: 'SOL'
      }
    ]
    const filterCoinData = coinData.filter(
      item => item.symbol === Object.keys(addresses).find(key => addresses[key] === selectedAddress)
    )
    setFilterSymbol(filterCoinData[0]?.symbol)
    if (selectedAddress) {
      if (activeTab === 'Game' || activeTab === 'Past') {
        poolsDataHistpory(filterCoinData[0]?.symbol)
      }
      if (activeTab === 'Leaderboard') {
        poolsleaderBoardDataHistpory(filterCoinData[0]?.symbol)
        pooltopThreeBoardDataHistpory(filterCoinData[0]?.symbol)
      }
      if (activeTab === 'History') {
        poolsParticularDataHistpory(filterCoinData[0]?.symbol)
      }
    }
  }, [selectedAddress, activeTab, filterData, page])

  let settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: activeTab === 'Past' ? 4 : 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: activeTab === 'Past' ? 2 : 2,
          slidesToScroll: 1,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

  return (
    <AppWrapper id="mainwrapper">
      <HeaderWrapper>
        <Header />
      </HeaderWrapper>
      <Box
        className="Main-div"
        style={{ position: 'relative', paddingBottom: '40px', minHeight: 'calc(100vh - 50px)' }}
      >
        <Box style={{ position: 'absolute', right: '30px', top: '50px' }}>
          <img src="/images/greenishbg.png" style={{ width: '100%', maxHeight: '700px' }} />
        </Box>
        <Box
          style={{
            top: '60%',
            left: '-70px',
            right: '0%',
            width: '314px',
            filter: 'blur(94px)',
            height: '314px',
            opacity: '40%',
            zIndex: '-1',
            position: 'absolute',
            transform: 'rotate(-90deg)',
            background: '#FED056',
            borderRadius: '1000px'
          }}
        ></Box>

        <div
          className="content-wrapper"
          style={{ position: 'relative' }}
          // style={{ paddingTop: '57px' }}
        >
          <Box display="flex" justifyContent="end" style={{ position: 'absolute', right: '30px', top: '10px' }}>
            <Button
              style={{
                marginBottom: '10px',
                color: 'rgb(255, 255, 255)',
                padding: '6px 10px',
                background: 'rgb(21, 34, 50)',
                borderRadius: '20px',
                border: '1px solid rgba(255, 255, 255, 0.32)'
              }}
              variant="contained"
              color="primary"
              onClick={() => setGameRule(true)}
            >
              Game Rules
            </Button>
          </Box>
          <div className="w-100 d-flex justify-content-center">
            <BodyWrapper id="bodywrapper">
              <Box>
                <Box mb={1} className={classes.mainHeading}>
                  <Box>
                    <Typography style={{ color: '#fff' }} variant="h3">
                      {activeTab === 'Leaderboard'
                        ? 'Leaderboard'
                        : activeTab === 'History'
                        ? 'History'
                        : `Unique Crypto Prediction: `}
                      {(activeTab === 'Game' || activeTab === 'Past') && (
                        <span style={{ color: 'rgb(74, 252, 254)' }}>Pick, Predict, Profit!</span>
                      )}
                    </Typography>
                    {(activeTab === 'Game' || activeTab === 'Past') && (
                      <Typography
                        style={{ color: 'rgb(74, 252, 254)', width: '100%', maxWidth: '450px' }}
                        variant="body2"
                      >
                        Guess the crypto price's final single digit between 1-9 and win free crypto! Pick, predict, and
                        Book your Profit.
                      </Typography>
                    )}
                  </Box>
                  <Box width="200px">
                    <SelectComponent
                      selectedAddress={selectedAddress}
                      setSelectedAddress={item => setSelectedAddress(item)}
                      classes={classes}
                      // priceData={priceData}
                      addresses={addresses}
                      activeTab={activeTab}
                      callSetData={item => setPriceData(item)}
                    />
                  </Box>
                </Box>
                <Box mt={1} mb={2} style={{ display: 'flex', justifyContent: 'end' }}>
                  <IconButtonWithTooltip
                    title="Live Game"
                    onClick={() => setActiveTab('Game')}
                    activeTab={activeTab}
                    tabName="Game"
                    IconComponent={SportsEsportsIcon}
                  />
                  <IconButtonWithTooltip
                    title="Past Game"
                    onClick={() => setActiveTab('Past')}
                    activeTab={activeTab}
                    tabName="Past"
                    IconComponent={SportsEsportsIcon}
                  />
                  <IconButtonWithTooltip
                    title="Leaderboard"
                    onClick={() => setActiveTab('Leaderboard')}
                    activeTab={activeTab}
                    tabName="Leaderboard"
                    IconComponent={MdLeaderboard}
                  />
                  <IconButtonWithTooltip
                    title="History"
                    onClick={() => {
                      if (account) {
                        setActiveTab('History')
                      }
                    }}
                    activeTab={activeTab}
                    tabName="History"
                    IconComponent={MdHistory}
                  />
                </Box>
                {(activeTab === 'Game' || activeTab === 'Past') && (
                  <>
                    {isLoading ? (
                      <Slider {...settings}>
                        {[...Array(activeTab === 'Past' ? 4 : 1)].map((_, index) => (
                          <Box key={index}>
                            <Skeleton variant="rect" width="97%" height={150} style={{ borderRadius: '10px' }} />
                            <Skeleton width="60%" style={{ borderRadius: '10px' }} />
                            <Skeleton width="80%" style={{ borderRadius: '10px' }} />
                            <Skeleton width="40%" style={{ borderRadius: '10px' }} />
                          </Box>
                        ))}
                      </Slider>
                    ) : (
                      <>
                        <Slider {...settings}>
                          {poolHistoryData &&
                            poolHistoryData?.map((prediction, index) => {
                              const expirationTime = new Date(prediction?.endDate).getTime()
                              const currentTime = new Date().getTime()

                              return (
                                <PredictionCard
                                  currentPastItem={currentPastItem}
                                  setCurrentPastItem={setCurrentPastItem}
                                  particularParticipantListGetter={particularParticipantListGetter}
                                  activeTab={activeTab}
                                  selectedAddress={selectedAddress}
                                  key={index}
                                  latestPrize={priceData?.price}
                                  closePrize={priceData?.current_price}
                                  status={
                                    prediction?.isActive ? 'Live' : !prediction?.isActive ? 'Expired' : 'Upcoming'
                                  }
                                  // lastPrice={prediction?.lastPrice}
                                  lockedPrice={prediction.lockedPrice}
                                  prizePool={prediction?.rewardAmount}
                                  item={prediction}
                                  callBack={() => {
                                    poolsDataHistpory(filterSymbol)
                                  }}
                                />
                              )
                            })}
                        </Slider>
                        {activeTab == 'past' &&
                          !isPastparticipantLoading &&
                          particularPoolParticipantList &&
                          particularPoolParticipantList.length === 0 && <DataNotFound title="Pool not found" />}

                        {(activeTab === 'Past' || activeTab === 'Game') && (
                          <TableContainer style={{ marginTop: '30px', maxHeight: '500px' }} component={Paper}>
                            <Table className={classes.table} aria-label="simple table">
                              {!isPastparticipantLoading &&
                                particularPoolParticipantList &&
                                particularPoolParticipantList.length !== 0 && (
                                  <TableHead>
                                    <TableRow>
                                      {/* <TableCell className={classes.headerCell}>Pool Id</TableCell> */}
                                      <TableCell className={classes.headerCell}>User</TableCell>
                                      <TableCell className={classes.headerCell}>Selected Number</TableCell>
                                      <TableCell className={classes.headerCell}>Result</TableCell>
                                    </TableRow>
                                  </TableHead>
                                )}
                              <TableBody>
                                {isPastparticipantLoading
                                  ? [...Array(5)].map((_, index) => (
                                      <TableRow key={index}>
                                        <TableCell>
                                          <Skeleton width="80%" />
                                        </TableCell>
                                        <TableCell>
                                          <Skeleton width="40%" />
                                        </TableCell>
                                        <TableCell>
                                          <Skeleton width="60%" />
                                        </TableCell>
                                        {/* <TableCell>
                                          <Skeleton width="50%" />
                                        </TableCell> */}
                                      </TableRow>
                                    ))
                                  : particularPoolParticipantList?.map((item, index) => {
                                      console.log(item, 'sadasd')
                                      return (
                                        <TableRow key={index}>
                                          {/* <TableCell>{item?.poolId ? `#${item?.poolId}` : '--'}</TableCell> */}
                                          <TableCell
                                            style={{
                                              color: item?.walletAddress === account ? 'rgb(49, 208, 170)' : '#fff'
                                            }}
                                          >
                                            {item?.walletAddress && sortAddress(item?.walletAddress)}&nbsp;
                                            <BiCopy
                                              style={{ cursor: 'pointer' }}
                                              onClick={() => {
                                                navigator.clipboard.writeText(item?.walletAddress)
                                                toast.success('Copied')
                                              }}
                                            />
                                          </TableCell>
                                          <TableCell>{item?.selectedNum ? item?.selectedNum : '--'}</TableCell>
                                          <TableCell>{item?.isWin ? item?.isWin : '--'}</TableCell>
                                        </TableRow>
                                      )
                                    })}
                              </TableBody>
                            </Table>
                            {!isPastparticipantLoading &&
                              particularPoolParticipantList &&
                              particularPoolParticipantList.length !== 0 && (
                                <Box
                                  style={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    alignItems: 'flex-end'
                                  }}
                                >
                                  <Pagination
                                    count={totalCountPast}
                                    onChange={(e, v) => setpastPage(v)}
                                    page={Number(pastPage)}
                                    variant="outlined"
                                    color="primary"
                                  />
                                </Box>
                              )}
                          </TableContainer>
                        )}
                      </>
                    )}
                  </>
                )}
                {activeTab === 'Leaderboard' && (
                  <>
                    <LeaderBoard
                      setFilterData={setFilterData}
                      filterData={filterData}
                      isLoading={isLoading}
                      type="leaderboard"
                      poolHistoryData={poolHistoryData}
                      topPoolHistoryData={topPoolHistoryData}
                      topIsLoading={topIsLoading}
                    />

                    {!isLoading && poolHistoryData && poolHistoryData.length === 0 && (
                      <DataNotFound title="Pool not found" />
                    )}
                  </>
                )}
                {activeTab === 'History' && (
                  <>
                    <LeaderBoard isLoading={isLoading1} poolHistoryData={poolParticularHistoryData} type="history" />
                    {!isLoading1 && poolParticularHistoryData && poolParticularHistoryData.length === 0 && (
                      <DataNotFound title="Pool not found" />
                    )}
                  </>
                )}
                {(activeTab === 'Leaderboard' || activeTab === 'History') && (
                  <Box mt={2} display="flex" justifyContent="end" width="100%">
                    {(activeTab === 'History' && poolParticularHistoryData.length > 0) ||
                    (activeTab === 'Leaderboard' && poolHistoryData.length > 0) ? (
                      <Pagination
                        count={totalPage}
                        onChange={(e, v) => setPage(v)}
                        page={Number(page)}
                        variant="outlined"
                        color="primary"
                      />
                    ) : null}
                  </Box>
                )}
              </Box>
              {gameRule && <GameRuleModal open={gameRule} handleClose={() => setGameRule(false)} />}
            </BodyWrapper>
          </div>
        </div>
      </Box>
      <Footer />
    </AppWrapper>
  )
}

export default PredictionSlider
