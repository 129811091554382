import React, { useState, useRef, useContext, useEffect } from 'react'

import {
  Grid,
  Box,
  Container,
  Typography,
  makeStyles,
  Button,
  ListItem,
  List,
  Link,
  IconButton
} from '@material-ui/core'

import { useHistory, Link as RouterLink } from 'react-router-dom'
import { AiOutlineMail } from 'react-icons/ai'
import { FaFacebookF, FaInstagram, FaLinkedin, FaMediumM, FaYoutube } from 'react-icons/fa'
import { FiSend, FiTwitter, FiYoutube } from 'react-icons/fi'
import { AiOutlineReddit, AiFillYoutube } from 'react-icons/ai'
import { FaPinterestP } from 'react-icons/fa'
import { FcReddit } from 'react-icons/fc'
import { useWeb3React } from '@web3-react/core'
import Logo from '../../../components/Header/Logo'

const useStyles = makeStyles(theme => ({
  footerSection: {
    background: '#000',
    position: 'relative',
    // padding: "50px 0px 0",
    zIndex: '1',
    overflow: ' hidden',
    // backgroundImage: "url('images/Footer background.png')",
    '& .iconBox': {
      // background: "linear-gradient(261.87deg, #62D3F0 13.12%, #35A5F5 83.57%)",
      background: '#33383d',
      border: '0.2px solid #ffffff6e',
      margin: '5px',
      marginTop: '40px'
    },
    '& .copy': {
      borderTop: '1px solid #d0d0d061',
      padding: '10px 0',
      textAlign: 'center',
      fontWeight: 300,
      fontSize: '12px',
      color: '#626262'
    },
    '& .shape': {
      position: 'absolute',
      right: '20px',
      top: '50px',
      [theme.breakpoints.down('xs')]: {
        top: '50%'
      }
    },
    '& .shape2': {
      position: 'absolute',
      left: '80px',
      top: '55px'
    },
    '& .shape3': {
      position: 'absolute',
      left: '40px',
      top: '75px'
    },
    '& .shape4': {
      position: 'absolute',
      left: '200px',
      bottom: '50px'
    },
    '& ul': {
      paddingLeft: '0',
      [theme.breakpoints.down('sm')]: {
        marginBottom: '30px'
      },
      [theme.breakpoints.down('xs')]: {
        marginBottom: '0px'
      },
      '& li': {
        paddingLeft: '0',
        alignItems: 'center',
        color: ' #fff !important',
        fontSize: '14px',
        '& svg': {
          marginRight: '10px',
          color: '#408FAC',
          fontSize: '15px'
        }
      }
    },
    '& svg': {
      color: '#fff',
      fontSize: '15px'
    },
    '& p': {
      color: '#fff'
    },
    '& h6': {
      color: '#fff',
      [theme.breakpoints.down('sm')]: {
        marginTop: '30px'
      },
      [theme.breakpoints.down('xs')]: {
        marginTop: '20px'
      }
    },
    '& a': {
      color: '#fff',
      fontWeight: 400,
      textDecoration: 'none',
      fontSize: '14px',
      display: 'flex',
      alignItems: 'center',
      '&:hover': {
        color: '#35a5f5',
        textDecoration: 'none'
      }
    }
  },
  roadmapcenter1: {
    position: 'absolute',
    top: '-24%',
    left: '89%',
    right: '0%',
    zIndex: '-1',
    borderRadius: '1000px',
    opacity: '50%',
    width: '427px',
    height: '427px',
    background: '#4AFCFE',
    opacity: '50%',
    filter: 'blur(94px)',
    transform: 'rotate(-90deg)',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  roadmapcenter2: {
    position: 'absolute',
    top: '30%',
    left: '-15%',
    right: '0%',
    zIndex: '-1',
    borderRadius: '1000px',
    opacity: '1.55',
    width: '427px',
    height: '427px',
    background: '#FED056',
    opacity: '20%',
    filter: 'blur(94px)',
    transform: 'rotate(-90deg)',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  }
}))

export default function Footer() {
  const classes = useStyles()
  const history = useHistory()

  const { account, library, chainId } = useWeb3React()
  const currentYear = new Date().getFullYear()
  return (
    <>
      <Box className={classes.footerSection}>
        {/* <Update /> */}
        <div className={classes.roadmapcenter1}></div>
        <div className={classes.roadmapcenter2}></div>
        <Box mt={7} mb={4}>
          <Container maxWidth="false">
            <Grid container>
              <Grid item xs={12} sm={12} md={5}>
                <Box mr={8}>
                  <Box mb={2}>
                    {' '}
                    <RouterLink to="/">
                      <Logo />
                    </RouterLink>
                  </Box>
                  <Typography variant="body1" component="small">
                    Taral's innovative halving-based POS ensures token scarcity and value preservation. With regular
                    halving every four years, our network maintains economic stability while incentivizing long-term
                    investment. Additionally, our robust burn mechanism systematically reduces the total token supply by
                    permanently removing a portion of tokens from circulation. This dual approach of halving and burning
                    not only enhances scarcity but also supports sustainable value growth, creating a balanced and
                    thriving economic ecosystem.
                  </Typography>
                </Box>
                <Box>
                  <IconButton className="iconBox">
                    <Link target="_blank" href="https://www.facebook.com">
                      <FaFacebookF />
                    </Link>
                  </IconButton>

                  <IconButton className="iconBox">
                    <Link target="_blank" href="https://www.instagram.com">
                      <FaInstagram />
                    </Link>
                  </IconButton>
                  <IconButton className="iconBox">
                    <Link target="_blank" href="https://in.linkedin.com/">
                      <FaLinkedin />
                    </Link>
                  </IconButton>
                  <IconButton className="iconBox">
                    <Link target="_blank" href="https://twitter.com">
                      {/* <FiTwitter /> */}
                      <img src="/images/twitter.png" style={{ height: '15px', width: '15px' }} />
                    </Link>
                  </IconButton>
                  <IconButton className="iconBox">
                    <Link target="_blank" href="https://www.youtube.com/">
                      <FaYoutube />
                    </Link>
                  </IconButton>
                  {/* <IconButton style={{ background: "linear-gradient(261.87deg, #62D3F0 13.12%, #35A5F5 83.57%)",}}>
                  <Link
                    target="_blank"
                    href="https://www.reddit.com/r/hovrNFT/"
                  >
                    <AiOutlineReddit style={{ fontSize: "20px" }} />
                  </Link>
                </IconButton> */}
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={7}>
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={3}>
                    <Typography variant="h6" style={{ color: '#4AFCFE' }}>
                      Quick Links
                    </Typography>
                    <List>
                      <ListItem>Dashboard</ListItem>
                      <ListItem>About Us</ListItem>
                      <ListItem>Our Technology</ListItem>
                      <ListItem>Blog</ListItem>
                      <ListItem>Careers</ListItem>
                      <ListItem>Privacy Policy</ListItem>
                      <ListItem>Terms of Services</ListItem>
                    </List>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <Typography variant="h6" style={{ color: '#4AFCFE' }}>
                      Resources
                    </Typography>
                    <List>
                      <ListItem style={{ cursor: 'pointer', color: '#646464' }}>Litepapers</ListItem>

                      <ListItem style={{ cursor: 'pointer', color: '#646464' }}>Whitepapers</ListItem>
                      <ListItem style={{ cursor: 'pointer', color: '#646464' }}>Case Studies</ListItem>
                      <ListItem style={{ cursor: 'pointer', color: '#646464' }}>FAQs</ListItem>
                      <ListItem style={{ cursor: 'pointer', color: '#646464' }}>Developer API</ListItem>
                      <ListItem style={{ cursor: 'pointer', color: '#646464' }}>Support</ListItem>
                    </List>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <Typography variant="h6" style={{ color: '#4AFCFE' }}>
                      Our Product
                    </Typography>
                    <List>
                      <ListItem style={{ cursor: 'pointer', color: '#646464' }}>Lending Services</ListItem>
                      <ListItem style={{ cursor: 'pointer', color: '#646464' }}>DeFi Solutions</ListItem>
                      <ListItem style={{ cursor: 'pointer', color: '#646464' }}>NFT Marketplace</ListItem>
                      <ListItem style={{ cursor: 'pointer', color: '#646464' }}>DApps Games</ListItem>
                      <ListItem style={{ cursor: 'pointer', color: '#646464' }}>Decentralized Exchange</ListItem>
                    </List>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <Typography variant="h6" style={{ color: '#4AFCFE' }}>
                      Security and Compliance
                    </Typography>
                    <List>
                      <ListItem style={{ cursor: 'pointer', color: '#646464' }}>Security Audits</ListItem>
                      <ListItem style={{ cursor: 'pointer', color: '#646464' }}>Compliance Certificates</ListItem>
                      <ListItem style={{ cursor: 'pointer', color: '#646464' }}>Data Protection</ListItem>
                      <ListItem style={{ cursor: 'pointer', color: '#646464' }}>Risk Management</ListItem>
                    </List>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
          <Box className="copy" mt={1}>
            © {currentYear} All Rights Reserved
          </Box>
        </Box>
      </Box>
    </>
  )
}
