import React from 'react'
import Typography from '@material-ui/core/Typography'
import DashboardLayout from '../../layout/dashboard/DashboardLayout'
import { Box, Container, Grid, makeStyles } from '@material-ui/core'
const useStyles = makeStyles(theme => ({
  mainBg: {
    position: 'relative',
    overflow: 'hidden',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: '-2000px',
      right: '-2000px',
      top: '0',
      bottom: '0',
      background: '#0f212e'
    }
  },
  rightBg: {
    position: 'relative',
    '&::before': {
      content: '""',
      height: '100%',
      width: '100%',
      right: '75%',
      position: 'absolute',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundImage: 'url(images/dashboard/abstract-bg.DBLt5nOh.svg)'
    }
  }
}))
const Dashboard = () => {
  const classes = useStyles()
  return (
    <>
      <DashboardLayout>
        <Box className={classes.mainBg}>
          <Container>
            <Grid container>
              <Grid item xs={12} md={6}></Grid>
              <Grid item xs={12} md={6}>
                <Box className={classes.rightBg}>
                  <img style={{ position: 'relative' }} src="/images/dashboard/welcome-top-en.avif" />
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </DashboardLayout>
    </>
  )
}

export default Dashboard
